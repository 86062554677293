import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const FilterAndSearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Filter = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const MySelling = styled.div<{ select: boolean }>`
  display: flex;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid ${(props) => (props.select ? "#009ee2" : "#DFE2E9")};
  background: ${(props) => (props.select ? "#009ee2" : "#FFF")};

  cursor: pointer;
`;

export const FilterText = styled.span<{ select: boolean }>`
  color: ${(props) => (props.select ? "#fff" : "#ACB1BA")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
`;

export const MyBuying = styled(MySelling)``;

export const Search = styled.div`
  display: flex;
  width: 480px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid #eaecee;
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  flex: 1 0 0;
  font-size: 16px;

  outline: none;
  line-height: 22px; /* 137.5% */
  border: none;

  &::placeholder {
    color: #acb1ba;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const SearchIconWrap = styled.button`
  background: inherit;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Delete = styled.div`
  display: flex;
  width: 82px;
  height: 44px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #eee;
  cursor: pointer;
`;

export const DeleteText = styled.div`
  color: #acb1ba;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CartWrap = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const TitleInfoWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleInfo = styled.div`
  width: 938px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const TitleInfoText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 137.5% */
`;

export const CartItemWrap = styled.div`
  display: flex;
  width: 896px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border: 1px solid #d8d8d8;
  background: #fff;
`;

export const CartStatus = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: 0.35px;
`;

export const CartItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 10px;
  align-self: stretch;
`;

export const CartDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
`;

export const ImgWrapper = styled.div`
  width: 158px;
  height: 145px;
`;

export const CartItemInfo = styled.div`
  height: 145px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  flex: 1 0 0;
`;

export const ItemSummaryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const ItemSummary = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FisrtItemText = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 100% */
`;

export const SecondItemText = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 110% */
`;

export const ButtonWrap = styled.div<{ isBuying?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isBuying ? "center" : "flex-end")};
  align-items: flex-start;
  gap: 10px;
`;

export const ModifyButton = styled.button<{
  disabled?: boolean;
  isBuying?: boolean;
}>`
  display: flex;
  width: ${(props) => (props.isBuying ? "100%" : "258px")};
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 8px;
  border: 2px solid ${(props) => (props.disabled ? "#ACB1BA" : "var(--Main)")};
  background: ${(props) => (props.disabled ? "#F8F8F8" : "#fff")};

  color: ${(props) => (props.disabled ? "#ACB1BA" : "var(--Main)")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
`;

export const SellModifyButton = styled.button<{
  isPurchased?: boolean;
}>`
  display: flex;
  width: 258px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 8px;
  border: 2px solid
    ${(props) => (props.isPurchased ? "#ACB1BA" : "var(--Main)")};
  background: ${(props) => (props.isPurchased ? "#F8F8F8" : "#fff")};

  color: ${(props) => (props.isPurchased ? "#ACB1BA" : "var(--Main)")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
`;

export const DeleteButton = styled(ModifyButton)`
  width: 103px;
`;

export const DeletePurchasedProductButton = styled(ModifyButton)`
  width: 103px;
  border: 2px solid #acb1ba;
  background: #f8f8f8;
  color: #acb1ba;
`;

export const BuyWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Buy = styled.button`
  display: flex;
  width: 167px;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid #009ee2;
  background: #009ee2;
  cursor: pointer;
`;

export const BuyText = styled.span`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;

export const LoadingText = styled.p`
  color: var(--button-gray);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 85.714% */

  margin-top: 30px;
`;

export const NoItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoItemComment = styled.p`
  color: var(--button-gray);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 85.714% */

  margin-top: 30px;
`;
