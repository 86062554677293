import styled from "styled-components";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 101;
  display: flex;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  /* gap: 30px; */
  /* margin-left: 40px; */
`;

export const Tab = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  height: 64px;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-bottom: ${(props) =>
    props.borderBottom && "3px solid var(--Main, #009EE2)"};
`;

export const CartTab = styled(Tab)`
  flex-direction: row;
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;

export const Title = styled.span<{
  color: string;
  fontWeight: number;
  title?: string;
}>`
  color: ${(props) => props.color};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.title === "장바구니" ? "29px" : "22px")};
`;

export const Search = styled.div`
  display: flex;
  width: 277px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid #eaecee;
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  flex: 1 0 0;

  outline: none;
  line-height: 22px; /* 137.5% */
  border: none;
  font-size: 16px;

  &::placeholder {
    color: #acb1ba;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const SearchIconWrap = styled.button`
  background: inherit;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const ButtonWrap = styled.div<{ border: string }>`
  background-color: inherit;

  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid ${(props) => props.border};
`;
