import React from "react";
import styled from "styled-components";

import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import { useNavigate } from "react-router-dom";

export default function SellSuccess() {
  const navigate = useNavigate();
  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Info>
          <TitleWrap>
            <Title>상품 등록 완료</Title>
          </TitleWrap>
          <SubTitleWrap>
            <SubTitle>상품 등록이 완료되었습니다</SubTitle>
          </SubTitleWrap>
        </Info>
        <Button onClick={() => navigate("/")}>홈 화면으로</Button>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  margin-bottom: 80px;
  gap: 20px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const TitleWrap = styled.div`
  display: flex;
  width: 465px;
  height: 57px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  display: flex;
  width: 381px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: var(--gray-900);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const SubTitleWrap = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const SubTitle = styled.span`
  color: var(--gray-900);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;

export const Button = styled.button`
  display: flex;
  width: 330px;
  height: 44px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: inherit;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--Main);

  color: var(--Main);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;
