import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const FilterAndDelete = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const Filter = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const FilterText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const FilterCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Delete = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 82px;
  height: 44px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? "#eee" : "#F23E3E")};
  cursor: pointer;
`;

export const DeleteText = styled.div<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? "#acb1ba" : "#FFFFFF")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const CartWrap = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const CartItemWrap = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border: 1px solid #d8d8d8;
  background: #fff;
`;

export const CartItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const CheckButton = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CartDetail = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
`;

export const CartItemInfo = styled.div`
  display: flex;
  width: 100%;
  height: 145px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;

export const ItemStatusWrap = styled.div<{ borderColor: string }>`
  display: flex;
  width: 63px;
  height: 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor};
  background: #fff;
`;

export const ItemStatus = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px; /* 187.5% */
  letter-spacing: 0.262px;
`;

export const ItemSummary = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemText = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 100% */
`;

export const CartPolicy = styled.div`
  width: 100%;
  text-align: right;
`;

export const CartPolicyText = styled.span`
  color: #212529;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const Calculation = styled.div`
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;

  border: 1px solid #d8d8d8;
  background: #fcfcfc;
`;

export const CalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const CalTitleText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const CalValueText = styled.span<{ finalPrice?: boolean }>`
  color: ${(props) => (props.finalPrice ? "#009EE2" : "#000")};
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: ${(props) => (props.finalPrice ? "30px" : "22px")};
  font-style: normal;
  font-weight: ${(props) => (props.finalPrice ? 700 : 500)};
  line-height: 22px; /* 100% */
`;

export const CalProductPrice = styled.div`
  display: flex;
  width: 400px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
`;

export const CalPlus = styled.div`
  display: flex;
  width: 80px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
`;

export const CalDeliveryPrice = styled.div`
  display: flex;
  width: 140px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
`;

export const CalEqual = styled.div`
  display: flex;
  width: 60px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
`;

export const CalFinalPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const BuyWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Buy = styled.button<{ disabled: boolean }>`
  display: flex;
  width: 167px;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: ${(props) => (props.disabled ? "none" : "1px solid #009ee2")};
  background: ${(props) => (props.disabled ? "#EEE" : "#009ee2")};
  cursor: pointer;
`;

export const BuyText = styled.span<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? "var(--button-gray)" : "#FFF")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;

export const NoItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-top: 120px;
  padding-bottom: 13px;
`;

export const NoItemText = styled.div`
  color: var(--button-gray);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 85.714% */
`;

export const NoItemButton = styled.button`
  display: flex;
  width: 407px;
  height: 44px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Main);

  color: var(--Main, #009ee2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */

  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;
