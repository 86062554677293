import { useMutation } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";
import { useNavigate } from "react-router-dom";

interface Param {
  userId: number;
  productId: number;
}

export default function useAddCart() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (param: Param) => axiosAuthClient.post("/web/carts", param),
    onSuccess: ({ data }) => {
      console.log(data);
      const { code } = data;
      if (code === 1000) {
        navigate("/cart");
      } else {
        alert(`장바구니에 담는데 실패했습니다(code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });
}
