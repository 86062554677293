import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

const getSectorId = async () => {
  const { data } = await axiosClient.get("/web/sectors");
  return data;
};

export default function useGetSectorId() {
  return useQuery(["getSectorId"], getSectorId, {
    onError: (err) => alert(err),
    select: (res) => res.result.sectors,
  });
}
