import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { axiosClient } from "../../../apis/general";
import { useQuery } from "@tanstack/react-query";
import { changePhoneContactForm } from "../../../utils/utility";
import styled from "styled-components";

interface propsType {
  onNext: (verifyCode: number, phoneNumber: string) => void;
}

const IdentityVerify = async (phoneNumber: string) => {
  const { data } = await axiosClient.post(`/web/auth/phone`, { phoneNumber });
  return data;
};

const checkDuplicatePhone = async (phoneNumber: string) => {
  const { data } = await axiosClient.get(
    `/web/auth/duplicate-phone-number?phoneNumber=${phoneNumber}`
  );
  return data;
};

export default function SignUpPhoneInput({ onNext }: propsType): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [commentShow, setCommentShow] = useState(false);

  const { refetch: identityVerifyRefetch } = useQuery(
    ["identity-verify"],
    () => IdentityVerify(phoneNumber),
    {
      enabled: false,
      onSuccess: (data) => {
        const { code } = data;
        if (code === 1000) {
          const {
            result: { verifyCode },
          } = data;
          onNext(verifyCode, phoneNumber);
        } else {
          const { message } = data;
          alert(`${message}`);
        }
      },
      onError: (err) => alert(err),
    }
  );

  const { refetch: checkDuplicatePhoneRefetch } = useQuery(
    ["checkDuplicatePhone"],
    () => checkDuplicatePhone(phoneNumber),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.code === 1000) {
          identityVerifyRefetch();
          return;
        }
        if (data.code === 2017) {
          setCommentShow(true);
          return;
        }
        alert(data.message);
      },
      onError: (err) => alert(err),
    }
  );

  const onRequestAuth = () => {
    checkDuplicatePhoneRefetch();
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>본인인증을 위해 휴대폰번호를 입력해주세요</InputTitle>
        <Input
          value={phoneNumber}
          onChange={({ target: { value } }) =>
            setPhoneNumber(changePhoneContactForm(value))
          }
          placeholder="‘-’없이 숫자만 입력"
          maxLength={13}
          tabIndex={1}
        />
        {commentShow && <Comment>이미 사용중인 핸드폰 번호입니다.</Comment>}
      </InputWrap>
      <Button
        onClick={onRequestAuth}
        tabIndex={2}
        disabled={!(phoneNumber.replaceAll("-", "").length === 11)}
      >
        본인 인증
      </Button>
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
