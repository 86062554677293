import { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1080;

export const GlobalStyle = createGlobalStyle`
html {
    font-size: 16px;
/* font-size: 62.5%; // 1rem = 10px 로 변경 한 것 */
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

:root {
        --Main: #009EE2;
        --gray-100: #B5BDC4;
        --gray-300: #8b97a2;
        --gray-900: #212529;
        --box_text: #8b97a2;
        --button-gray: #ACB1BA;
    }

    /* @media all and (max-width: ${supportDeviceSize}px) {
        font-size: 31.25%;
    } */
    input:focus::placeholder {
        color: transparent;
    }
    textarea:focus::placeholder {
        color: transparent;
    }
}

body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', sans-serif;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
`;
