import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { changeNumberFormat } from "../../../utils/utility";
import { axiosClient } from "../../../apis/general";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import Popup from "../../Popup/common";

interface propsType {
  verifyCode: number | undefined;
  phoneNumber?: string | undefined;
  onNext: () => void;
  onPrevious?: () => void;
  setId?: (val: string) => void;
  purpose: string;
}

const FindID = async (phoneNumber: string | undefined) => {
  const { data } = await axiosClient.get(
    `/web/auth/id?phoneNumber=${phoneNumber}`
  );
  return data;
};

export default function PhoneConfirm({
  verifyCode,
  phoneNumber,
  onNext,
  onPrevious,
  setId,
  purpose,
}: propsType): JSX.Element {
  const [inputVerifyCode, setInputVerifyCode] = useState<number>();
  const [commentShow, setCommentShow] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const { refetch: findIdRefetch } = useQuery(
    ["find-id"],
    () => FindID(phoneNumber),
    {
      enabled: false,
      onSuccess: (data) => {
        const { code } = data;
        if (code === 1000) {
          const {
            result: { userId },
          } = data;
          if (setId) setId(userId);
          onNext();
        } else {
          const { message } = data;
          if (code === 2136) alert(`${message}(code: ${code})`);
          if (code === 2013) {
            onTogglePopUp();
          }
        }
      },
      onError: (err) => alert(err),
    }
  );

  const onRequestAuth = () => {
    if (verifyCode === Number(inputVerifyCode)) {
      if (["sign-up", "reset-pw"].includes(purpose)) {
        onNext();
      }
      if (purpose === "find-id") {
        findIdRefetch();
      }
    } else {
      setCommentShow(true);
    }
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>휴대폰으로 보내드린 인증번호를 입력해주세요</InputTitle>
        <Input
          value={inputVerifyCode}
          onChange={({ target: { value } }) =>
            setInputVerifyCode(changeNumberFormat(value))
          }
          placeholder="6자리 숫자"
          maxLength={6}
          tabIndex={1}
        />
        {commentShow && <Comment>인증번호가 일치하지 않습니다.</Comment>}
      </InputWrap>
      <Button
        onClick={onRequestAuth}
        tabIndex={2}
        disabled={inputVerifyCode?.toString().length !== 6}
      >
        확인
      </Button>
      {isPopUpOpen && (
        <Popup
          type="phoneConfirm"
          info={"가입 내역이 없습니다./본인 인증 페이지로 돌아갑니다."}
          buttonCount={1}
          onCancel={onTogglePopUp}
          onOk={() => {
            onTogglePopUp();
            onPrevious && onPrevious();
          }}
        />
      )}
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
