import React from "react";
import AppHeader from "../components/Header";
import AppContent from "../components/Content";
import AppFooter from "../components/Footer";
import styled from "styled-components";
import { supportDeviceSize } from "../components/styles";

const DefaultLayout = () => {
  return (
    <Root>
      <AppHeader />
      <AppContent />
      <AppFooter />
    </Root>
  );
};

const Root = styled.div`
  /* width: 1360px; */
  display: flex;
  flex-direction: column;
  /* height: 100vh; */

  /* @media all and (max-width: ${supportDeviceSize}px) {
    width: 100vw;
  } */
`;

export default DefaultLayout;
