import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  place-items: center;
`;

export const Container = styled.div`
  display: flex;
  width: 480px;
  margin-top: 180px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

export const CommonText = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;
