import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { axiosAuthClient } from "../apis/general";
import { changeCommaFormat } from "../utils/utility";

export default function CustomTooltip() {
  const [priceList, setPriceList] = useState([
    // {
    //   type: "택배",
    //   dataSum: "140cm 이하",
    //   weight: "15kg 이하",
    //   price: "3,000원",
    // },
    // {
    //   type: "택배",
    //   dataSum: "160cm 이하",
    //   weight: "20kg 이하",
    //   price: "4,000원",
    // },
    // {
    //   type: "택배",
    //   dataSum: "180cm 이하",
    //   weight: "25kg 이하",
    //   price: "5,800원",
    // },
    // {
    //   type: "택배",
    //   dataSum: "190cm 이하",
    //   weight: "30kg 이하",
    //   price: "8,790원",
    // },
    // {
    //   type: "용달",
    //   dataSum: "290cm 이하",
    //   weight: "275kg 이하",
    //   price: "50,000원",
    // },
    // {
    //   type: "용달",
    //   dataSum: "350cm 이하",
    //   weight: "366kg 이하",
    //   price: "100,000원",
    // },
    // {
    //   type: "용달",
    //   dataSum: "420cm 이하",
    //   weight: "550kg 이하",
    //   price: "200,000원",
    // },
    // {
    //   type: "용달",
    //   dataSum: "600cm 이하",
    //   weight: "1100kg 이하",
    //   price: "400,000원",
    // },
  ]);

  useEffect(() => {
    const getDefaultValues = async () => {
      try {
        const {
          data: { result },
        } = await axiosAuthClient.get(`/web/default-values`);

        setPriceList(result.deliveryFees);
      } catch (error) {
        alert(error);
      }
    };
    getDefaultValues();
  }, []);
  return (
    <Wrap>
      {/* <TitleWrap>
        <Title>배송비 책정 안내</Title>
        <TitleSub>
          예상 배송비는 입력하신 상품의 크기를 기반으로 책정됩니다. 안내드린
          가격에서 변경될 수 있습니다.
        </TitleSub>
      </TitleWrap> */}
      <Table>
        <TableTitleWrap>
          <TableTitleType>
            <Text>배송 타입</Text>
          </TableTitleType>
          <TableTitleDataSum>
            <Text>세 변의 합 ( 가로 + 세로 + 높이 )</Text>
          </TableTitleDataSum>
          <TableTitleWeight>
            <Text>무게</Text>
          </TableTitleWeight>
          <TableTitlePrice>
            <Text>금액</Text>
          </TableTitlePrice>
        </TableTitleWrap>
        {priceList.map(
          ({ deliveryMethod, sumOfSides, weight, deliveryFee }, idx) => (
            <TableRowInfoWrap key={idx}>
              <TableRowInfoType>
                <Text>{deliveryMethod}</Text>
              </TableRowInfoType>
              <TableRowInfoDataSum>
                <Text>{sumOfSides}</Text>
              </TableRowInfoDataSum>
              <TableRowInfoWeight>
                <Text>{weight}</Text>
              </TableRowInfoWeight>
              <TableRowInfoPrice>
                <Text>{changeCommaFormat(deliveryFee)} 원</Text>
              </TableRowInfoPrice>
            </TableRowInfoWrap>
          )
        )}
      </Table>
    </Wrap>
  );
}

const Wrap = styled.div`
  top: 200px;
  display: flex;
  /* width: 1040px; */
  padding: 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 23px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
`;

// const TitleWrap = styled.span`
//   display: flex;
//   width: 971px;
//   align-items: center;
//   gap: 28px;
// `;

// const Title = styled.span``;

// const TitleSub = styled.span`
//   color: #000;
//   font-family: Noto Sans KR;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
// `;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TableTitleWrap = styled.div`
  display: flex;
  width: 921.986px;
  align-items: flex-start;

  background: #eee;
`;

const TableTitleType = styled.div`
  display: flex;
  width: 141.844px;
  height: 41.667px;
  justify-content: center;
  align-items: center;
  gap: 4.433px;
  flex-shrink: 0;
`;

const TableTitleDataSum = styled.div`
  display: flex;
  height: 41.667px;
  justify-content: center;
  align-items: center;
  gap: 4.433px;
  flex: 1 0 0;
`;

const TableTitleWeight = styled.div`
  display: flex;
  width: 235.816px;
  height: 41.667px;
  justify-content: center;
  align-items: center;
  gap: 4.433px;
  flex-shrink: 0;
`;

const TableTitlePrice = styled.div`
  display: flex;
  width: 161.348px;
  height: 41.667px;
  justify-content: center;
  align-items: center;
  gap: 4.433px;
  flex-shrink: 0;
`;

const TableRowInfoWrap = styled(TableTitleWrap)`
  background: none;
`;

const TableRowInfoType = styled(TableTitleType)``;

const TableRowInfoDataSum = styled(TableTitleDataSum)``;

const TableRowInfoWeight = styled(TableTitleWeight)``;

const TableRowInfoPrice = styled(TableTitlePrice)``;

const Text = styled.span`
  color: var(--gray-900);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14.184px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.504px; /* 137.5% */
`;
