import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

const getSettlements = async (
  userId: number,
  productName: string,
  page: number
) => {
  const { data } = await axiosAuthClient.get(
    `/web/settlements?userId=${userId}&productName=${productName}&page=${page}`
  );
  return data;
};

export default function useGetSettlements(
  userId: number,
  productName: string,
  page: number
) {
  return useQuery(
    ["getSettlements", userId, productName, page],
    () => getSettlements(userId, productName, page),
    {
      onError: (err) => alert(err),
      select: ({ result: { settlements, totalCount } }) => ({
        settlements,
        totalCount,
      }),
    }
  );
}
