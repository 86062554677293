import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import {
  Close,
  Content,
  ExtraMessage,
  Input,
  InputContainer,
  InputTitle,
  InputWrap,
  LoadFromUesrTitleText,
  LoadFromUser,
  LoadFromUserCheckText,
  LoadFromUserCheckWrap,
  LoadFromUserTitle,
  LoadFromuserCheck,
  Main,
  RadioButton,
  RadioItem,
  RadioText,
  RadioWrap,
  Subject,
  SubjectDesc,
  SubjectDescText,
  SubjectText,
  Title,
  TitleWrap,
  Wrap,
} from "./styles";
import { ReactComponent as CloseButton } from "../../../assets/header/ic-header-close.svg";
import { ButtonWrap, InfoButton, InfoButtonText } from "../../MyPage/styles";
import { LoginState, UserDBIdState } from "../../../recoil/login";
import { useRecoilState } from "recoil";
import { useMutation } from "@tanstack/react-query";
import { axiosAuthClient, axiosClient } from "../../../apis/general";
import { changeAllContactForm } from "../../../utils/utility";
import Popup from "../../Popup/common";

interface PropsType {
  onToggleModal: () => void;
}

interface ConsultingInfo {
  userId: number;
  name: string;
  phoneNumber: string;
  consultingPurposeId: number;
  message?: string;
}

const cursorStyle = {
  cursor: "pointer",
};

const optionList = [
  { id: 1, title: "판매 상담" },
  { id: 2, title: "구매 상담" },
  { id: 3, title: "배송 및 교환 상담" },
  { id: 4, title: "제휴" },
  { id: 5, title: "기타 문의" },
];

export default function Consulting({ onToggleModal }: PropsType) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [consultingPurposeId, setConsultingPurposeId] = useState(0);
  const [message, setMessage] = useState("");
  const [userId] = useRecoilState(UserDBIdState);
  const [isLoggedIn] = useRecoilState(LoginState);
  const [loadCheckbox, setLoadCheckbox] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const AllInputsFilled = name && phoneNumber && consultingPurposeId !== 0;

  const checkMyPage = async (userDBId: number) => {
    try {
      const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
      if (data.code === 1000) {
        const {
          result: {
            user: { name, businessName, phoneNumber },
          },
        } = data;
        setName(name || businessName);
        setPhoneNumber(phoneNumber);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setLoadCheckbox(true);
      checkMyPage(userId);
    }
  }, []);

  useEffect(() => {
    if (!loadCheckbox) {
      setName("");
      setPhoneNumber("");
    } else {
      checkMyPage(userId);
    }
  }, [loadCheckbox]);

  const requestConsulting = useMutation({
    mutationFn: (param: ConsultingInfo) =>
      axiosClient.post("/web/consultings", param),
    onSuccess: ({ data }) => {
      const { code, message } = data;
      if (code === 1000) {
        onTogglePopUp();
      } else {
        alert(`${message}(code: ${code})`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const handleRequest = () => {
    requestConsulting.mutate({
      userId,
      name,
      phoneNumber,
      consultingPurposeId,
      message,
    });
  };

  return (
    <>
      <Modal
        open={true}
        footer={null}
        width={850}
        closeIcon={null}
        centered
        onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
      >
        <Wrap>
          <Content>
            <TitleWrap>
              <Close>
                <CloseButton onClick={onToggleModal} style={cursorStyle} />
              </Close>
              <Title>
                <Subject>
                  <SubjectText>상담 신청</SubjectText>
                </Subject>
                <SubjectDesc>
                  <SubjectDescText>
                    영업일 기준 1일 이내 운영자가 연락 드릴 예정입니다
                  </SubjectDescText>
                </SubjectDesc>
              </Title>
            </TitleWrap>
            <Main>
              <LoadFromUser>
                <LoadFromUserTitle>
                  <LoadFromUesrTitleText>
                    상담 신청자 정보
                  </LoadFromUesrTitleText>
                </LoadFromUserTitle>
                <LoadFromUserCheckWrap>
                  <LoadFromuserCheck
                    disabled={!isLoggedIn}
                    checked={loadCheckbox}
                    onChange={() => setLoadCheckbox(!loadCheckbox)}
                  />
                  <LoadFromUserCheckText>
                    회원정보에서 불러오기
                  </LoadFromUserCheckText>
                </LoadFromUserCheckWrap>
              </LoadFromUser>
              <InputContainer>
                <InputWrap>
                  <InputTitle>이름*</InputTitle>
                  <Input
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                    placeholder="이름을 입력해주세요"
                    minLength={2}
                    maxLength={20}
                  />
                </InputWrap>
                <InputWrap>
                  <InputTitle>연락처*</InputTitle>
                  <Input
                    value={phoneNumber}
                    onChange={({ target: { value } }) =>
                      setPhoneNumber(changeAllContactForm(value))
                    }
                    placeholder="‘-’없이 숫자만 입력"
                    maxLength={13}
                  />
                </InputWrap>
                <InputWrap>
                  <InputTitle>상담 목적*</InputTitle>
                  <RadioWrap>
                    {optionList.map(({ id, title }) => (
                      <RadioItem key={id}>
                        <RadioButton
                          type="radio"
                          name="test"
                          value={id}
                          onChange={({ target: { value } }) => {
                            setConsultingPurposeId(Number(value));
                          }}
                        />
                        <RadioText>{title}</RadioText>
                      </RadioItem>
                    ))}
                  </RadioWrap>
                </InputWrap>
                <ExtraMessage
                  value={message}
                  onChange={({ target: { value } }) => setMessage(value)}
                  maxLength={200}
                  placeholder="추가 메시지(선택)"
                ></ExtraMessage>
              </InputContainer>
            </Main>
            <ButtonWrap>
              <InfoButton
                onClick={handleRequest}
                border={AllInputsFilled ? "Main" : ""}
                background={AllInputsFilled ? "Main" : ""}
              >
                <InfoButtonText
                  color={AllInputsFilled ? "#FFF" : "var(--button-gray)"}
                >
                  신청 완료
                </InfoButtonText>
              </InfoButton>
            </ButtonWrap>
          </Content>
          {isPopUpOpen && (
            <Popup
              type="consulting"
              info={"상담 신청이 완료되었습니다./2~3일 이내에 연락드릴게요."}
              buttonCount={1}
              onCancel={onTogglePopUp}
              onOk={() => {
                onTogglePopUp();
                onToggleModal();
              }}
            />
          )}
        </Wrap>
      </Modal>
    </>
  );
}
