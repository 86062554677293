import styled from "styled-components";

export const CategorySelect = styled.select<{ marginTop?: string }>`
  margin-top: ${(props) => props.marginTop || "0px"};
  border-radius: 5px;
  border: 1px solid #009ee2;
  background: #fff;
  filter: blur(0px);
  display: flex;
  width: 251px;
  height: 36px;
  padding: 4px 8px;
  align-items: center;
  gap: 133px;

  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: url("/assets/arrow_down.svg") calc(100% - 5px) center no-repeat;
`;
