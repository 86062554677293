import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: "active",
})<{ marginTop: string }>`
  margin-top: ${(props) => props.marginTop};
  font-size: 15px;
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 2rem;
    cursor: pointer;
  }
  li.previous a,
  li.next a {
    color: #62b6b7;
  }
  li.active a {
    color: #91cccd;
    font-weight: 700;
    min-width: 32px;
  }

  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
