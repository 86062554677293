import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const DetailWrap = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const Detail = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  align-items: flex-start;
  gap: 120px;
  border-bottom: 1px solid #dfe2e9;
`;

export const Description = styled.textarea<{ savedData: boolean }>`
  display: flex;
  width: 900px;
  height: 128px;
  padding: 10px;
  color: ${(props) => (props.savedData ? "" : "#ACB1BA")};
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #d8d8d8;
`;

export const ProductImgWrap = styled.div`
  width: 395px;
`;

export const ReadingGlassesWrap = styled.div`
  text-align: right;
`;

export const ReadingGlasses = styled.span`
  color: #000;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 107.143% */
  letter-spacing: 0.35px;
  cursor: pointer;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
`;

export const TextWrap = styled.div`
  display: flex;
  height: 47px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const ProductTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const RowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const RowInfoTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

export const RowInfoDesc = styled.span`
  max-width: 200px;
  word-break: break-word;

  color: #000;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const PriceWrap = styled(TextWrap)`
  width: 100%;
  text-align: right;
`;

export const Price = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const PriceDescWrap = styled.div`
  width: 100%;
  text-align: right;
`;

export const PriceDesc = styled.span`
  color: #a8a8a8;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

export const CartButton = styled.div<{ background: string; border?: string }>`
  display: flex;
  width: 162px;
  height: 44px;
  min-height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border-radius: 8px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
`;

export const CartButtonText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const OtherProductsWrap = styled.div`
  width: 100%;
  display: flex;
  height: 364px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const OtherProductsTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const OtherProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
`;
