import React, { useEffect, useRef, useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  Filter,
  CartItem,
  CartItemWrap,
  CartDetail,
  CartItemInfo,
  ItemSummary,
  CartWrap,
  FilterAndSearch,
  FilterText,
  Search,
  SearchInput,
  Container,
  ItemSummaryWrap,
  ButtonWrap,
  ModifyButton,
  FisrtItemText,
  SecondItemText,
  MySettlement,
  MyMethod,
  DateText,
  InfoWrap,
  InfoTitle,
  InfoContent,
  InfoTitleWrap,
  SearchIconWrap,
  NoItemWrapper,
  NoItemComment,
} from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/header/ic-header-search.svg";
import { ReactComponent as HelpIcon } from "../../assets/content/settlement/ic-content-settlement-help.svg";
import { ReactComponent as TitleInfoIcon } from "../../assets/content/sell/ic-content-sell-info.svg";

// import PopularItem01 from "../../assets/content/popularItem/img-content-popularItem-01.png";
import { MyPaginate } from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { FirstItemSmallText } from "./styles";
import Method from "./method";
import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";
import { useRecoilState } from "recoil";
import { UserDBIdState } from "../../recoil/login";
import useGetSettlements from "../../hooks/settlement/useGetSettlements";
import TransactionStatement from "../../components/Settlement/TransactionStatement";
import { changeCommaFormat } from "../../utils/utility";
import { Tooltip } from "antd";

export const getSettlementMethod = async (userId: number) => {
  const { data } = await axiosAuthClient.get(
    `/web/users/${userId}/settlement-methods`
  );
  return data;
};

interface Settlement {
  id: number;
  productName: string;
  price: number;
  brokerageFee: number;
  expectedDeliveryFee: number;
  settlementStatus: string;
  productImageUrl: string;
  purchaseCompleteDate: string;
  settlementCompleteDate: string;
}

export default function Settlement() {
  const navigate = useNavigate();
  const [settlement, setSettlement] = useState(true);
  const [method, setMethod] = useState(false);
  const [userId] = useRecoilState(UserDBIdState);

  const [productName, setProductName] = useState<string>("");
  const productInput = useRef("");
  const [page, setPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const onSelect = (type: string) => {
    if (type === "settlement") {
      setSettlement(true);
      setMethod(false);
    } else {
      setMethod(true);
      setSettlement(false);
    }
  };

  const { data: settlementsInfo } = useGetSettlements(
    userId,
    productName,
    page
  );

  const { data: methodData } = useQuery(
    ["getSettlementMethod"],
    () => getSettlementMethod(userId),
    {
      select: (res) => res.result.settlementMethod,
    }
  );

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>정산 관리</Title>
          </TitleWrap>
          <FilterAndSearch>
            <Filter>
              <MySettlement
                select={settlement}
                onClick={() => onSelect("settlement")}
              >
                <FilterText select={settlement}>정산</FilterText>
              </MySettlement>
              <MyMethod select={method} onClick={() => onSelect("method")}>
                <FilterText select={method}>정산 수단 관리</FilterText>
              </MyMethod>
            </Filter>
            {settlement && (
              <Search>
                <SearchInput
                  onChange={(e) => (productInput.current = e.target.value)}
                  placeholder="상품 정보를 입력해주세요."
                  tabIndex={1}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      setProductName(productInput.current);
                    }
                  }}
                />
                <SearchIconWrap
                  onClick={() => setProductName(productInput.current)}
                  tabIndex={2}
                >
                  <SearchIcon />
                </SearchIconWrap>
              </Search>
            )}
          </FilterAndSearch>
          {settlement ? (
            <>
              <CartWrap>
                {settlementsInfo?.settlements?.map(
                  ({
                    id,
                    productName,
                    price,
                    brokerageFee,
                    expectedDeliveryFee,
                    settlementStatus,
                    productImageUrl,
                    purchaseCompleteDate,
                    settlementCompleteDate,
                  }: Settlement) => (
                    <Container key={id}>
                      <CartItemWrap>
                        <CartItem>
                          <CartDetail>
                            <div
                              style={{
                                height: "122px",
                              }}
                            >
                              <img
                                src={productImageUrl}
                                alt="상품 이미지"
                                width={158}
                                height={104}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                            <CartItemInfo>
                              <ItemSummaryWrap>
                                <ItemSummary>
                                  <FisrtItemText>{productName}</FisrtItemText>
                                  <div style={{ display: "flex" }}>
                                    {settlementStatus !== "정산 완료" && (
                                      <Tooltip
                                        placement="bottom"
                                        title={
                                          "정산 대기 상태의 상품은 월말에 정산 수단 관리에서 등록한 계좌에 자동으로 입금될 예정입니다.\n 즉시 정산을 원하신다면 아래 ‘정산금 수령 신청'버튼을 눌러주세요."
                                        }
                                        overlayInnerStyle={{
                                          width: "390px",
                                          color: "#000",
                                          background: "#ffffff",
                                          border: "1px solid #000",
                                          whiteSpace: "pre-line",
                                        }}
                                        arrow={false}
                                      >
                                        <HelpIcon />
                                      </Tooltip>
                                    )}
                                    <FirstItemSmallText>
                                      {settlementStatus !== "정산 완료"
                                        ? "정산 대기"
                                        : settlementStatus}
                                    </FirstItemSmallText>
                                  </div>
                                </ItemSummary>
                                <ItemSummary>
                                  <SecondItemText>
                                    정산금 {changeCommaFormat(price)}원
                                  </SecondItemText>
                                  <DateText>
                                    {settlementStatus !== "정산 완료" ? (
                                      <>구매 확정일: {purchaseCompleteDate}</>
                                    ) : (
                                      <>
                                        정산 완료일 : {settlementCompleteDate}
                                      </>
                                    )}
                                  </DateText>
                                </ItemSummary>
                              </ItemSummaryWrap>
                              <ButtonWrap>
                                <ModifyButton
                                  onClick={() => {
                                    if (settlementStatus !== "정산 완료") {
                                      navigate("/settlement/apply", {
                                        state: {
                                          productId: id,
                                          productImageUrl,
                                          productName,
                                          price,
                                        },
                                      });
                                    } else {
                                      onToggleModal();
                                    }
                                  }}
                                >
                                  {settlementStatus === "정산 완료"
                                    ? "결제 영수증 확인"
                                    : "정산금 수령 신청"}
                                </ModifyButton>
                              </ButtonWrap>
                            </CartItemInfo>
                          </CartDetail>
                        </CartItem>
                      </CartItemWrap>
                      {isOpen && (
                        <TransactionStatement
                          onToggleModal={onToggleModal}
                          productName={productName}
                          price={price}
                          brokerageFee={brokerageFee}
                          expectedDeliveryFee={expectedDeliveryFee}
                          purchaseCompleteDate={purchaseCompleteDate}
                        />
                      )}
                    </Container>
                  )
                )}
              </CartWrap>
              {settlementsInfo?.totalCount === 0 && (
                <NoItemWrapper>
                  <NoItemComment>
                    조회할 수 있는 정산내역이 없습니다.
                  </NoItemComment>
                </NoItemWrapper>
              )}
              {settlementsInfo?.totalCount !== 0 && (
                <>
                  <MyPaginate
                    marginTop="50px"
                    forcePage={0}
                    previousLabel={"〈"}
                    nextLabel={"〉"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(
                      (settlementsInfo?.totalCount ?? 1) / 5
                    )}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => {
                      setPage(selected + 1);
                    }}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                  />
                  <InfoWrap>
                    <InfoTitleWrap>
                      <TitleInfoIcon />
                      <InfoTitle>꼭 확인해주세요!</InfoTitle>
                    </InfoTitleWrap>
                    <InfoContent>
                      • 세금계산서는 거래 주체인 판매자가 구매자에게 발행합니다.
                      <br />
                      • 세금계산서는 사업자 인증 받은 판매자에게 요청하실 수
                      있습니다.
                      <br />
                      • 이벤트 쿠폰 사용 금액은 할인된 금액이기 때문에
                      세금계산서에 포함되지 않습니다.
                      <br />
                      • 거래명세서는 결제가 완료되었음을 증명하는 용도로만 활용
                      가능하며 세무상의 지출증빙 효력이 없습니다.
                      <br />• 현금영수증은 개인의 소득공제용으로만 사용
                      가능하며, 결제 당시 지출 증빙용으로 선택하셨더라도
                      매입세액공제를 받으실 수 없습니다.
                    </InfoContent>
                  </InfoWrap>
                </>
              )}
            </>
          ) : (
            <Method {...methodData} />
          )}
        </Content>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
