import React from "react";
import styled from "styled-components";

interface propsType {
  show: boolean;
  content: string;
  emptyWidth?: string;
}

// SignUpComment와 스타일 일부만 다름(Wrap의 gap, Empty의 width)
// 공통으로 쓰지 않은 것은 Comment에 넘기는 props가 많아져서임
export default function Comment({ show, content, emptyWidth }: propsType) {
  if (show) {
    return (
      <Wrap>
        <Empty width={emptyWidth}></Empty>
        <Content>{content}</Content>
      </Wrap>
    );
  }
  return null;
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 3.75rem;
  align-self: stretch;
`;

const Empty = styled.span<{ width?: string }>`
  width: ${(props) => props.width || "4rem"};
`;

const Content = styled.span`
  padding-left: 0.93rem;
  font-size: 0.87rem;
  color: red;
`;
