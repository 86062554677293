import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

// export const MainBanner = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;

//   width: 100%;
//   height: 399px;
//   background: #e4faff;
// `;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  flex-shrink: 0;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const Text = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px; /* 80.357% */
  letter-spacing: 0.262px;
`;

export const CategoryItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  cursor: pointer;
`;

export const CategoryItemText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 16.86px; /* 84.301% */
`;

export const NonCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const PopularItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const Filter = styled.div`
  display: flex;
  height: 51px;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: 8px;
`;

export const FilterItem = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-bottom: ${({ selected }) =>
    selected ? "3px solid #009ee2" : "3px solid #D8D8D8;"};
  cursor: pointer;
`;

export const FilterItemText = styled(Text)`
  font-size: 22px;
  font-weight: 500;
`;

export const Card = styled.div`
  display: flex;
  /* align-items: flex-start; */
  /* gap: 34px; */
  /* align-self: stretch; */
  width: 100%;
  justify-content: space-between;
  /* border: 1px solid black; */
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  /* flex: 1 0 0; */
  flex-wrap: wrap;
  /* border: 1px solid red; */
  cursor: pointer;
`;

export const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

export const ItemTitle = styled(Text)`
  color: #212529;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
`;

export const ItemType = styled.div<{ isBiz: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* flex: 1 0 0; */
  width: 47px;

  border-radius: 8px;
  background: ${(props) => (props.isBiz === "Y" ? "#b3e2f6" : "#ffddbd")};
`;

export const ItemTypeText = styled(Text)<{ isBiz: string }>`
  color: ${(props) => (props.isBiz === "Y" ? "#009EE2" : "#FF8C22")};
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;

export const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export const ItemDetailElement = styled.div<{ gap: string }>`
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.gap};
  align-self: stretch;
`;

export const ItemDetailElementText = styled(Text)`
  color: #212529;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;

export const Line = styled.hr`
  width: 192px;
  height: 1px;
  background: #d8d8d8;
  border-width: 0;
  color: gray;
`;

export const ItemPriceWrap = styled.div`
  width: 100%;
  text-align: right;
`;

export const ItemPriceText = styled(Text)`
  color: #212529;
  font-family: Pretendard;
  font-size: 20px;
`;

export const MiddleBannerWrap = styled.div`
  width: 100%;
  height: 187px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  background: url("/assets/img-middle-banner.png") calc(100% - 5px) center
    no-repeat;
  cursor: pointer;
`;

export const MiddleBannerTextTop = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: 0.262px;
  margin-left: 40px;
`;

export const MiddleBannerTextBottom = styled(MiddleBannerTextTop)`
  font-size: 18px;
  font-weight: 500;
`;

export const DescAndBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  align-self: stretch;
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  align-self: stretch;
`;

export const DescTitle = styled.div`
  text-align: center;
`;

export const DescTitleText = styled(Text)`
  font-size: 36px;
  font-weight: 600;
  line-height: 47px; /* 130.556% */
  letter-spacing: 0.35px;
`;

export const DescItemWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const DescItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const DescItemContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const DescItemContent = styled.div`
  text-align: center;
`;

export const DescItemContentTopText = styled(Text)`
  color: #212529;
  font-size: 27px;
  line-height: 39px; /* 144.444% */
`;

export const DescItemContentBottomText = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px; /* 175% */
`;
