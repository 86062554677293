export const isIDValid = (id: string | undefined): boolean => {
  // 영문 대문자, 영문 소문자, 숫자 중 한개 또는 여러개로 구성할 수 있음
  if (id === undefined) {
    return false;
  }
  return /^[a-zA-Z0-9]{5,20}$/.test(id);
};

export const isPasswordValid = (password: string | undefined): boolean => {
  // 영문(대문자/소문자), 숫자 순서 상관없이 적어도 1개씩 있어야 함(특수문자 가능. 필수는 아님)
  if (password === undefined) {
    return false;
  }
  // return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/.test(password);
  return /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,20}$/.test(password);
};

export const isPasswordEqual = (
  originPW: string | undefined,
  confirmPW: string | undefined
): boolean => {
  if (originPW === undefined || confirmPW === undefined) {
    return false;
  }
  return originPW === confirmPW;
};

export const isNameValid = (name: string | undefined) => {
  if (name === undefined) {
    return false;
  }
  return /^[가-힣]{2,20}$/.test(name);
};

export const isEmailValid = (email: string | undefined) => {
  if (email === undefined) {
    return false;
  }
  return /^[a-z0-9\.\-_]+@([a-z0-9\-]+\.)+[a-z]{2,6}$/.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
};

export const isNumber = (value: any) => {
  return /^[0-9]+$/.test(value) ? true : false;
};

// 핸드폰 번호 형식 변환
export const changePhoneContactForm = (value: any) => {
  value = value
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(\-{1,2})$/g, "");
  return value;
};

// 모든 전화번호 형식 변환(일반, 핸드폰)
export const changeAllContactForm = (value: any) => {
  value = value
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return value;
};

// 사업자등록번호 형식 변환
export const changeComRegNumForm = (value: any) => {
  value = value
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
  return value;
};

// 사업자등록번호 형식에서 일반 숫자로 변환(*중요: -삭제하지만 string으로 return)
// api에 넘기는 사업자등록번호가 string 형식이라 이렇게 처리
export const changeNumStringForm = (value: string): string => {
  return value?.replaceAll("-", "");
};

// 위 함수와 다르게 형식도 number로 변환
export const changeNumRealForm = (value: string | number): number => {
  if (typeof value === "number") {
    return value;
  }
  return Number(value?.replaceAll(",", ""));
};

// 숫자 천단위 콤마 찍기
const NUMBER_FORMAT_REGX = /\B(?=(\d{3})+(?!\d))/g;

export const changeCommaFormat = (value: number) => {
  return value?.toString().replace(NUMBER_FORMAT_REGX, ",");
};

export const changeNumberFormat = (value: any) => {
  return value.replace(/[^0-9]/g, "");
};

// 예상 배송비 계산
export const calDeliveryFee = (
  width: number,
  depth: number,
  height: number,
  deliveryFeeList: any[]
): number => {
  return executeDeliveryFolmula(width + depth + height, deliveryFeeList);
};

// 배송비 계산식
const executeDeliveryFolmula = (
  value: number,
  deliveryFeeList: any[]
): number => {
  if (value && value <= 140) {
    return deliveryFeeList[0].deliveryFee;
  }
  if (value > 140 && value <= 160) {
    return deliveryFeeList[1].deliveryFee;
  }
  if (value > 160 && value <= 180) {
    return deliveryFeeList[2].deliveryFee;
  }
  if (value > 180 && value <= 190) {
    return deliveryFeeList[3].deliveryFee;
  }
  if (value > 190 && value <= 290) {
    return deliveryFeeList[4].deliveryFee;
  }
  if (value > 290 && value <= 350) {
    return deliveryFeeList[5].deliveryFee;
  }
  if (value > 350 && value <= 420) {
    return deliveryFeeList[6].deliveryFee;
  }
  if (value > 420 && value <= 600) {
    return deliveryFeeList[7].deliveryFee;
  }
  if (value > 600) {
    return 0;
  }
  return 0;
};

export const FindCategoryID = (value: string) => {
  let CategoryMap = new Map();
  CategoryMap.set("사무용품", 1);
  CategoryMap.set("카페/식당", 2);
  CategoryMap.set("의류/잡화점", 3);
  CategoryMap.set("학원/유치원", 4);
  CategoryMap.set("호텔/숙박", 5);
  CategoryMap.set("연구실/병원", 6);
  CategoryMap.set("기타", 7);
  return Promise.resolve(CategoryMap.get(value));
};

export const FindConditionID = (value: string) => {
  let ConditionMap = new Map();
  ConditionMap.set("새 상품", 1);
  ConditionMap.set("새 것처럼 깨끗", 2);
  ConditionMap.set("사용감 있으나 깨끗", 3);
  ConditionMap.set("사용감 많음", 4);
  return Promise.resolve(ConditionMap.get(value));
};

export const FindScratchID = (value: string) => {
  let ScratchMap = new Map();
  ScratchMap.set("없음", 1);
  ScratchMap.set("생활기스", 2);
  ScratchMap.set("보통기스", 3);
  ScratchMap.set("심한기스", 4);
  return Promise.resolve(ScratchMap.get(value));
};

export const convertURLtoFile = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
  const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };
  return new File([data], filename!, metadata);
};

export const findDiffValueCount = (originValue: any, editValue: any) => {
  const keys = Object.keys(originValue);
  let diffCount = 0;
  // userId, productTitle 패스하고 i는 2부터 시작
  for (let i = 2; i < keys.length; i++) {
    const key = keys[i];
    if (originValue[key] !== editValue[key]) {
      diffCount = diffCount + 1;
    }
  }
  return diffCount;
};

export const isTitleChanged = (originValue: any, editValue: any) => {
  return originValue["productTitle"] !== editValue["productTitle"];
};

type AccountRegExpMap = {
  [type: string]: any;
};

export const accountRegExpMap: AccountRegExpMap = {
  "(구)KB국민은행": {
    regExp: /^(\d{3})(\d{2})(\d{4})(\d{3})$/,
    finalRegExp: /^(\d{3})-(\d{2})-(\d{4})-(\d{3})$/,
    form: "$1-$2-$3-$4",
    maxLength: 12,
  },
  "(신)KB국민은행": {
    regExp: /^(\d{6})(\d{2})(\d{6})$/,
    finalRegExp: /^(\d{6})-(\d{2})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 14,
  },
  IBK기업은행: {
    regExp: /^(\d{3})(\d{6})(\d{2})(\d{3})$/,
    finalRegExp: /^(\d{3})-(\d{6})-(\d{2})-(\d{3})$/,
    form: "$1-$2-$3-$4",
    maxLength: 14,
  },
  NH농협은행: {
    regExp: /^(\d{3})(\d{4})(\d{4})(\d{2})$/,
    finalRegExp: /^(\d{3})-(\d{4})-(\d{4})-(\d{2})$/,
    form: "$1-$2-$3-$4",
    maxLength: 13,
  },
  "(구)신한은행": {
    regExp: /^(\d{3})(\d{2})(\d{6})$/,
    finalRegExp: /^(\d{3})-(\d{2})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 11,
  },
  "(신)신한은행": {
    regExp: /^(\d{3})(\d{3})(\d{6})$/,
    finalRegExp: /^(\d{3})-(\d{3})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 12,
  },
  우리은행: {
    regExp: /^(\d{4})(\d{3})(\d{6})$/,
    finalRegExp: /^(\d{4})-(\d{3})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 13,
  },
  KEB하나은행: {
    regExp: /^(\d{3})(\d{6})(\d{5})$/,
    finalRegExp: /^(\d{3})-(\d{6})-(\d{5})$/,
    form: "$1-$2-$3",
    maxLength: 14,
  },
  "(구)외환은행": {
    regExp: /^(\d{3})(\d{6})(\d{3})$/,
    finalRegExp: /^(\d{3})-(\d{6})-(\d{3})$/,
    form: "$1-$2-$3",
    maxLength: 12,
  },
  씨티은행: {
    regExp: /^(\d{3})(\d{6})(\d{3})$/,
    finalRegExp: /^(\d{3})-(\d{6})-(\d{3})$/,
    form: "$1-$2-$3",
    maxLength: 12,
  },
  DGB대구은행: {
    regExp: /^(\d{3})(\d{2})(\d{6})(\d{1})$/,
    finalRegExp: /^(\d{3})-(\d{2})-(\d{6})-(\d{1})$/,
    form: "$1-$2-$3-$4",
    maxLength: 12,
  },
  BNK부산은행: {
    regExp: /^(\d{3})(\d{4})(\d{4})(\d{2})$/,
    finalRegExp: /^(\d{3})-(\d{4})-(\d{4})-(\d{2})$/,
    form: "$1-$2-$3-$4",
    maxLength: 13,
  },
  SC제일은행: {
    regExp: /^(\d{3})(\d{2})(\d{6})$/,
    finalRegExp: /^(\d{3})-(\d{2})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 11,
  },
  케이뱅크: {
    regExp: /^(\d{3})(\d{3})(\d{6})$/,
    finalRegExp: /^(\d{3})-(\d{3})-(\d{6})$/,
    form: "$1-$2-$3",
    maxLength: 12,
  },
  카카오뱅크: {
    regExp: /^(\d{4})(\d{2})(\d{7})$/,
    finalRegExp: /^(\d{4})-(\d{2})-(\d{7})$/,
    form: "$1-$2-$3",
    maxLength: 13,
  },
};

export const changeAccountForm = (value: any, bank: string) => {
  value = value
    .replace(/[^0-9]/g, "")
    .replace(accountRegExpMap[bank].regExp, accountRegExpMap[bank].form);
  return value;
};

export const CardList = [
  { id: "기업 BC", cardCompany: "IBK_BC" },
  { id: "광주은행", cardCompany: "GWANGJUBANK" },
  { id: "롯데카드", cardCompany: "LOTTE" },
  { id: "KDB산업은행", cardCompany: "KDBBANK" },
  { id: "BC카드", cardCompany: "BC" },
  { id: "삼성카드", cardCompany: "SAMSUNG" },
  { id: "새마을금고", cardCompany: "SAEMAUL" },
  { id: "신한카드", cardCompany: "SHINHAN" },
  { id: "신협", cardCompany: "SHINHYEOP" },
  { id: "씨티카드", cardCompany: "CITI" },
  { id: "우리BC카드(BC 매입)", cardCompany: "WOORI" },
  { id: "우체국예금보험", cardCompany: "POST" },
  { id: "저축은행중앙회", cardCompany: "SAVINGBANK" },
  { id: "전북은행", cardCompany: "JEONBUKBANK" },
  { id: "제주은행", cardCompany: "JEJUBANK" },
  { id: "카카오뱅크", cardCompany: "KAKAOBANK" },
  { id: "케이뱅크", cardCompany: "KBANK" },
  { id: "토스뱅크", cardCompany: "TOSSBANK" },
  { id: "하나카드", cardCompany: "HANA" },
  { id: "현대카드", cardCompany: "HYUNDAI" },
  { id: "KB국민카드", cardCompany: "KOOKMIN" },
  { id: "NH농협카드", cardCompany: "NONGHYEOP" },
  { id: "Sh수협은행", cardCompany: "SUHYEOP" },
];

// let RegExpMap = new Map();
//   RegExpMap.set("(구)KB국민은행", {
//     regExp : /^(\d{3})(\d{2})(\d{4})(\d{3})$/,
//     form : `$1-$2-$3-$4`
//   });
//   RegExpMap.set("(신)KB국민은행", {
//     regExp : /^(\d{6})(\d{2})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("IBK기업은행", {
//     regExp : /^(\d{3})(\d{6})(\d{2})(\d{3})$/,
//     form : `$1-$2-$3-$4`
//   });
//   RegExpMap.set("NH농협은행", {
//     regExp : /^(\d{3})(\d{4})(\d{4})(\d{2})$/,
//     form : `$1-$2-$3-$4`
//   });
//   RegExpMap.set("(구)신한은행", {
//     regExp : /^(\d{3})(\d{2})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("(신)신한은행", {
//     regExp : /^(\d{3})(\d{3})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("우리은행", {
//     regExp : /^(\d{4})(\d{3})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("KEB하나은행", {
//     regExp : /^(\d{3})(\d{6})(\d{5})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("(구)외환은행", {
//     regExp : /^(\d{3})(\d{6})(\d{3})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("씨티은행", {
//     regExp : /^(\d{3})(\d{6})(\d{3})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("DGB대구은행", {
//     regExp : /^(\d{3})(\d{2})(\d{6})(\d{1})$/,
//     form : `$1-$2-$3-$4`
//   });
//   RegExpMap.set("BNK부산은행", {
//     regExp : /^(\d{3})(\d{4})(\d{4})(\d{2})$/,
//     form : `$1-$2-$3-$4`
//   });
//   RegExpMap.set("SC제일은행", {
//     regExp : /^(\d{3})(\d{2})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("케이뱅크", {
//     regExp : /^(\d{3})(\d{3})(\d{6})$/,
//     form : `$1-$2-$3`
//   });
//   RegExpMap.set("카카오뱅크", {
//     regExp : /^(\d{4})(\d{2})(\d{7})$/,
//     form : `$1-$2-$3`
//   });
// 밑의 주석내용은 프로젝트 세팅시 원래 있던 유틸함수. 나중에 혹시 필요할지 몰라 주석처리.
/*export const saveLoginInfo = (loginInfo: UserInfo) => {
  window.localStorage.setItem(
    process.env.REACT_APP_LOGIN_INFO ?? "",
    JSON.stringify(loginInfo) ?? ""
  );
};

export const clearLoginData = () => {
  window.localStorage.removeItem(process.env.REACT_APP_LOGIN_INFO ?? "");
};

export const getLoginInfoUserName = (): string => {
  const loginInfo: UserInfo = JSON.parse(
    window.localStorage.getItem(process.env.REACT_APP_LOGIN_INFO ?? "") ?? ""
  );
  return loginInfo.name ?? "";
};

export const getLoginInfoJwt = () => {
  const loginInfo = JSON.parse(
    window.localStorage.getItem(process.env.REACT_APP_LOGIN_INFO ?? "") ?? ""
  );
  return loginInfo.jwt ?? "";
};*/
