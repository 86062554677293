import React from "react";
import styled from "styled-components";

const MobileFooter = () => {
  return (
    <FooterSection>
      <FooterContainer>
        <FooterTitle>기업의 品격</FooterTitle>

        <FooterContentContainer>
          <FooterContent>
            WERT(뷔어트) | 사업자등록번호 : 143-81-35033
          </FooterContent>
          <FooterContent>통신판매업신고번호 : 2023-용인기흥-6347</FooterContent>
          <FooterContent>대표 : 최지우</FooterContent>
          <FooterContent>개인정보보호관리책임자 : 최지우</FooterContent>
          <FooterContent>계좌번호 : 우리은행 최지우 1005-804-591458</FooterContent>
          <FooterContent>경기 용인시 기흥구 강남서로 9, 7층 703호 엠919호</FooterContent>
          <FooterContent>고객센터 : 070-4102-3503 | 메일 : help@gipum.net</FooterContent>
        </FooterContentContainer>

        <FooterLinks>
          <Link>
            <a
              href="https://jagged-tiara-c80.notion.site/d6c81251283743d1b995db1151d7e5cf"
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: 'none', color: 'var(--gray-600, #545E68)'}}
            >
              회사소개
            </a>
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link>
            <a
              href="https://jagged-tiara-c80.notion.site/aded96d138984fd9a0fb846d7322595f?pvs=4"
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: 'none', color: 'var(--gray-600, #545E68)'}}
            >
              이용약관
            </a>
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link>
            <a
              href="https://jagged-tiara-c80.notion.site/6e004af1ae27460799c1884b663bddf8"
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: 'none', color: 'var(--gray-600, #545E68)'}}
            >
              개인정보처리방침
            </a>
          </Link>
        </FooterLinks>
      </FooterContainer>
    </FooterSection>
  );
};

const FooterSection = styled.div`
  background: #F7F7F7;
  margin-top: 5rem;
`;

const FooterContainer = styled.div`
  margin-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const FooterTitle = styled.p`
display: inline-block
color: var(--gray-900, #212529);
font-family: Pretendard;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
`

const FooterContentContainer = styled.div`
display: flex;
margin-top: 1rem;
margin-bottom: 1rem;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
`;

const FooterContent = styled.div`
color: var(--gray-300, #8B97A2);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;`;

const Link = styled.strong`
color: var(--gray-600, #545E68);
font-feature-settings: 'clig' off, 'liga' off;

font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
`;

const FooterLinks = styled.div`
display: flex;
align-items: center;
gap: 16px;
align-self: stretch;
`

export default MobileFooter;
