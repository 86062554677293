import styled from "styled-components";

/** 마이페이지, 마이페이지 수정, 상담신청 모두에서 쓰고 있음 */

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  margin-bottom: 210px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const CommonText = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const InfoTitleWrap = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const InfoTitle = styled(CommonText)`
  color: #000;
  font-size: 28px;
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
`;

export const InfoButton = styled(Button)<{
  border: string;
  background: string;
}>`
  display: flex;
  width: 100%;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  border: ${(props) => (props.border ? `1px solid var(--Main)` : "none")};
  background: ${(props) =>
    props.background === "Main" ? "var(--Main)" : "#EEE"};
`;

export const InfoButtonText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const InfoMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
`;

export const InfoUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const InfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const Address = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const LoadFromUserCheckboxWrap = styled.div`
  display: flex;
  height: 23px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const LoadFromUserCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadFromUserText = styled(CommonText)`
  font-size: 16px;
  font-weight: 500;
`;

export const NameTitleWrap = styled.div`
  width: 81px;
  height: 22px;
  flex-shrink: 0;
`;

export const CommonTitle = styled(CommonText)`
  color: #5f6268;
  font-size: 16px;
  font-weight: 500;
`;

export const CommonInput = styled.input.attrs((props) => ({
  type: props.type,
}))`
  padding: 10px 16px;
  flex: 1;
  line-height: 22px; /* 137.5% */
  font-size: 16px;
  color: #212529;

  border-radius: 8px;
  border: 1px solid #dfe2e9;

  &::placeholder {
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const ItemTitle = styled.div`
  width: 180px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
`;

export const ItemInput = styled(CommonInput)``;

export const AddressTitle = styled(ItemTitle)``;

export const Container = styled.div`
  display: flex;
  width: 768px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1;
`;

export const InputWrap = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
`;

export const BasicInput = styled(CommonInput)`
  width: 535px;
  flex: none;
  box-sizing: border-box;
`;

export const AddressBasicInput = styled(CommonInput)`
  width: 420px;
  flex: none;
  box-sizing: border-box;
`;

export const AddressDetailInput = styled(CommonInput)``;

export const CheckOrSearchButton = styled(Button)<{ locked?: boolean }>`
  display: flex;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${(props) => (props.locked ? "#DFE2E9" : "#009ee2")};
`;

export const CheckOrSearchButtonText = styled.span<{ locked?: boolean }>`
  color: ${(props) => (props.locked ? "#ACB1BA" : "#009ee2")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
`;

export const ButtonWrap = styled.div`
  width: 100%;
`;
