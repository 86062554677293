import React from "react";
import AppHeader from "../../components/Header";
import styled from "styled-components";
import {
  Wrap,
  Title,
  TitleText,
  SignupChoice,
  SignupWrap,
  SignupContent,
  SignupText,
  SignupMain,
  Button,
  ContentText,
} from "./styles";
import { ReactComponent as Logo } from "../../assets/login/img-login-logo.svg";
import { ReactComponent as BizSignUp } from "../../assets/signup/ic-signup-biz.svg";
import { ReactComponent as CommonSignUp } from "../../assets/signup/ic_signup-common.svg";
// import CommonSignupImg from "../../assets/signup/ic-signup-common.png";
// import BizSignupImg from "../../assets/signup/ic-signup-biz.png";
import { Link } from "react-router-dom";

export default function Signup() {
  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Title>
          <Logo width={151} height={38} />
          <TitleText>
            회원가입 후 기업의 품격에서 다양한 서비스를 이용해보세요
          </TitleText>
        </Title>
        <SignupChoice>
          <SignupWrap>
            <SignupContent>
              {/* <img src={CommonSignupImg} alt="일반회원 가입" /> */}
              <CommonSignUp />
              <SignupMain>
                <SignupText>
                  <ContentText fontWeight={700}>일반 회원가입</ContentText>
                  <ContentText fontWeight={500}>일반 사용자</ContentText>
                </SignupText>
                <ButtonLink to="/signup/common">
                  <Button>가입하기</Button>
                </ButtonLink>
              </SignupMain>
            </SignupContent>
          </SignupWrap>
          <SignupWrap>
            <SignupContent>
              {/* <img src={BizSignupImg} alt="Biz회원 가입" /> */}
              <BizSignUp />
              <SignupMain>
                <SignupText>
                  <ContentText fontWeight={700}>Biz 회원가입</ContentText>
                  <ContentText fontWeight={500}>사업자 회원</ContentText>
                </SignupText>
                <ButtonLink to="/signup/biz">
                  <Button>가입하기</Button>
                </ButtonLink>
              </SignupMain>
            </SignupContent>
          </SignupWrap>
        </SignupChoice>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonLink = styled(Link)`
  width: 100%;
  text-decoration-line: none;
`;
