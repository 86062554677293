import React from "react";
import styled from "styled-components";

const MobileMain = () => {
  return (
    <IntroSection>
      <NoticeTitle>
        안내 사항
      </NoticeTitle>
      <NoticeContent>
        원활한 접속을 위해 <Bold>PC 기기로 접속</Bold>해주세요
      </NoticeContent>

      <ServiceIntro>
        사장님!<br/>
        <BoldContent>돈 주고 버리지</BoldContent> 마세요<br/>
        기업의 품격에서<br/>
        <BoldContent>기품있게 팔고</BoldContent> 돈 벌어 가세요
      </ServiceIntro>

      <ServiceIntroExtra>저희가 번거로운 일은 모두 해드리겠습니다</ServiceIntroExtra>
    </IntroSection>
  );
};

const IntroSection = styled.section`
display: flex;
flex-direction: column;
margin-left: 2rem;
`;

const NoticeTitle = styled.span`
width: fit-content;
margin-top: 5rem;
color: #009EE2;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Pretendard;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 160%;
border-bottom: 2px solid var(--1, #009EE2);
`;

const NoticeContent = styled.span`
margin-top: 1rem;
color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%;
`;

const ServiceIntro = styled.span`
margin-top: 5rem;
color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 33.6px */
letter-spacing: 0.35px;
`;

const ServiceIntroExtra = styled(NoticeContent)`
margin-top: 1rem;
letter-spacing: -0.2px;
`

const Bold = styled.span`
color: #009EE2;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 160%;
`;

const BoldContent = styled(Bold)`
font-size: 24px;
`

export default MobileMain;
