import React from "react";
import {
  Wrap,
  Content,
  CompanyNameText,
  CompanyInfo,
  LineWrap,
  CompanyInfoText,
  BottomWrap,
  LinkWrap,
  LinkText,
  SNSLinkWrap,
} from "./styles";
import { ReactComponent as LineImg } from "../../assets/footer/line.svg";
import { ReactComponent as SNSBlogImg } from "../../assets/footer/ic-footer-sns-blog.svg";
// import { ReactComponent as SNSNaverImg } from "../../assets/footer/ic-footer-sns-naver.svg";
import { ReactComponent as SNSInstagramImg } from "../../assets/footer/ic-footer-sns-instagram.svg";
import { ReactComponent as SNSYoutubeImg } from "../../assets/footer/ic-footer-sns-youtube.svg";
import { ReactComponent as SNSKakaoImg } from "../../assets/footer/ic-footer-sns-kakao.svg";

const cursorStyle = {
  cursor: "pointer",
};

const AppFooter = () => (
  <Wrap>
    <Content>
      <CompanyNameText>기업의 品격</CompanyNameText>
      <CompanyInfo>
        <LineWrap>
          <CompanyInfoText>주식회사 기업의품격</CompanyInfoText>
          <LineImg />
          <CompanyInfoText>사업자등록번호 : 143-81-35033</CompanyInfoText>
        </LineWrap>
        <CompanyInfoText>
          통신판매업신고번호 : 2023-용인기흥-6347
        </CompanyInfoText>
        <LineWrap>
          <CompanyInfoText>대표 : 최지우</CompanyInfoText>
          <LineImg />
          <CompanyInfoText>개인정보보호관리책임자 : 최지우</CompanyInfoText>
          <LineImg />
          <CompanyInfoText>
            계좌번호 : 우리은행 최지우 1005-804-591458
          </CompanyInfoText>
        </LineWrap>
        <CompanyInfoText>
          경기 용인시 기흥구 강남서로 9, 7층 703호 엠919호
        </CompanyInfoText>
        <LineWrap>
          <CompanyInfoText>고객센터 : 070-4102-3503</CompanyInfoText>
          <LineImg />
          <CompanyInfoText>메일 : help@gipum.net</CompanyInfoText>
        </LineWrap>
      </CompanyInfo>
      <BottomWrap>
        <LinkWrap>
          <LinkText
            onClick={() => {
              window.open(
                "https://jagged-tiara-c80.notion.site/d6c81251283743d1b995db1151d7e5cf",
                "_blank"
              );
            }}
            style={cursorStyle}
          >
            회사소개
          </LinkText>
          <LineImg />
          <LinkText
            onClick={() => {
              window.open(
                "https://jagged-tiara-c80.notion.site/aded96d138984fd9a0fb846d7322595f?pvs=4",
                "_blank"
              );
            }}
            style={cursorStyle}
          >
            이용약관
          </LinkText>
          <LineImg />
          <LinkText
            onClick={() => {
              window.open(
                "https://jagged-tiara-c80.notion.site/6e004af1ae27460799c1884b663bddf8?pvs=4",
                "_blank"
              );
            }}
            style={cursorStyle}
          >
            개인정보 처리방침
          </LinkText>
        </LinkWrap>
        <SNSLinkWrap>
          <SNSBlogImg
            style={cursorStyle}
            onClick={() => {
              window.open("https://blog.naver.com/gipumnet", "_blank");
            }}
          />
          {/* <SNSNaverImg style={cursorStyle} /> */}
          <SNSInstagramImg
            style={cursorStyle}
            onClick={() => {
              window.open("https://www.instagram.com/gipum.corp/", "_blank");
            }}
          />
          <SNSYoutubeImg
            style={cursorStyle}
            onClick={() => {
              window.open("https://www.youtube.com/@user-fp8by8wo6k", "_blank");
            }}
          />
          <SNSKakaoImg
            style={cursorStyle}
            onClick={() => {
              window.open("http://pf.kakao.com/_BKrxjG", "_blank");
            }}
          />
        </SNSLinkWrap>
      </BottomWrap>
    </Content>
  </Wrap>
);

export default React.memo(AppFooter);
