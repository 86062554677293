import { useMutation } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

interface Param {
  cartId: number;
  cartProductIds: number[];
}

export default function useDeleteCart() {
  // userId: number,
  // excludeCompletedProduct: boolean
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param: Param) =>
      axiosAuthClient.patch("/web/carts/status", param),
    onSuccess: ({ data }) => {
      const { code } = data;
      console.log(data);
      if (code === 1000) {
        window.location.reload();
        // queryClient.invalidateQueries({
        //   queryKey: ["getCart", userId, excludeCompletedProduct],
        // });
        // alert("장바구니에서 삭제되었습니다.");
      } else {
        alert(`삭제에 실패했습니다(code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });
}
