import React, { useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import { Wrap, Content, TitleWrap, Title } from "./styles";

import SellingStatus from "./selling";
import BuyingStatus from "./buying";

export default function TradingStatus() {
  const [selling, setSelling] = useState(true);
  const [buying, setBuying] = useState(false);

  const onSelect = (type: string) => {
    if (type === "selling") {
      setSelling(true);
      setBuying(false);
    } else {
      setBuying(true);
      setSelling(false);
    }
  };

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>내 매매현황</Title>
          </TitleWrap>
          {selling && (
            <SellingStatus
              selling={selling}
              buying={buying}
              onSelect={onSelect}
            />
          )}
          {buying && (
            <BuyingStatus
              selling={selling}
              buying={buying}
              onSelect={onSelect}
            />
          )}
        </Content>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
