import React from "react";
import styled from "styled-components";

interface Props {
  type: any;
}

export default function EasyPayExplanaion({ type }: Props) {
  return (
    <EasyPayExplanationWrap>
      {type === "카카오페이" && (
        <>
          <EasyPayExplanation>
            • 카카오페이는 카카오톡에서 카드를 등록, 간단하게 비밀번호만으로
            결제할 수 있는 빠르고 편리한 모바일 결제 서비스입니다.
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 지원 카드 : 모든 카드 등록/결제 가능
          </EasyPayExplanation>
        </>
      )}
      {type === "네이버페이" && (
        <>
          <EasyPayExplanation>
            • 주문 변경 시 카드사 혜택 및 할부 적용 여부는 해당 카드사 정책에
            따라 변경될 수 있습니다.
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 네이버페이는 네이버ID로 별도 앱 설치 없이 신용카드 또는 은행계좌
            정보를 등록하여 네이버페이 비밀번호로 결제할 수 있는 간편결제
            서비스입니다.
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 결제 가능한 신용카드: 신한, 삼성, 현대, BC, 국민, 하나, 롯데,
            NH농협, 씨티, 카카오뱅크
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 결제 가능한 은행: NH농협, 국민, 신한, 우리, 기업, SC제일, 부산,
            경남, 수협, 우체국, 미래에셋대우, 광주, 대구, 전북, 새마을금고,
            제주은행, 신협, <br /> &nbsp;&nbsp;  하나은행, 케이뱅크, 카카오뱅크,
            삼성증권
          </EasyPayExplanation>
        </>
      )}
      {type === "페이코" && (
        <>
          <EasyPayExplanation>PAYCO 간편결제 안내</EasyPayExplanation>
          <EasyPayExplanation>
            • PAYCO는 온/오프라인 쇼핑은 물론 송금, 멤버십 적립까지 가능한 통합
            서비스입니다.
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 휴대폰과 카드 명의자가 동일해야 결제 가능하며, 결제금액 제한은
            없습니다.
          </EasyPayExplanation>
          <EasyPayExplanation>
            • 지원카드 : 모든 국내 신용/체크카드
          </EasyPayExplanation>
        </>
      )}
    </EasyPayExplanationWrap>
  );
}

const EasyPayExplanationWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 822px;
`;

const EasyPayExplanation = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;
