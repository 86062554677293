import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const SubTitleWrap = styled.div`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const SubTitle = styled.div`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 91.667% */
`;

export const PayWrap = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const CommonText = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const InfoCommonWrap = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  border: 1px solid #d8d8d8;
  background: #fff;
`;

export const ProductInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const InfoTitleWrap = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ProductInfoTitleWrap = styled(InfoTitleWrap)`
  padding: 0px;
`;

export const InfoTitle = styled(CommonText)``;

export const ProductInfo = styled(InfoCommonWrap)``;

export const PriceInfo = styled(InfoCommonWrap)``;

export const PayMethodInfo = styled(InfoCommonWrap)``;

export const ProcessInfo = styled(InfoCommonWrap)`
  justify-content: center;
  align-items: center;
  background: #e5f7ff;
`;

export const BuyerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
`;

export const PIContent = styled.div`
  display: flex;
  width: 460px;
  align-items: flex-start;
  gap: 20px;
`;

export const PIContentSummary = styled.div`
  height: 104px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const PIName = styled(CommonText)``;

export const PIPrice = styled(CommonText)`
  font-size: 16px;
  font-weight: 500;
`;

export const PriceInfoContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
`;

export const PriceInfoContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const ItemPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const DeliveryPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const DeliveryPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const TotalPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 700;
`;

export const TotalPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 700;
`;

export const PayMethodContent = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 10px;
  border: 1px solid #dfe3e6;
  background: #fff;
`;

export const EasyPay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Line = styled.hr`
  width: 820px;
  height: 1px;
  background: #eaecee;
`;

export const CommonPay = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const EasyPayContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const EasyPayCheckboxWrap = styled.div`
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const EasyPayCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EasyPayDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 24px; */
  flex: 1 0 0;
`;

export const EasyPayTitle = styled(CommonText)`
  font-size: 18px;
  line-height: 24px;
`;

export const EasyPaySummary = styled(CommonText)`
  color: #8b97a2;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
`;

export const EasyPayAddButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 0px 14px;
  align-items: center;
  gap: 8px;

  border-radius: 30px;
  border: 1px solid #dfe3e6;
`;

export const EasyPayCardWrap = styled.div`
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const EasyPayCard = styled.div`
  display: flex;
  width: 225px;
  padding: 40px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;

  border-radius: 10px;
  border: 1px dashed #dfe3e6;
  background: #f4f6f7;
`;

export const EasyPayCardComment = styled.span`
  color: #212529;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;

export const CPCheckboxWrap = styled.div`
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: flex-start;
`;

export const CPCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CPTitle = styled(CommonText)`
  font-size: 18px;
  line-height: 24px;
`;

export const ProcessInfoTitleWrap = styled(InfoTitleWrap)`
  width: 100%;
  justify-content: flex-start;
`;

export const ProcessInfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Step = styled.div`
  display: flex;
  height: 142px;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #b3b3b3;
  background: #fff;
`;

export const StepContent = styled.div`
  display: flex;
  height: 103px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const StepTextWrap = styled.div`
  display: flex;
  width: 74px;
  height: 45px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

export const StepText = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const BuyerDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  align-self: stretch;
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;

export const PayButton = styled(Button)<{
  border: string;
  background: string;
}>`
  display: flex;
  width: 100%;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  border: ${(props) => (props.border ? `1px solid var(--Main)` : "none")};
  background: ${(props) =>
    props.background === "Main" ? "var(--Main)" : "#EEE"};
`;

export const PayButtonText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;

export const BuyerBasicDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const BuyerBasicDetailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const LoadFromUser = styled.div`
  display: flex;
  width: 338px;
  align-items: center;
  gap: 10px;
`;

export const BuyerName = styled.div`
  display: flex;
  flex-direction: column;
  width: 505px;
  gap: 5px;
`;

export const BuyerContact = styled.div`
  display: flex;
  width: 460px;
  align-items: center;
  gap: 20px;
`;

export const BuyerAddress = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const LoadFromUserCheckboxWrap = styled.div`
  display: flex;
  height: 23px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const LoadFromUserCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadFromUserText = styled(CommonText)`
  font-size: 16px;
  font-weight: 500;
`;

export const BuyerNameTitleWrap = styled.div`
  width: 81px;
  height: 22px;
  flex-shrink: 0;
`;

export const BuyerCommonTitle = styled(CommonText)`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const BuyerCommonInput = styled.input.attrs({
  type: "text",
})`
  padding: 10px 16px;
  flex: 1 0 0;
  line-height: 22px; /* 137.5% */
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;

  &::placeholder {
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const BuyerNameTitle = styled(BuyerCommonTitle)``;

export const TitleDesc = styled.span`
  color: #8b8b8e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
`;

export const AccountInputWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

export const AccountSelect = styled.select`
  display: flex;
  width: 153px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;

  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const BuyerNameInput = styled(BuyerCommonInput)``;

export const BuyerContactTitle = styled(BuyerCommonTitle)`
  width: 80px;
`;

export const BuyerContactInput = styled(BuyerCommonInput)``;

export const BuyerAddressTitle = styled(BuyerCommonTitle)`
  width: 80px;
`;

export const BuyerAddressWrap = styled.div`
  display: flex;
  width: 768px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const BuyerAddressInputWrap = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const BuyerAddressInput1 = styled(BuyerCommonInput)`
  width: 563px;
  flex: none;
  box-sizing: border-box;
`;

export const BuyerAddressInput2 = styled(BuyerCommonInput)`
  width: 563px;
  flex: 1 0 0;
  box-sizing: border-box;
`;

export const SearchAddressButton = styled(Button)`
  display: flex;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #009ee2;
`;

export const SearchAddressText = styled.span`
  color: #009ee2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
`;

export const DeliveryMessage = styled.textarea`
  display: flex;
  height: 126px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 1px solid #d8d8d8;
  font-size: 16px;

  &::placeholder {
    color: #b3b3b3;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
`;
