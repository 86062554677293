import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { isEmailValid } from "../../../utils/utility";
import { axiosClient } from "../../../apis/general";
import styled from "styled-components";

interface propsType {
  onNext: (verifyCode: number) => void;
  userId?: string;
}

export default function EmailInput({ onNext, userId }: propsType) {
  const [email, setEmail] = useState("");
  const [commentShow, setCommentShow] = useState(false);

  const sendEmailAndGoNext = async () => {
    try {
      const { data } = await axiosClient.post("/web/auth/email", { email });
      if (data.code === 1000) {
        const {
          result: { verifyCode },
        } = data;
        onNext(verifyCode);
        return;
      }
      alert(data.message);
    } catch (error) {
      alert(error);
    }
  };

  const CompareIDAndEmail = async (userId: string, email: string) => {
    try {
      const { data } = await axiosClient.get(
        `/web/auth/id-company-email?userId=${userId}&companyEmail=${email}`
      );
      if (data.code === 1000) {
        sendEmailAndGoNext();
        return;
      }
      if (data.code === 2223) {
        setCommentShow(true);
        return;
      }
      if (data.code === 2151) {
        alert(data.message);
        return;
      }
      alert(data.messsage);
    } catch (error) {
      alert(error);
    }
  };

  const onRequestAuth = () => {
    CompareIDAndEmail(userId ?? "", email);
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>이메일 주소를 입력해주세요.</InputTitle>
        <Input
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          placeholder="company@company.co.kr"
          minLength={2}
          maxLength={50}
        />
        {commentShow && <Comment>이메일이 일치하지 않습니다.</Comment>}
      </InputWrap>
      <Button onClick={onRequestAuth} disabled={!isEmailValid(email)}>
        메일 인증
      </Button>
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
