import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { isEmailValid } from "../../../utils/utility";
import { axiosClient } from "../../../apis/general";
import styled from "styled-components";

interface propsType {
  onNext: (verifyCode: number, email: string) => void;
}

export default function SignUpEmailInput({ onNext }: propsType) {
  const [email, setEmail] = useState("");
  const [commentShow, setCommentShow] = useState(false);
  const [comment, setComment] = useState(false);

  const checkDuplicateEmail = async (email: string) => {
    try {
      const { data } = await axiosClient.get(
        `/web/auth/duplicate-email?email=${email}`
      );
      if (data.code === 1000) {
        sendEmailAndGoNext(email, onNext);
        return;
      }
      setCommentShow(true);
      setComment(data.message);
    } catch (error) {
      alert(error);
    }
  };

  const sendEmailAndGoNext = async (
    email: string,
    onNext: (verifyCode: number, email: string) => void
  ) => {
    try {
      const { data } = await axiosClient.post("/web/auth/email", { email });
      if (data.code === 1000) {
        const {
          result: { verifyCode },
        } = data;
        onNext(verifyCode, email);
        return;
      }
      alert(data.message);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>이메일 주소를 입력해주세요.</InputTitle>
        <Input
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          placeholder="company@company.co.kr"
          minLength={2}
          maxLength={50}
        />
        {commentShow && <Comment>{comment}</Comment>}
      </InputWrap>
      <Button
        onClick={() => checkDuplicateEmail(email)}
        disabled={!isEmailValid(email)}
      >
        메일 인증
      </Button>
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
