import React, { useEffect } from "react";
import styled from "styled-components";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../apis/general";
import { PayOrderIdState } from "../../recoil/pay";
import { useRecoilState } from "recoil";

export default function PaymentsSuccess() {
  const navigate = useNavigate();
  const [orderId] = useRecoilState(PayOrderIdState);
  const location = useLocation();

  useEffect(() => {
    const SendEmail = async () => {
      try {
        await axiosClient.post("/web/payments/email", {
          orderId,
        });
      } catch (error) {
        alert(error);
      }
    };
    SendEmail();
  }, []);

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container>
          <Title>결제 완료</Title>
          <Text>정상적으로 결제가 완료되었어요</Text>
          {location.state && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Text>〈 가상계좌 정보 〉</Text>
              <Text>•은행 : {location.state.bankName} </Text>
              <Text>•계좌번호 : {location.state.accountNumber} </Text>
            </div>
          )}
          <Button onClick={() => navigate("/productinquiry")}>
            인기 상품 보러가기
          </Button>
        </Container>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 120px;
  padding-bottom: 76px;
`;

export const Title = styled.div`
  color: var(--gray-900);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const Text = styled.div`
  color: var(--gray-900);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;

export const Button = styled.button`
  display: flex;
  width: 407px;
  height: 44px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Main);

  color: var(--Main, #009ee2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */

  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;
