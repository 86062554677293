import { atom } from "recoil";

export const PhoneNumberState = atom<string>({
  key: "PhoneNumberState",
  default: "",
});

export const EmailState = atom<string>({
  key: "EmailState",
  default: "",
});

export const UserIdState = atom<string>({
  key: "UserIdState",
  default: "",
});
