import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";
import { UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";

const getBuyingStatus = async (
  userId: number,
  page: number,
  productName?: string
) => {
  const { data } = await axiosAuthClient.get(
    `/web/products/purchased?userId=${userId}&productName=${productName}&page=${page}`
  );
  console.log(data);
  return data;
};

export default function useGetBuyingStatus(page: number, productName?: string) {
  const [userId] = useRecoilState(UserDBIdState);
  return useQuery(
    ["getbuyingstatus", page, productName],
    () => getBuyingStatus(userId, page, productName),
    {
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (err) => alert(err),
      select: ({ result: { purchasedProducts, totalCount } }) => ({
        purchasedProducts,
        totalCount,
      }),
    }
  );
}
