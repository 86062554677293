import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { axiosClient } from "../../../apis/general";
import { useQuery } from "@tanstack/react-query";
import { changePhoneContactForm } from "../../../utils/utility";
import styled from "styled-components";

interface propsType {
  onNext: (verifyCode: number, phoneNumber: string) => void;
  userId?: string;
  purpose?: string;
}

const IdentityVerify = async (phoneNumber: string) => {
  const { data } = await axiosClient.post(`/web/auth/phone`, { phoneNumber });
  return data;
};

export default function PhoneInput({
  onNext,
  userId,
  purpose,
}: propsType): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [commentShow, setCommentShow] = useState(false);

  const { refetch: identityVerifyRefetch } = useQuery(
    ["identity-verify"],
    () => IdentityVerify(phoneNumber),
    {
      enabled: false,
      onSuccess: (data) => {
        const { code } = data;
        if (code === 1000) {
          const {
            result: { verifyCode },
          } = data;
          onNext(verifyCode, phoneNumber);
        } else {
          const { message } = data;
          alert(`${message}(code: ${code})`);
        }
      },
      onError: (err) => alert(err),
    }
  );

  const CompareIDAndPhone = async (userId: string, phoneNumber: string) => {
    try {
      const { data } = await axiosClient.get(
        `/web/auth/id-phonenumber?userId=${userId}&phoneNumber=${phoneNumber}`
      );
      if (data.code === 1000) {
        identityVerifyRefetch();
        return;
      }
      if (data.code === 2222) {
        setCommentShow(true);
        return;
      }
      alert(data.messsage);
    } catch (error) {
      alert(error);
    }
  };

  const onRequestAuth = () => {
    if (purpose === "find-pw") {
      CompareIDAndPhone(userId ?? "", phoneNumber);
    } else {
      identityVerifyRefetch();
    }
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>가입시 사용한 전화번호를 입력하세요</InputTitle>
        <Input
          value={phoneNumber}
          onChange={({ target: { value } }) =>
            setPhoneNumber(changePhoneContactForm(value))
          }
          placeholder="‘-’없이 숫자만 입력"
          maxLength={13}
          tabIndex={1}
        />
        {commentShow && (
          <Comment>잘못된 전화번호입니다. 다시 시도해주세요.</Comment>
        )}
      </InputWrap>
      <Button
        onClick={onRequestAuth}
        tabIndex={2}
        disabled={!(phoneNumber.replaceAll("-", "").length === 11)}
      >
        본인 인증
      </Button>
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
