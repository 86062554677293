import React, { useState } from "react";
import {
  Input as LoginInput,
  InputContainer,
  InputTitle as LoginInputTitle,
  InputWrap,
  LoginButton as ResetButton,
  LoginButtonText as ResetButtonText,
  Main,
} from "../../pages/login/styles";
import styled from "styled-components";
import Comment from "./Comment";
import { axiosClient } from "../../apis/general";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { isPasswordEqual, isPasswordValid } from "../../utils/utility";

interface PropsType {
  userId: string;
}

interface ParamType {
  userId: string;
  password: string;
}

// 공통 컴포넌트 폴더에 있지만 로그인의 스타일을 사용
export default function PWReset({ userId }: PropsType) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [warn, setWarn] = useState(false);
  const [warnContent, setWarnContent] = useState("");

  const AllInputsFilled = password.length !== 0 && passwordConfirm.length !== 0;

  const requestResetPW = useMutation({
    mutationFn: (param: ParamType) =>
      axiosClient.patch("/web/auth/password", param),
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        alert("비밀번호가 재설정되었습니다. 다시 로그인해주세요.");
        navigate("/login");
      } else {
        const { message } = data;
        alert(`${message}`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const handleReset = () => {
    if (!isPasswordValid(password)) {
      setWarn(true);
      setWarnContent("비밀번호가 유효하지 않습니다.");
      return;
    }

    if (!isPasswordEqual(password, passwordConfirm)) {
      setWarn(true);
      setWarnContent("비밀번호가 일치하지 않습니다.");
      return;
    }

    warn && setWarn(false);
    requestResetPW.mutate({
      userId,
      password,
    });
  };

  return (
    <Main>
      <InputContainer>
        <input type="text" value={userId} hidden onChange={() => {}} />
        <InputWrap>
          <InputTitle>비밀번호</InputTitle>
          <Input
            type="password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            placeholder="비밀번호를 입력해주세요."
          />
        </InputWrap>
        <InputWrap>
          <InputTitle>비밀번호 확인</InputTitle>
          <Input
            type="password"
            value={passwordConfirm}
            onChange={({ target: { value } }) => setPasswordConfirm(value)}
            placeholder="비밀번호를 다시 입력해주세요."
          />
        </InputWrap>
      </InputContainer>
      <Comment show={warn} content={warnContent} emptyWidth="6.5rem" />
      <ResetButton
        disabled={!AllInputsFilled}
        isActive={AllInputsFilled}
        onClick={handleReset}
      >
        <ResetButtonText isActive={AllInputsFilled}>
          재설정 완료
        </ResetButtonText>
      </ResetButton>
    </Main>
  );
}

const InputTitle = styled(LoginInputTitle)`
  width: 6.56rem;
  text-align: left;
`;

const Input = styled(LoginInput)`
  &::placeholder {
    text-align: left;
  }
`;
