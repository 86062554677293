import React, { useState } from "react";
import {
  Container,
  InputWrap,
  LeftInput,
  RightInput,
  ItemWrap,
  ItemTitle,
  InputContainer,
  CheckOrSearchButton,
  Input,
  AddressWrap,
  SignUpButton,
} from "./styles";
import DaumPostcode from "react-daum-postcode";
import { Modal } from "antd";

import useSignupStates from "../../../../hooks/signup/useSignupStates";
import {
  isEmailValid,
  isIDValid,
  isNameValid,
  isPasswordEqual,
  isPasswordValid,
} from "../../../../utils/utility";
import Comment from "../../SignUpComment";
import useSignUp from "../../../../hooks/signup/useSignUp";
import useCheckDuplicateID from "../../../../hooks/signup/useCheckDuplicateID";
import { PhoneNumberState } from "../../../../recoil/auth";
import { useRecoilState } from "recoil";

export default function SignupForm() {
  const [number] = useRecoilState(PhoneNumberState);
  const {
    state: {
      isBiz,
      userId,
      password,
      passwordConfirm,
      phoneNumber,
      address,
      addressDetail,
      name,
      email,
    },
    action,
  } = useSignupStates(number);

  const [idWarn, setIdWarn] = useState(false);
  const [passwordWarn, setPasswordWarn] = useState(false);
  const [pwConfirmWarn, setPwConfirmWarn] = useState(false);
  const [nameWarn, setNameWarn] = useState(false);
  const [emailWarn, setEmailWarn] = useState(false);
  const [addressWarn, setAddressWarn] = useState(false);
  const [addressDetailWarn, setAddressDetailWarn] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [idDuplicateCheck, setIdDuplicateCheck] = useState(false);
  const { refetch } = useCheckDuplicateID(userId, setIdDuplicateCheck);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data.address.split(" ").splice(2).join(" ");
    action({ type: "address", payload: sidoSigungu + splitAddress });
    setAddressWarn(false);
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const requestSignUp = useSignUp({ isBiz: false });

  const onRequestSignUp = (): void => {
    const idCheck = isIDValid(userId);
    const pwCheck = isPasswordValid(password);
    const pwConfirmCheck = isPasswordEqual(password, passwordConfirm);
    const nameCheck = isNameValid(name);
    const emailCheck = isEmailValid(email);
    const addressCheck = address ? true : false;
    const addressDetailCheck = addressDetail ? true : false;
    const allCheck = [
      idCheck,
      pwCheck,
      pwConfirmCheck,
      nameCheck,
      emailCheck,
      addressCheck,
      addressDetailCheck,
    ];
    if (allCheck.includes(false)) {
      // 원래는 setState함수로 경로를 먼저 표시한뒤 경고표시 상태로 입력값을 체트함.
      // 그러나 setState함수가 비동기적으로 작동하기 때문에 가입하기 버튼을 빠르게 누르면
      // 원하는대로 동작하지 않는 경우 발생. 따라서 위와 같이 유효성 검사 먼저 진행한 뒤
      // 이 조건문에서 setState로 경고 표시만 표출한다.
      // 하드 코딩. 추후 리팩토링 필요
      alert("입력값을 확인해주세요.");
      setIdWarn(!idCheck);
      setPasswordWarn(!pwCheck);
      setPwConfirmWarn(!pwConfirmCheck);
      setNameWarn(!nameCheck);
      setEmailWarn(!emailCheck);
      setAddressWarn(!addressCheck);
      setAddressDetailWarn(!addressDetailCheck);
      return;
    }
    if (idDuplicateCheck === false) {
      alert("아이디 중복확인을 해주세요");
      return;
    }
    requestSignUp.mutate({
      isBiz,
      userId,
      password,
      passwordConfirm,
      phoneNumber,
      address,
      addressDetail,
      name,
      email,
    });
  };

  return (
    <Container>
      <InputWrap>
        <LeftInput>
          <ItemWrap>
            <ItemTitle>아이디*</ItemTitle>
            <InputContainer>
              <Input
                type="text"
                value={userId}
                onChange={(e) =>
                  action({ type: "userId", payload: e.target.value })
                }
                tabIndex={1}
                placeholder={"아이디를 입력해주세요"}
                onBlur={() => {
                  setIdWarn(!isIDValid(userId));
                }}
                minLength={5}
                maxLength={20}
              />
              <CheckOrSearchButton
                onClick={() => {
                  isIDValid(userId)
                    ? refetch()
                    : alert("아이디를 확인해주세요.");
                }}
                tabIndex={2}
              >
                ID 중복 확인
              </CheckOrSearchButton>
            </InputContainer>
          </ItemWrap>
          <Comment
            show={idWarn}
            content="5~20자의 영문(대/소문자) 또는 숫자를 사용해주세요."
          />
          <ItemWrap>
            <ItemTitle>비밀번호*</ItemTitle>
            <Input
              type="password"
              value={password}
              onChange={(e) =>
                action({ type: "password", payload: e.target.value })
              }
              tabIndex={3}
              placeholder={"비밀번호를 입력해주세요"}
              onBlur={() => {
                setPasswordWarn(!isPasswordValid(password));
                if (passwordConfirm) {
                  setPwConfirmWarn(!isPasswordEqual(password, passwordConfirm));
                }
              }}
              minLength={8}
              maxLength={20}
            />
          </ItemWrap>
          <Comment
            show={passwordWarn}
            content="8~20자의 영문(대/소문자), 숫자를 사용해주세요(특수문자 가능)."
          />
          <ItemWrap>
            <ItemTitle>비밀번호 확인*</ItemTitle>
            <Input
              type="password"
              value={passwordConfirm}
              onChange={(e) =>
                action({ type: "passwordConfirm", payload: e.target.value })
              }
              tabIndex={4}
              placeholder={"비밀번호를 확인해주세요"}
              onBlur={() => {
                setPwConfirmWarn(!isPasswordEqual(password, passwordConfirm));
              }}
              minLength={8}
              maxLength={20}
            />
          </ItemWrap>
          <Comment
            show={pwConfirmWarn}
            content="비밀번호가 일치하지 않습니다."
          />
        </LeftInput>
        <RightInput>
          <ItemWrap>
            <ItemTitle>이름*</ItemTitle>
            <Input
              type="text"
              value={name}
              onChange={(e) =>
                action({ type: "name", payload: e.target.value })
              }
              tabIndex={5}
              placeholder={"이름을 입력해주세요"}
              onBlur={() => {
                setNameWarn(!isNameValid(name));
              }}
              minLength={2}
              maxLength={20}
            />
          </ItemWrap>
          <Comment show={nameWarn} content="2~20자의 한글을 입력해주세요." />
          <ItemWrap>
            <ItemTitle>이메일*</ItemTitle>
            <Input
              type="email"
              value={email}
              onChange={(e) =>
                action({ type: "email", payload: e.target.value })
              }
              tabIndex={6}
              placeholder={"이메일을 입력해주세요"}
              onBlur={() => {
                setEmailWarn(!isEmailValid(email));
              }}
              minLength={2}
              maxLength={50}
            />
          </ItemWrap>
          <Comment show={emailWarn} content="이메일을 확인해주세요." />
          <ItemWrap>
            <ItemTitle>연락처*</ItemTitle>
            <Input
              type="tel"
              value={phoneNumber}
              placeholder={"연락처를 입력해주세요"}
              disabled
            />
          </ItemWrap>
          <AddressWrap>
            <ItemWrap>
              <ItemTitle>주소*</ItemTitle>
              <InputContainer>
                <Input
                  type="text"
                  value={address}
                  onChange={(e) =>
                    action({ type: "address", payload: e.target.value })
                  }
                  tabIndex={7}
                  placeholder={"주소를 입력해주세요"}
                  onBlur={() => {
                    setAddressWarn(address ? false : true);
                  }}
                />
                <CheckOrSearchButton onClick={onToggleModal} tabIndex={8}>
                  주소 찾기
                </CheckOrSearchButton>
              </InputContainer>
            </ItemWrap>
            <Comment show={addressWarn} content="주소를 확인해주세요." />
            {/* 상세 주소 ui 하드코딩. 기존 코드 그대로 가져와 쓰면서 값만 입력 안함 */}
            <ItemWrap>
              <ItemTitle></ItemTitle>
              <Input
                type="text"
                value={addressDetail}
                onChange={(e) =>
                  action({ type: "addressDetail", payload: e.target.value })
                }
                tabIndex={9}
                placeholder={"상세 주소를 입력해주세요"}
                onBlur={() => {
                  setAddressDetailWarn(addressDetail ? false : true);
                }}
              />
            </ItemWrap>
            <Comment
              show={addressDetailWarn}
              content="상세주소를 확인해주세요."
            />
          </AddressWrap>
        </RightInput>
      </InputWrap>
      <SignUpButton onClick={onRequestSignUp} tabIndex={10}>
        가입하기
      </SignUpButton>
      {isOpen && (
        <Modal
          open={true}
          footer={null}
          onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
          width={550}
          bodyStyle={{ padding: "15px" }}
        >
          <DaumPostcode onComplete={handleComplete} />
        </Modal>
      )}
    </Container>
  );
}
