import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  align-self: stretch;
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 62px;
  align-self: stretch;
`;

export const LeftInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RightInput = styled(LeftInput)``;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const AddressWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`;

export const ItemTitle = styled.span`
  width: 130px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
`;

export const Input = styled.input.attrs((props) => ({
  type: props.type,
}))`
  display: flex;
  width: 250px;
  height: 51px;
  padding: 0px 16px;
  align-items: center;
  flex: 1 0 0;
  font-size: 16px;
  color: ${(props) => (props.readOnly ? "#8b97a2" : "#000")};

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: ${(props) => (props.readOnly ? "#EEE" : "#FFF")};
  box-sizing: border-box;

  &::placeholder {
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const CheckOrSearchButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  width: 137px;
  height: 51px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? "var(--gray-100)" : "#009ee2")};
  background: #fff;

  color: ${(props) => (props.disabled ? "var(--gray-100)" : "#009ee2")};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const SignUpButton = styled.button`
  display: flex;
  width: 470px;
  height: 52px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid #009ee2;
  background: #009ee2;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;
