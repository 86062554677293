import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

interface returnType {
  code: number;
  isSuccess: boolean;
  message: string;
  result: {
    scratchStatus: { id: number; scratch: string }[];
  };
}

export const getPrdScratch = async (): Promise<returnType> => {
  const { data } = await axiosAuthClient.get(`/web/products/scratch-status`);
  return data;
};

export default function useGetPrdScratch() {
  return useQuery(["getPrdScratch"], getPrdScratch, {
    onError: (err) => alert(err),
    select: (res) => res.result.scratchStatus,
  });
}
