import React, { useEffect, useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import { Link } from "react-router-dom";

// import PopularItem01 from "../../assets/content/popularItem/img-content-popularItem-01.png";

import {
  Title,
  Wrap,
  CardItemModified,
  Content,
  FilteringWrap,
  ExceptCompletedProduct,
  ExceptCompletedProductText,
  ExceptCompletedProductChk,
  FilterBox,
  ProductList,
  CardItemWrap,
  TitleWrap,
  TopButton,
} from "./styles";

import {
  ItemDescription,
  ItemDetail,
  ItemDetailElement,
  ItemDetailElementText,
  ItemPriceText,
  ItemPriceWrap,
  ItemTitle,
  ItemType,
  ItemTypeDisabled,
  ItemTypeText,
  ItemTypeTextDisabled,
  NoItemComment,
  NoItemWrapper,
} from "../../components/CardItem/styles";

import { MyPaginate } from "../../components/Pagination";
import { CategorySelect } from "../../components/CategorySelect";
import useGetCategories from "../../hooks/common/useGetCategories";
import { changeCommaFormat } from "../../utils/utility";
import useGetProducts from "../../hooks/common/useGetProducts";
import { ProductNameState, SelectedCategoryIdState } from "../../recoil/home";
import { useRecoilState } from "recoil";

interface Product {
  condition: string;
  isBiz: string;
  price: number;
  brokerageFee: number;
  productId: number;
  productImageUrl: string;
  productName: string;
  purchaseStatus: string;
  usedMonth: number;
  usedYear: number;
  userId: number;
}

export default function ProductInquiry() {
  const [category, setCategory] = useState<string>("전체");
  const [filter, setFilter] = useState<string>("최신순");
  const [page, setPage] = useState<number>(1);
  const [excludePurchaseCompletedProduct, setExcludePurchaseCompletedProduct] =
    useState<string>("N");
  const [selectedCategoryId] = useRecoilState(SelectedCategoryIdState);
  const [productName] = useRecoilState(ProductNameState);

  useEffect(() => {
    setCategory(selectedCategoryId || "전체");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const { data: categories } = useGetCategories();
  const { data: productsInfo } = useGetProducts(
    category,
    filter,
    page,
    excludePurchaseCompletedProduct,
    productName
  );

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>상품 조회</Title>
          </TitleWrap>
          <CategorySelect
            marginTop="40px"
            onChange={(e) => setCategory(e.target.value)}
            defaultValue={selectedCategoryId}
          >
            <option value="전체">전체</option>
            {categories?.map(({ id, name }) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
          </CategorySelect>
          <FilteringWrap>
            <ExceptCompletedProduct>
              <ExceptCompletedProductText>
                거래 완료 상품 제외
              </ExceptCompletedProductText>
              <ExceptCompletedProductChk
                onChange={(e) =>
                  setExcludePurchaseCompletedProduct(
                    e.target.checked ? "Y" : "N"
                  )
                }
              />
            </ExceptCompletedProduct>
            <FilterBox onChange={(e) => setFilter(e.target.value)}>
              <option value="최신순">최신순</option>
              <option value="조회순">조회순</option>
              <option value="낮은 가격순">낮은 가격순</option>
              <option value="높은 가격순">높은 가격순</option>
            </FilterBox>
          </FilteringWrap>
          <ProductList>
            <TopButton
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M6 17L12 11L18 17" stroke="#ACB1BA" strokeWidth="2" />
                <path d="M17 6L7 6" stroke="#ACB1BA" strokeWidth="2" />
              </svg>
            </TopButton>
            {productsInfo?.products?.map(
              ({
                condition,
                isBiz,
                price,
                brokerageFee,
                productId,
                productImageUrl,
                productName,
                purchaseStatus,
                usedMonth,
                usedYear,
              }: // userId,
              Product) => (
                <ProductLink
                  to={`/product/${productId}`}
                  key={productId}
                  onClick={(e: any) => {
                    if (purchaseStatus !== "판매중") {
                      e.preventDefault();
                    }
                  }}
                >
                  <CardItemWrap>
                    <CardItemModified>
                      <img
                        src={productImageUrl}
                        loading="lazy"
                        alt={productName}
                        width={192}
                        height={144}
                        style={{
                          borderRadius: "8px",
                          opacity: purchaseStatus !== "판매중" ? "0.4" : "1.0",
                        }}
                      />
                      <ItemDescription gap="10px">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <ItemTitle
                            fontSize="20px"
                            style={{
                              color:
                                purchaseStatus !== "판매중"
                                  ? "#979797"
                                  : "#212529",
                            }}
                          >
                            {productName}
                          </ItemTitle>
                          {purchaseStatus !== "판매중" && (
                            <div
                              style={{
                                display: "flex",
                                minWidth: "65px",
                                height: "25px",
                                padding: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                borderRadius: "8px",
                                boxSizing: "border-box",
                                border: "1px solid #979797",
                                background: "#FFF",
                                color: "#979797",
                                fontSize: "12px",
                              }}
                            >
                              거래완료
                            </div>
                          )}
                        </div>
                        {purchaseStatus !== "판매중" ? (
                          <>
                            <ItemTypeDisabled>
                              <ItemTypeTextDisabled>
                                {isBiz === "Y" ? "사업자" : "개인"}
                              </ItemTypeTextDisabled>
                            </ItemTypeDisabled>
                          </>
                        ) : (
                          <>
                            <ItemType isBiz={isBiz}>
                              <ItemTypeText isBiz={isBiz}>
                                {isBiz === "Y" ? "사업자" : "개인"}
                              </ItemTypeText>
                            </ItemType>
                          </>
                        )}

                        <ItemDetail>
                          <ItemDetailElement gap="20px">
                            <ItemDetailElementText
                              disabled={purchaseStatus !== "판매중"}
                            >
                              사용기간
                            </ItemDetailElementText>
                            <ItemDetailElementText
                              disabled={purchaseStatus !== "판매중"}
                            >
                              {usedYear}년 {usedMonth}개월
                            </ItemDetailElementText>
                          </ItemDetailElement>
                          <ItemDetailElement gap="21px">
                            <ItemDetailElementText
                              disabled={purchaseStatus !== "판매중"}
                            >
                              컨디션
                            </ItemDetailElementText>
                            <ItemDetailElementText
                              disabled={purchaseStatus !== "판매중"}
                            >
                              {condition}
                            </ItemDetailElementText>
                          </ItemDetailElement>
                        </ItemDetail>
                        <ItemPriceWrap>
                          <ItemPriceText
                            disabled={purchaseStatus !== "판매중"}
                            fontSize="18px"
                          >
                            {changeCommaFormat(price + brokerageFee)}원
                          </ItemPriceText>
                        </ItemPriceWrap>
                      </ItemDescription>
                    </CardItemModified>
                  </CardItemWrap>
                </ProductLink>
              )
            )}
          </ProductList>
          {productsInfo?.totalCount === 0 && (
            <NoItemWrapper>
              <NoItemComment>조회할 수 있는 상품이 없습니다.</NoItemComment>
            </NoItemWrapper>
          )}
          {productsInfo?.totalCount !== 0 && (
            <MyPaginate
              marginTop="6.25rem"
              previousLabel={"〈"}
              nextLabel={"〉"}
              breakLabel={"..."}
              // react-query가 fetch해올때 totalCount가 undefiend인 경우가 있음
              // 그럴때 결과가 NaN으로 나오고 warning이 뜸. 그런 상황을 1로 예외처리함
              pageCount={Math.ceil((productsInfo?.totalCount ?? 1) / 50)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={5}
              onPageChange={({ selected }) => setPage(selected + 1)}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          )}
        </Content>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductLink = styled(Link)`
  text-decoration: none;
`;
