import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { IsBizType } from "../../types/memberInfo";
import { axiosAuthClient } from "../../apis/general";

interface Param {
  isBiz: boolean;
}

export default function useModifyMyPageMutation({ isBiz }: Param) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (param: IsBizType<typeof isBiz>) =>
      axiosAuthClient.patch("/web/users", param),
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        navigate("/mypage");
      } else {
        alert("수정에 실패했습니다(원인: 서버 오류).");
      }
    },
    onError: (err) => {
      alert(err);
    },
  });
}
