import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Wrap,
  Menu,
  Tab,
  Button,
  Title,
  Search,
  SearchInput,
  CartTab,
  ButtonWrap,
  SearchIconWrap,
} from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/header/ic-header-search.svg";
import { ReactComponent as Logo } from "../../assets/header/img-header-logo.svg";
import { ReactComponent as CartIcon } from "../../assets/header/ic-header-cart.svg";
import { LoginState } from "../../recoil/login";
import { useRecoilState } from "recoil";
import Consulting from "./consulting";
import { ProductNameState } from "../../recoil/home";
import Popup from "../Popup/common";
import { JWT_KEY } from "../../config/constant";

interface commonLinkPropsType {
  link: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  color: string;
  fontWeight: number;
  title: string;
}

export default function AppHeader() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const productInput = useRef("");
  const [, setProductName] = useRecoilState(ProductNameState);
  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(LoginState);

  useEffect(() => {
    localStorage.getItem(JWT_KEY) ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, [localStorage.getItem(JWT_KEY)]);

  const CommonLink = ({
    link,
    onClick,
    color,
    fontWeight,
    title,
  }: commonLinkPropsType) => (
    <Link to={link} onClick={onClick}>
      <Button>
        <Title color={color} fontWeight={fontWeight} title={title}>
          {title}
        </Title>
      </Button>
      {title === "장바구니" && (
        <CartIcon style={{ position: "relative", top: "3px" }} />
      )}
    </Link>
  );

  const tapList5: Array<{
    title: string;
    link: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    color: string;
    borderBottom: boolean;
    fontWeight: number;
  }> = [
    {
      title: "홈",
      link: "/",
      onClick: () => {},
      color: location.pathname === "/" ? "var(--Main)" : "#212529",
      borderBottom: location.pathname === "/" ? true : false,
      fontWeight: 500,
    },
    {
      title: "상품 조회",
      link: "/productinquiry",
      onClick: () => {},
      color:
        location.pathname === "/productinquiry" ? "var(--Main)" : "#212529",
      borderBottom: location.pathname === "/productinquiry" ? true : false,
      fontWeight: 500,
    },
    {
      title: "내 매매현황",
      link: "/tradingstatus",
      onClick: (e) => {
        if (!isLoggedIn) {
          onTogglePopUp();
          e.preventDefault();
        }
      },
      color: location.pathname === "/tradingstatus" ? "var(--Main)" : "#212529",
      borderBottom: location.pathname === "/tradingstatus" ? true : false,
      fontWeight: 500,
    },
    {
      title: "판매 등록",
      link: "/sell",
      onClick: (e) => {
        if (!isLoggedIn) {
          onTogglePopUp();
          e.preventDefault();
        }
      },
      color: location.pathname === "/sell" ? "var(--Main)" : "#212529",
      borderBottom: location.pathname === "/sell" ? true : false,
      fontWeight: 500,
    },
    {
      title: "정산 관리",
      link: "/settlement",
      onClick: (e) => {
        if (!isLoggedIn) {
          onTogglePopUp();
          e.preventDefault();
        }
      },
      color: location.pathname === "/settlement" ? "var(--Main)" : "#212529",
      borderBottom: location.pathname === "/settlement" ? true : false,
      fontWeight: 500,
    },
  ];

  return (
    <Wrap>
      <Logo
        width={92}
        height={22.5}
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />
      <Menu>
        {tapList5.map(
          (
            { title, link, onClick, color, borderBottom, fontWeight },
            index
          ) => (
            <Tab key={index} borderBottom={borderBottom}>
              <CommonLink
                onClick={onClick}
                link={link}
                color={color}
                fontWeight={fontWeight}
                title={title}
              />
            </Tab>
          )
        )}
        <Search>
          <SearchInput
            onChange={(e) => (productInput.current = e.target.value)}
            placeholder="원하는 상품을 검색해보세요"
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                setProductName(productInput.current);
                navigate("/productinquiry");
              }
            }}
          />
          <SearchIconWrap
            onClick={() => {
              setProductName(productInput.current);
              navigate("/productinquiry");
            }}
          >
            <SearchIcon />
          </SearchIconWrap>
        </Search>
        <CartTab>
          <CommonLink
            link="/cart"
            onClick={(e) => {
              if (!isLoggedIn) {
                onTogglePopUp();
                e.preventDefault();
              }
            }}
            color="#212529"
            fontWeight={500}
            title="장바구니"
          />
        </CartTab>
        {isLoggedIn ? (
          <Tab>
            <CommonLink
              onClick={() => {}}
              link="/mypage"
              color="#212529"
              fontWeight={700}
              title="마이페이지"
            />
          </Tab>
        ) : (
          <ButtonWrap
            onClick={() => navigate("/login")}
            border="#8B97A2"
            style={{ marginRight: "10px" }}
          >
            <Button>
              <Title color={"#ACB1BA"} fontWeight={700}>
                {"로그인"}
              </Title>
            </Button>
          </ButtonWrap>
        )}
        <ButtonWrap onClick={() => onToggleModal()} border="#009ee2">
          <Button>
            <Title color={"#009ee2"} fontWeight={700}>
              {"상담 신청"}
            </Title>
          </Button>
        </ButtonWrap>
      </Menu>
      {isOpen && <Consulting onToggleModal={onToggleModal} />}
      {isPopUpOpen && (
        <Popup
          type="login"
          info={"로그인이 필요한 페이지입니다./로그인 화면으로 이동합니다."}
          buttonCount={1}
          onCancel={onTogglePopUp}
          onOk={() => {
            onTogglePopUp();
            navigate("/login");
          }}
        />
      )}
    </Wrap>
  );
}
