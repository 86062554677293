import React, { useState } from "react";
import AppHeader from "../../components/Header";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  InfoWrap,
  Info,
} from "../../components/MyPage/styles";
import { useNavigate } from "react-router-dom";
import MyPageBizUser from "../../components/MyPage/bizUser";
import MyPageCommonUser from "../../components/MyPage/commonUser";

import { useRecoilState } from "recoil";
import {
  JwtState,
  LoginState,
  UserDBIdState,
  UserBizState,
} from "../../recoil/login";

import { JWT_KEY } from "../../config/constant";
import useCheckMyPageQuery from "../../hooks/mypage/useCheckMyPageQuery";
import Popup from "../../components/Popup/common";

export default function MyPage() {
  const navigate = useNavigate();

  const [, setIsLoggedIn] = useRecoilState(LoginState);
  const [, setJwt] = useRecoilState(JwtState);
  const [userDBId, setUserDBId] = useRecoilState(UserDBIdState);
  const [userBiz, setUserBiz] = useRecoilState(UserBizState);

  const [isOpen, setIsOpen] = useState(false);

  const { data } = useCheckMyPageQuery(userDBId);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>마이페이지</Title>
            <LogOut
              onClick={() => {
                onToggleModal();
              }}
            >
              로그아웃
            </LogOut>
          </TitleWrap>
          <InfoWrap>
            <Info>
              {userBiz === "Y"
                ? data && <MyPageBizUser {...data} />
                : data && <MyPageCommonUser {...data} />}
            </Info>
          </InfoWrap>
        </Content>
      </Wrap>
      {isOpen && (
        <Popup
          info={"로그아웃하시겠습니까?"}
          onCancel={onToggleModal}
          onOk={() => {
            setIsLoggedIn(false);
            setJwt("");
            setUserDBId(0);
            setUserBiz("");
            localStorage.removeItem(JWT_KEY);
            navigate("/login");
          }}
        />
      )}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogOut = styled.button`
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  cursor: pointer;

  background: inherit;
  border-radius: 0.31rem;
  border: 1px solid #dadada;

  color: #2f2e39;
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
