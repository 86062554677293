import React from "react";
import {
  InfoTitleWrap,
  InfoTitle,
  Address,
  InputWrap,
  Container,
  ItemWrap,
  ItemTitle,
  ItemInput,
  AddressTitle,
  InfoContent,
  AddressBasicInput,
  AddressDetailInput,
  InfoMain,
  InfoUnit,
  ButtonWrap,
  InfoButton,
  InfoButtonText,
} from "../styles";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface propsType {
  address: string;
  addressDetail: string;
  email: string;
  name: string;
  phoneNumber: string;
  userId: string;
}

const InputBorderStyle = {
  border: "none",
};

export default function MyPageCommonUser(props: propsType) {
  const { address, addressDetail, email, name, phoneNumber, userId } = props;
  return (
    <>
      <InfoMain>
        <InfoUnit>
          <InfoTitleWrap>
            <InfoTitle>계정 정보</InfoTitle>
          </InfoTitleWrap>
          <InfoContent>
            <ItemWrap>
              <ItemTitle>아이디</ItemTitle>
              <ItemInput
                type="text"
                value={userId}
                style={InputBorderStyle}
                readOnly
              />
            </ItemWrap>
          </InfoContent>
        </InfoUnit>
        <InfoUnit>
          <InfoTitleWrap>
            <InfoTitle>회원 정보</InfoTitle>
          </InfoTitleWrap>
          <InfoContent>
            <ItemWrap>
              <ItemTitle>이름</ItemTitle>
              <ItemInput
                type="text"
                value={name}
                style={InputBorderStyle}
                readOnly
              />
            </ItemWrap>
            <ItemWrap>
              <ItemTitle>연락처</ItemTitle>
              <ItemInput
                type="tel"
                value={phoneNumber}
                style={InputBorderStyle}
                readOnly
              />
            </ItemWrap>
            <ItemWrap>
              <ItemTitle>이메일</ItemTitle>
              <ItemInput
                type="email"
                value={email}
                style={InputBorderStyle}
                readOnly
              />
            </ItemWrap>
            <Address>
              <AddressTitle>주소</AddressTitle>
              <Container>
                <InputWrap>
                  <AddressBasicInput
                    type="text"
                    value={address}
                    style={InputBorderStyle}
                    readOnly
                  />
                </InputWrap>
                <InputWrap>
                  <AddressDetailInput
                    type="text"
                    value={addressDetail}
                    style={InputBorderStyle}
                    readOnly
                  />
                </InputWrap>
              </Container>
            </Address>
          </InfoContent>
        </InfoUnit>
      </InfoMain>
      <ButtonWrap>
        <ButtonLink to="/mypage/modify">
          <InfoButton border="Main" background="Main">
            <InfoButtonText color="#fff">수정</InfoButtonText>
          </InfoButton>
        </ButtonLink>
      </ButtonWrap>
    </>
  );
}

const ButtonLink = styled(Link)`
  text-decoration: none;
`;
