import React from "react";
import Carousel from "react-material-ui-carousel";
import ImgPreviewIcon from "../../assets/content/sell/ic-content-sell-imgPreview.png";

interface Props {
  imageFiles: any[];
}

const styles = {
  Wrap: {
    width: "inherit",
    height: "inherit",
  },
  NoImageBox: {
    width: "460px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "17px",
  },
};

export default function ImageSlider({ imageFiles }: Props) {
  return (
    <div style={styles.Wrap}>
      {(!imageFiles || imageFiles?.length === 0) && (
        <div style={styles.NoImageBox as React.CSSProperties}>
          <img
            width={48}
            height={48}
            src={ImgPreviewIcon}
            alt="이미지 미리보기 아이콘"
          />
        </div>
      )}
      {imageFiles && imageFiles.length > 0 && (
        <Carousel swipe={false} autoPlay={false} indicators={false}>
          {imageFiles?.map(({ id, productImageUrl }) => (
            <img
              src={productImageUrl}
              key={id}
              width={395}
              height={300}
              style={{ borderRadius: "8px" }}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}
