import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from "../../assets/header/img-header-logo.svg";
import styled from 'styled-components';

const MobileHeader = () => {
  const navigate = useNavigate();

  return (
    <HeaderSection>
      <Header className="mobile_header">
        <Logo
          width={104}
          height={26}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </Header>
    </HeaderSection>
  );
};

const HeaderSection = styled.div`
  display: flex
`;

const Header = styled.header`
  margin-left: auto;
  margin-top: 3rem;
  margin-right: 2rem;
`

export default MobileHeader;
