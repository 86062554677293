import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

interface Param {
  productId: number;
}

export default function useDeleteSellingWriting(
  closePopUp: () => void,
  page: number,
  productName?: string
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (param: Param) =>
      axiosAuthClient.patch("/web/products/status", param),
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        closePopUp();
        queryClient.invalidateQueries({
          queryKey: ["getsellingstatus", page, productName],
        });
      } else {
        alert(`삭제에 실패했습니다(code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });
}
