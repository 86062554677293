import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoilState",
  storage: localStorage,
});

export const PayProductIdState = atom<number>({
  key: "PayProductIdState",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const PayProductIdsState = atom<number[]>({
  key: "PayProductIdsState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const CartProductIdsState = atom<number[]>({
  key: "CartProductIdsState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const PayOrderIdState = atom<number>({
  key: "PayOrderIdState",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const PayOrderInfoState = atom<object>({
  key: "PayOrderInfoState",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const PayPathState = atom<string>({
  key: "PayPathState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
