import React from "react";
import { Modal } from "antd";
import {
  Bottom,
  Category,
  Close,
  Column,
  CompanyAddress,
  CompanyAddressVal,
  CompanyName,
  CompanyNameVal,
  CompanyRegNumber,
  CompanyRegNumberVal,
  Content,
  FirstLine,
  ForthLine,
  Main,
  Name,
  NameVal,
  SecondLine,
  Supplier,
  SupplierContent,
  SupplierTitle,
  ThirdLine,
  Title,
  TitleWrap,
  Top,
  TopContent,
  TopTitle,
  TotalValueWrap,
  TransactionInfo,
  ValueWrap,
  Wrap,
} from "./styles";
import { ReactComponent as CloseButton } from "../../../assets/header/ic-header-close.svg";
import { ReactComponent as Logo } from "../../../assets/header/img-header-logo.svg";
import { UserBizState, UserDBIdState } from "../../../recoil/login";
import { useRecoilState } from "recoil";
import useCheckMyPageQuery from "../../../hooks/mypage/useCheckMyPageQuery";
import { changeCommaFormat } from "../../../utils/utility";

interface PropsType {
  onToggleModal: () => void;
  productName: string;
  price: number;
  brokerageFee: number;
  expectedDeliveryFee: number;
  purchaseCompleteDate: string;
}

const cursorStyle = {
  cursor: "pointer",
};

export default function TransactionStatement({
  onToggleModal,
  productName,
  price,
  brokerageFee,
  expectedDeliveryFee,
  purchaseCompleteDate,
}: PropsType) {
  const [userBiz] = useRecoilState(UserBizState);
  const [userDBId] = useRecoilState(UserDBIdState);
  const { data } = useCheckMyPageQuery(userDBId);

  return (
    <>
      <Modal
        open={true}
        footer={null}
        width={1316}
        closeIcon={null}
        centered
        onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
      >
        <Wrap>
          <Content>
            <TitleWrap>
              <Close>
                <CloseButton onClick={onToggleModal} style={cursorStyle} />
              </Close>
              <Title>
                <Logo width={95} height={24} />
              </Title>
            </TitleWrap>
            <Main>
              <Top>
                <TopTitle>거 래 명 세 표</TopTitle>
                <TopContent>
                  <Supplier
                    style={{ width: userBiz === "Y" ? "auto" : "1196px" }}
                  >
                    <SupplierTitle>
                      <div>공</div>
                      <div>급</div>
                      <div>자</div>
                    </SupplierTitle>
                    <SupplierContent
                      style={{ width: userBiz === "Y" ? "534px" : "1132px" }}
                    >
                      <FirstLine>
                        <CompanyRegNumber>사업자 등록번호</CompanyRegNumber>
                        <CompanyRegNumberVal>143-81-35033</CompanyRegNumberVal>
                      </FirstLine>
                      <SecondLine>
                        <CompanyName>상호(법인명)</CompanyName>
                        <CompanyNameVal>기업의 품격(주)</CompanyNameVal>
                        <Name>성명</Name>
                        <NameVal>최지우</NameVal>
                      </SecondLine>
                      <ThirdLine>
                        <CompanyAddress>사업장 주소</CompanyAddress>
                        <CompanyAddressVal>
                          경기 용인시 기흥구 강남서로 9, 7층 703호 엠919호
                        </CompanyAddressVal>
                      </ThirdLine>
                      <ForthLine>
                        <CompanyName>업태</CompanyName>
                        <CompanyNameVal>소매, 서비스</CompanyNameVal>
                        <Name>종목</Name>
                        <NameVal>전자상거래업</NameVal>
                      </ForthLine>
                    </SupplierContent>
                  </Supplier>
                  {userBiz === "Y" && (
                    <Supplier>
                      <SupplierTitle>
                        <div>공</div>
                        <div>급</div>
                        <div>받</div>
                        <div>는</div>
                        <div>자</div>
                      </SupplierTitle>
                      <SupplierContent>
                        <FirstLine>
                          <CompanyRegNumber>사업자 등록번호</CompanyRegNumber>
                          <CompanyRegNumberVal>
                            {data?.companyRegistrationNumber}
                          </CompanyRegNumberVal>
                        </FirstLine>
                        <SecondLine>
                          <CompanyName>상호(법인명)</CompanyName>
                          <CompanyNameVal>{data?.businessName}</CompanyNameVal>
                          <Name>성명</Name>
                          <NameVal>{data?.representativeName}</NameVal>
                        </SecondLine>
                        <ThirdLine>
                          <CompanyAddress>사업장 주소</CompanyAddress>
                          <CompanyAddressVal>
                            {data?.address + " " + data?.addressDetail}
                          </CompanyAddressVal>
                        </ThirdLine>
                        <ForthLine>
                          <CompanyName>업태</CompanyName>
                          <CompanyNameVal>{data?.sector}</CompanyNameVal>
                          <Name>종목</Name>
                          <NameVal>{data?.involvedIn}</NameVal>
                        </ForthLine>
                      </SupplierContent>
                    </Supplier>
                  )}
                </TopContent>
              </Top>
              <Bottom>
                <TransactionInfo>
                  <Category>
                    <Column width="154px">거래일자</Column>
                    <Column width="500px">상풒명</Column>
                    <Column width="104px">수량</Column>
                    <Column width="168px">거래액(VAT 포함 원화)</Column>
                    <Column width="160px">비고</Column>
                  </Category>
                  <ValueWrap>
                    <Column
                      width="38px"
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {purchaseCompleteDate.split(" ")[0].split("-")[0]}
                    </Column>
                    <Column
                      width="36px"
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {purchaseCompleteDate.split(" ")[0].split("-")[1]}
                    </Column>
                    <Column
                      width="36px"
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {purchaseCompleteDate.split(" ")[0].split("-")[2]}
                    </Column>
                    <Column
                      width="500px"
                      style={{
                        fontWeight: "500",
                        justifyContent: "flex-start",
                      }}
                    >
                      {productName}
                    </Column>
                    <Column
                      width="104px"
                      style={{
                        fontWeight: "500",
                        justifyContent: "flex-end",
                      }}
                    >
                      1
                    </Column>
                    <Column
                      width="168px"
                      style={{
                        fontWeight: "500",
                        justifyContent: "flex-end",
                      }}
                    >
                      {changeCommaFormat(price + brokerageFee)}
                    </Column>
                    <Column width="160px"></Column>
                  </ValueWrap>
                  <ValueWrap>
                    <Column width="38px"></Column>
                    <Column width="36px"></Column>
                    <Column width="36px"></Column>
                    <Column width="500px"></Column>
                    <Column width="104px"></Column>
                    <Column width="168px"></Column>
                    <Column width="160px"></Column>
                  </ValueWrap>
                  <ValueWrap>
                    <Column width="676px">총 거래액 합계</Column>
                    <Column width="104px"></Column>
                    <Column
                      width="168px"
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      {changeCommaFormat(price + brokerageFee)}
                    </Column>
                    <Column width="160px"></Column>
                  </ValueWrap>
                  <ValueWrap>
                    <Column width="676px">배송비 합계</Column>
                    <Column width="104px"></Column>
                    <Column
                      width="168px"
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      {changeCommaFormat(expectedDeliveryFee)}
                    </Column>
                    <Column width="160px"></Column>
                  </ValueWrap>
                  <TotalValueWrap>
                    <Column width="676px">실제 총 결제 금액</Column>
                    <Column width="104px"></Column>
                    <Column
                      width="168px"
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      {changeCommaFormat(
                        price + brokerageFee + expectedDeliveryFee
                      )}
                    </Column>
                    <Column width="160px"></Column>
                  </TotalValueWrap>
                </TransactionInfo>
              </Bottom>
            </Main>
          </Content>
        </Wrap>
      </Modal>
    </>
  );
}
