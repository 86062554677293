import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

const getTrendProducts = async (type: string) => {
  const { data } = await axiosClient.get(`/web/products/trends?type=${type}`);
  return data;
};

export default function useGetTrendProducts(type: string) {
  return useQuery(["getTrendProducts", type], () => getTrendProducts(type), {
    onError: (err) => alert(err),
    select: ({ result: { trendProducts } }) => trendProducts,
  });
}
