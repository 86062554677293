import React, { useState } from "react";
import {
  Button,
  Container,
  InputTitle,
  InputWrap,
  Input,
} from "../CommonStyles";
import { changeNumberFormat } from "../../../utils/utility";
import styled from "styled-components";

interface propsType {
  verifyCode: number | undefined;
  onNext: () => void;
}

export default function EmailConfirm({ verifyCode, onNext }: propsType) {
  const [inputVerifyCode, setInputVerifyCode] = useState<number>();
  const [commentShow, setCommentShow] = useState(false);

  const onRequestAuth = () => {
    if (verifyCode === Number(inputVerifyCode)) {
      onNext();
    } else {
      setCommentShow(true);
    }
  };

  return (
    <Container>
      <InputWrap>
        <InputTitle>메일로 보내드린 인증번호를 입력해주세요</InputTitle>
        <Input
          value={inputVerifyCode}
          onChange={({ target: { value } }) =>
            setInputVerifyCode(changeNumberFormat(value))
          }
          placeholder="6자리 숫자"
          maxLength={6}
          tabIndex={1}
        />
        {commentShow && <Comment>인증번호가 일치하지 않습니다.</Comment>}
      </InputWrap>
      <Button
        onClick={onRequestAuth}
        tabIndex={2}
        disabled={inputVerifyCode?.toString().length !== 6}
      >
        확인
      </Button>
    </Container>
  );
}

const Comment = styled.span`
  font-size: 0.87rem;
  color: red;
`;
