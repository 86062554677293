import { atom } from "recoil";

export const SelectedCategoryIdState = atom<string>({
  key: "SelectedCategoryIdState",
  default: "",
});

export const ProductNameState = atom<string>({
  key: "ProductNameState",
  default: "",
});
