import React from 'react'
import MobileHeader from '../MobileHeader';

interface PublicProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * 모바일 기본 탬플릿
 */
const MobilePublicTemplate = ({ children, ...rest }: PublicProps) => {
  return (
    <main {...rest} className={`${rest.className} public-template`}>
      <MobileHeader />
      {children}
    </main>
  );
};

export default MobilePublicTemplate;
