import React, { useState } from "react";
import AppHeader from "../../../components/Header";
import styled from "styled-components";
import { Wrap, Container } from "./styles";
import { ReactComponent as Logo } from "../../../assets/login/img-login-logo.svg";
import IDCheck from "../../../components/Auth/IDCheck";
import PWReset from "../../../components/Auth/PWReset";
import PhoneInput from "../../../components/Auth/common/phoneInput";
import PhoneConfirm from "../../../components/Auth/common/phoneConfirm";
import { useRecoilState } from "recoil";
import { UserIdState } from "../../../recoil/auth";
import EmailInput from "../../../components/Auth/biz/emailInput";
import EmailConfirm from "../../../components/Auth/biz/emailConfirm";

export default function FindPW() {
  const [step, setStep] = useState<
    "ID확인" | "휴대폰인증" | "이메일인증" | "인증확인" | "비밀번호 재설정"
  >("ID확인");
  const [userId, setUserId] = useRecoilState(UserIdState);
  const [verifyCode, setVerifyCode] = useState<number>();
  const [isBiz, setIsBiz] = useState(false);

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container>
          <Logo width={151} height={38} />
          <Show condition={step === "ID확인"}>
            <IDCheck
              onNext={(isBiz: string) => {
                setIsBiz(isBiz === "Y" ? true : false);
                setStep(isBiz === "Y" ? "이메일인증" : "휴대폰인증");
              }}
              userId={userId}
              setUserId={(val: string) => setUserId(val)}
            />
          </Show>
          {isBiz ? (
            <>
              <Show condition={step === "이메일인증"}>
                <EmailInput
                  onNext={(verifyCode: number) => {
                    setVerifyCode(verifyCode);
                    setStep("인증확인");
                  }}
                  userId={userId}
                />
              </Show>
              <Show condition={step === "인증확인"}>
                <EmailConfirm
                  verifyCode={verifyCode}
                  onNext={() => setStep("비밀번호 재설정")}
                />
              </Show>
            </>
          ) : (
            <>
              <Show condition={step === "휴대폰인증"}>
                <PhoneInput
                  onNext={(verifyCode: number) => {
                    setVerifyCode(verifyCode);
                    setStep("인증확인");
                  }}
                  userId={userId}
                  purpose="find-pw"
                />
              </Show>
              <Show condition={step === "인증확인"}>
                <PhoneConfirm
                  verifyCode={verifyCode}
                  onNext={() => setStep("비밀번호 재설정")}
                  purpose="reset-pw"
                />
              </Show>
            </>
          )}
          <Show condition={step === "비밀번호 재설정"}>
            <PWReset userId={userId} />
          </Show>
        </Container>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

interface showPropsType {
  condition: boolean;
  children: any;
}

function Show({ condition, children }: showPropsType) {
  return condition === true ? children : null;
}
