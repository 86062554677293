import styled from "styled-components";

export const Container = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.gap || "40px"};
  align-self: stretch;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const InputTitle = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
`;

export const ConfirmGuideText = styled.span`
  width: max-content;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
`;

export const Button = styled.button<{ disabled?: boolean }>`
  display: flex;
  height: 58px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid ${(props) => (!props.disabled ? "#009EE2" : "#eee")};
  background: ${(props) => (!props.disabled ? "#009EE2" : "#eee")};

  color: ${(props) => (!props.disabled ? "#FFFFFF" : "#acb1ba")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
`;

export const Input = styled.input.attrs({
  type: "text",
})`
  display: flex;
  height: 51px;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  font-size: 18px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;

  &::placeholder {
    color: #8b97a2;
    font-size: 18px;
  }
`;
