import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

const getProducts = async (
  category: string,
  filter: string,
  page: number,
  excludePurchaseCompletedProduct: string,
  productName?: string
) => {
  const { data } = await axiosClient.get(
    `/web/products?category=${category}&filter=${filter}&page=${page}&excludePurchaseCompletedProduct=${excludePurchaseCompletedProduct}&productName=${productName}`
  );
  return data;
};

export default function useGetProducts(
  category: string,
  filter: string,
  page: number,
  excludePurchaseCompletedProduct: string,
  productName?: string
) {
  return useQuery(
    [
      "getProducts",
      category,
      filter,
      page,
      excludePurchaseCompletedProduct,
      productName,
    ],
    () =>
      getProducts(
        category,
        filter,
        page,
        excludePurchaseCompletedProduct,
        productName
      ),
    {
      onError: (err) => alert(err),
      select: ({ result: { products, totalCount } }) => ({
        products,
        totalCount,
      }),
    }
  );
}
