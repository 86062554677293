import styled from "styled-components";

export const Wrap = styled.div`
  margin-top: 126px;

  display: flex;
  padding: 50px 240px 60px 240px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  background: #f7f8f9;
`;

export const Content = styled.div`
  display: flex;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const CompanyNameText = styled.span`
  color: #212529;
  font-family: Pretendard;
  font-size: 18.586px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
`;

export const LineWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CompanyInfoText = styled.span`
  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

export const BottomWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`;

export const LinkText = styled.span`
  color: #545e68;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const SNSLinkWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
