import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { PayOrderIdState, PayOrderInfoState } from "../../../recoil/pay";
import { UserDBIdState } from "../../../recoil/login";
import { axiosAuthClient } from "../../../apis/general";
import { useNavigate } from "react-router-dom";

export default function PaymentsTossSuccess() {
  const [orderId] = useRecoilState(PayOrderIdState); // 서버에서 주는 고유값
  const [orderInfo] = useRecoilState(PayOrderInfoState);
  const [userId] = useRecoilState(UserDBIdState);
  const searchParams = new URLSearchParams(document.location.search);
  const tossOrderId = searchParams.get("orderId");
  const paymentKey = searchParams.get("paymentKey");
  const amount = searchParams.get("amount");
  const param = { userId, paymentKey, orderId, tossOrderId, amount };
  const navigate = useNavigate();

  useEffect(() => {
    const SaveAndApprovePayments = async () => {
      try {
        const { data } = await axiosAuthClient.post("/web/payments", param);
        if (data.code === 1000) {
          const result = await axiosAuthClient.post("/web/payments/toss", {
            ...param,
            ...orderInfo,
          });
          if (result.data.code === 1000) {
            navigate("/payments/success", {
              state: result.data.result?.virtualAccount,
            });
          } else {
            navigate("/payments/fail");
          }
        }
      } catch (error) {
        alert(error);
      }
    };
    SaveAndApprovePayments();
  }, []);
  return <div>결제 진행중입니다.</div>;
}
