import React from "react";
import styled from "styled-components";

export default function CommonPayExplanaion() {
  return (
    <EasyPayExplanationWrap>
      <EasyPayExplanation>
        &nbsp;&nbsp;&nbsp; 가상 계좌 유효 기간{" "}
        <span style={{ color: "red" }}>결제 시점으로부터 48시간 이내</span>
      </EasyPayExplanation>
      <EasyPayExplanation>
        • 가상계좌는 주문 시 고객님께 발급되는 일회성 계좌번호 이므로 입금자명이
        다르더라도 입금 확인이 가능합니다.
      </EasyPayExplanation>
      <EasyPayExplanation>
        • 단, 선택하신 은행을 통해 결제 금액을 1원 단위까지 정확히 맞추셔야
        합니다.
      </EasyPayExplanation>
      <EasyPayExplanation>
        • 가상 계좌의 입금 유효 기간은 주문 후 2일 이내이며, 기간 초과 시
        계좌번호는 소멸되어 입금되지 않습니다.
      </EasyPayExplanation>
      <EasyPayExplanation>
        • 구매 가능 수량이 1개로 제한된 상품은 주문 취소 시, 24시간 내 가상
        계좌를 통한 재주문이 불가 합니다.
      </EasyPayExplanation>
      <EasyPayExplanation>
        • 인터넷뱅킹, 텔레뱅킹, ATM/CD기계, 은행 창구 등에서 입금할 수 있습니다.
      </EasyPayExplanation>
      <EasyPayExplanation>
        • ATM 기기는 100원 단위 입금이 되지 않으므로 통장 및 카드로 계좌이체
        해주셔야 합니다. 은행 창구에서도 1원 단위 입금이 가능합니다.
      </EasyPayExplanation>
    </EasyPayExplanationWrap>
  );
}

const EasyPayExplanationWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 822px;
`;

const EasyPayExplanation = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;
