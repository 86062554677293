import styled from "styled-components";
import ArrowIcon from "../../assets/content/sell/ic-content-sell-arrow.png";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Form = styled.form`
  display: flex;
  width: 1086px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Title = styled.div`
  display: flex;
  height: 70px;
  align-items: center;
  gap: 417px;
  align-self: stretch;
`;

export const TitleText = styled.span`
  display: flex;
  width: 381px;
  height: 36px;
  flex-direction: column;
  justify-content: center;

  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const TitleInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const TitleInfoText = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 137.5% */
`;

export const SubjectWrap = styled.div`
  display: flex;
  height: 72px;
  min-width: 170px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const SubjectText = styled.span`
  width: 416px;
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const SubjectInput = styled.input`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
  font-size: 16px;

  &::placeholder {
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const MainInputWrap = styled.div`
  display: flex;
  width: 1086px;
  align-items: flex-start;
  gap: 60px;
`;

export const ImgInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
`;

export const ImgPreviewWrap = styled.div`
  display: flex;
  width: 460px;
  height: 300px;
  padding: 3px 0px 3.248px 0px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
`;

export const ImgPreviewText = styled.span`
  color: #8e8e8e;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
`;

export const ImgInputMenu = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  z-index: 3;
`;

export const ImgInputStatusWrap = styled.div`
  width: 108px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgInputStatus = styled.span`
  color: #9da5b1;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
`;

export const ImgInputButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

export const ImageInputSearchFileLabel = styled.label<{
  disabled: boolean;
}>`
  display: flex;
  width: 149px;
  height: 42px;
  min-height: 35.949px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  border-radius: 8.479px;
  border: ${(props) => (props.disabled ? "none" : "1.06px solid var(--Main)")};
  background: ${(props) => props.disabled && "#eee"};

  color: ${(props) => (props.disabled ? "var(--button-gray)" : "var(--Main)")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.318px; /* 194.318% */
`;

export const ImgInputSearchFile = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

export const ImgInputDeleteFile = styled(Button)<{ disabled: boolean }>`
  display: flex;
  width: 90px;
  height: 42px;
  min-height: 35.949px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  border: ${(props) => (props.disabled ? "none" : "1.06px solid var(--Main)")};
  border-radius: 8.479px;
  background: ${(props) => props.disabled && "#eee"};

  color: ${(props) => (props.disabled ? "var(--button-gray)" : "var(--Main)")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.318px; /* 194.318% */
`;

export const RegisterButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 50px;

  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.disabled ? "var(--gray-100)" : "#009ee2")};
  background: ${(props) => (props.disabled ? "var(--gray-100)" : "#009ee2")};
`;

export const DetailInputWrap = styled.div`
  display: flex;
  width: 566px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
`;

export const CommonTitle = styled.span`
  flex: 1 0 0;
  align-self: stretch;

  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// export const CommonSelectWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex: 1 0 0;
//   align-self: stretch;
// `;

export const CommonSelect = styled.select`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;

  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const CommonInput = styled.input`
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;

  &::placeholder {
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const CommonUnitText = styled.span`
  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const CategoryAndProdName = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const Category = styled.div`
  display: flex;
  width: 249px;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const ProdName = styled.div`
  display: flex;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const ProdNameInput = styled(CommonInput).attrs({
  type: "text",
})``;

export const Size = styled.div`
  display: flex;
  width: 566px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

export const SizeTitleWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const SizeTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const SizeTitleInfo = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
`;

export const SizeWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const SizeUnit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

export const SizeUnitInput = styled(CommonInput).attrs({
  type: "number",
})`
  width: 90px;
  box-sizing: border-box;
  text-align: right;
  &::placeholder {
    font-size: 14px;
  }
`;

export const DeliveryFeeInfo = styled.p`
  margin: 0;
  color: red;
  font-size: 13.5px;
`;

export const UsePeriod = styled.div`
  display: flex;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const UsePeriodInputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const UsePeriodYearInput = styled(CommonInput).attrs({
  type: "number",
})`
  width: 100px;
  box-sizing: border-box;
  text-align: right;
  &::placeholder {
    font-size: 14px;
  }
`;

export const UsePeriodInputMonth = styled(CommonSelect)`
  width: 70px;
`;

export const Condition = styled.div`
  display: flex;
  width: 336px;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

export const ConditionSelect = styled(CommonSelect)``;

export const SellPrice = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  border-radius: 8px;
  background: #fafafa;
`;

export const SellPriceTitleWrap = styled.div`
  display: flex;
  height: 22px;
  padding: 0px 20px;
  align-items: flex-start;
  gap: 10px;
`;

export const SellPriceTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const DeliveryFeeSettingInfo = styled.span`
  color: #212121;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const SellPriceInputContainer = styled.div`
  display: flex;
  width: 1040px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const SellPriceInputWrap = styled.div`
  display: flex;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const SignContainer = styled.div`
  display: flex;
  height: 63px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 24.244px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.336px; /* 137.5% */
`;

export const CommissionInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

export const DeliveryFeeInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

export const FinalFeeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const SellPriceInputDesc = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const SellPriceInputMain = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const SellPriceInput = styled.input`
  display: flex;
  width: 191px;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
  box-sizing: border-box;

  &::placeholder {
    color: var(--gray-300);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const CommissionInputDesc = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const CommissionExplanation = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
`;

export const CommissionInputMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const CommissionRate = styled(SellPriceInput)`
  width: 70px;
  justify-content: center;
  align-items: center;
  flex: none;
  text-align: center;
`;

export const CommissionInput = styled(SellPriceInput)`
  width: 140px;
`;

export const DeliveryFeeInputDesc = styled(SellPriceInputDesc)``;

export const DeliveryFeeInputMain = styled(SellPriceInputMain)``;

export const DeliveryFeeInput = styled(SellPriceInput)`
  width: 132px;
`;

export const FinalFeeDesc = styled(SellPriceInputDesc)``;

export const FinalFeeMain = styled(SellPriceInputMain)``;

export const FinalFee = styled(SellPriceInput)`
  width: 191px;
`;

export const ProductState = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  border-radius: 8px;
  background: #fafafa;
`;

export const ProductStateTitle = styled.span`
  height: 32px;
  flex-shrink: 0;
  align-self: stretch;

  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
`;

export const NormalWork = styled.div`
  display: flex;
  width: 506px;
  height: 58px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const ProductStateSmallTitle = styled(ProductStateTitle)`
  height: 23px;
  flex: 1 0 0;

  font-size: 16px;
`;

export const NormalWorkSelectWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const OptionText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 2px;
`;

export const RadioCheckBox = styled.input`
  appearance: none;
  border: 0.2em solid gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:checked {
    border: 0.3em solid var(--Main);
  }
  cursor: pointer;
`;

export const ScratchState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const ScratchStateSelect = styled(CommonSelect)`
  width: 452px;
`;

export const DetailContentInput = styled(CommonInput).attrs({
  type: "text",
})`
  width: 452px;
  box-sizing: border-box;
`;

export const DamageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
`;

export const DamageAreaSelectWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
`;

export const DamageAreaInput = styled(CommonInput).attrs({
  type: "text",
})`
  width: 390px;
  box-sizing: border-box;
`;

export const PickUpPlace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
`;

export const PickUpPlaceTitleWrap = styled(SizeTitleWrap)``;

export const PickUpPlaceTitle = styled(SizeTitle)``;

export const PickUpPlaceTitleInfo = styled(SizeTitleInfo)``;

export const PickUpPlaceInputWrap = styled(PickUpPlace)``;

export const LoadFromUser = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const LoadFromUserCheck = styled.input.attrs({
  type: "checkbox",
})`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 3px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 2px solid #009ee2;
  background: #fff;
`;

export const LoadFromUserText = styled.span`
  color: #33363f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

export const AddressWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 7px;
`;

export const AddressBasic = styled.div`
  display: flex;
  gap: 15px;
`;

export const AddressBasicInput = styled(CommonInput).attrs({
  type: "text",
})`
  width: 376px;
  flex: none;
  box-sizing: border-box;
`;

export const SearchAddressButton = styled(Button)`
  display: flex;
  height: 40px;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid #009ee2;

  color: #009ee2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const AddressDetailInput = styled(CommonInput).attrs({
  type: "text",
})`
  flex: none;
  box-sizing: border-box;
`;

export const ProductShipmentSchedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;
// PSS -> ProductShipmentSchedule
export const PSSSelectWrap = styled(DamageAreaSelectWrap)``;

export const PSSOption = styled(Option)`
  width: 252px;
`;

export const PSSInput = styled.input`
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
`;

export const ProductDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const ProductDescInput = styled.textarea`
  display: flex;
  height: 90px;
  padding: 13px 10px 3px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  font-size: 16px;

  border: 1px solid #d9d9d9;

  &::placeholder {
    color: #acb1ba;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.35px;
  }
`;

export const RegisterButtonText = styled.span`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;
