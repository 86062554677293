import React, { useState } from "react";
import {
  InputWrap,
  LeftInput,
  RightInput,
  ItemWrap,
  ItemTitle,
  InputContainer,
  CheckOrSearchButton,
  Input,
  AddressWrap,
  SignUpButton,
  Form,
} from "./styles";
// import {
//   isEmailValid,
//   isIDValid,
//   isPasswordEqual,
//   isPasswordValid,
// } from "../../../../utils/utility";
// import { isIDValid } from "../../../../utils/utility";
import Comment from "../../SignUpComment";
import useCheckDuplicateID from "../../../../hooks/signup/useCheckDuplicateID";
import useSignUp from "../../../../hooks/signup/useSignUp";
import { useForm, Controller } from "react-hook-form";
import useCurrentDate from "../../../../hooks/signup/useCurrentDate";
import { BizMember } from "../../../../types/memberInfo";
import { Modal } from "antd";
import DaumPostcodeEmbed from "react-daum-postcode";
import {
  changeAllContactForm,
  changeComRegNumForm,
} from "../../../../utils/utility";
import useGetSectorId from "../../../../hooks/signup/useGetSectorId";
import { ConfigProvider } from "antd";
import { SelectSectorId, filterOption } from "../../SelectSectorId";
import useCheckComRegNum from "../../../../hooks/auth/useCheckComRegNum";
import { EmailState } from "../../../../recoil/auth";
import { useRecoilState } from "recoil";

interface SectorId {
  id: number;
  name: string;
}

export default function SignupForm() {
  const [email] = useRecoilState(EmailState);
  const {
    register,
    formState: { errors },
    formState,
    handleSubmit,
    setValue,
    getFieldState,
    getValues,
    watch,
    trigger,
    control,
  } = useForm({
    mode: "onBlur",
  });

  const currentDate = useCurrentDate();
  const [idDuplicateCheck, setIdDuplicateCheck] = useState(false);
  const { refetch: checkDuplicateID } = useCheckDuplicateID(
    watch("userId"),
    setIdDuplicateCheck
  );
  const [isOpen, setIsOpen] = useState(false);

  const { data: sectorIdList } = useGetSectorId();
  const [comRegNumCheck, setComRegNumCheck] = useState(false);
  const { refetch: checkComRegNum } = useCheckComRegNum(
    watch("companyRegistrationNumber"),
    setComRegNumCheck
  );

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data.address.split(" ").splice(2).join(" ");
    setValue("address", sidoSigungu + splitAddress);
    trigger("address");
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const requestSignUp = useSignUp({ isBiz: true });

  const onSubmit = (data: BizMember): void => {
    if (idDuplicateCheck === false) {
      alert("아이디 중복확인을 해주세요");
      return;
    }
    if (comRegNumCheck === false) {
      alert("사업자 등록번호를 인증해주세요");
      return;
    }
    delete data.passwordConfirm;
    requestSignUp.mutate(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputWrap>
        <input {...register("isBiz")} value="Y" hidden />
        <LeftInput>
          <ItemWrap>
            <ItemTitle>아이디*</ItemTitle>
            <InputContainer>
              <Input
                type="text"
                {...register("userId", {
                  required: "아이디를 확인해주세요.",
                  pattern: {
                    value: /^[a-zA-Z0-9]{5,20}$/,
                    message:
                      "5~20자의 영문(대/소문자) 또는 숫자를 사용해주세요.",
                  },
                })}
                tabIndex={1}
                placeholder={"아이디를 입력해주세요"}
                minLength={5}
                maxLength={20}
              />
              <CheckOrSearchButton
                type="button"
                onClick={() => {
                  getFieldState("userId").error === undefined &&
                  getValues("userId")
                    ? checkDuplicateID()
                    : alert("아이디를 확인해주세요.");
                }}
                tabIndex={2}
              >
                ID 중복 확인
              </CheckOrSearchButton>
            </InputContainer>
          </ItemWrap>
          <Comment
            show={Boolean(errors.userId)}
            content={String(errors.userId?.message)}
          />
          <ItemWrap>
            <ItemTitle>비밀번호*</ItemTitle>
            <Input
              type="password"
              {...register("password", {
                required: "비밀번호를 확인해주세요.",
                pattern: {
                  // value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/,
                  value: /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,20}$/,
                  message:
                    "8~20자의 영문(대/소문자), 숫자를 사용해주세요(특수문자 가능).",
                },
                onChange: () =>
                  Boolean(formState.dirtyFields.passwordConfirm) &&
                  trigger("passwordConfirm"),
              })}
              tabIndex={3}
              placeholder={"비밀번호를 입력해주세요"}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.password)}
            content={String(errors.password?.message)}
          />
          <ItemWrap>
            <ItemTitle>비밀번호 확인*</ItemTitle>
            <Input
              type="password"
              {...register("passwordConfirm", {
                required: "비밀번호를 확인해주세요.",
                validate: (value, formValues) => {
                  return (
                    value === formValues.password ||
                    "비밀번호가 일치하지 않습니다."
                  );
                },
              })}
              tabIndex={4}
              placeholder={"비밀번호를 입력해주세요"}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.passwordConfirm)}
            content={String(errors.passwordConfirm?.message)}
          />
          <ItemWrap>
            <ItemTitle>담당자명*</ItemTitle>
            <Input
              type="text"
              {...register("representativeName", {
                required: "담당자명을 확인해주세요.",
                pattern: {
                  value: /^[가-힣]{2,20}$/,
                  message: "2~20자의 한글을 입력해주세요.",
                },
              })}
              tabIndex={5}
              placeholder={"담당자명을 입력해주세요"}
              minLength={2}
              maxLength={20}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.representativeName)}
            content={String(errors.representativeName?.message)}
          />
          <ItemWrap>
            <ItemTitle>법인 이메일*</ItemTitle>
            <Input
              type="email"
              {...register("companyEmail")}
              value={email}
              readOnly
            />
          </ItemWrap>
        </LeftInput>
        <RightInput>
          <ItemWrap>
            <ItemTitle>사업자명*</ItemTitle>
            <Input
              type="text"
              {...register("businessName", {
                required: "사업자명을 확인해주세요.",
              })}
              tabIndex={6}
              placeholder={"사업자명을 입력해주세요"}
              maxLength={50}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.businessName)}
            content={String(errors.businessName?.message)}
          />
          <ItemWrap>
            <ItemTitle>개업일자*</ItemTitle>
            <Input
              type="date"
              {...register("openingDate", {
                required: "날짜를 확인해주세요.",
              })}
              max={currentDate}
              tabIndex={7}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.openingDate)}
            content={String(errors.openingDate?.message)}
          />
          <ItemWrap>
            <ItemTitle>사업자 등록번호*</ItemTitle>
            <InputContainer>
              <Input
                type="text"
                {...register("companyRegistrationNumber", {
                  required: "사업자등록번호를 확인해주세요.",
                  onChange: (e) => {
                    setValue(
                      "companyRegistrationNumber",
                      changeComRegNumForm(e.target.value)
                    );
                  },
                })}
                readOnly={comRegNumCheck}
                tabIndex={8}
                maxLength={10}
                placeholder={"‘-’없이 입력해주세요"}
              />
              <CheckOrSearchButton
                type="button"
                disabled={
                  watch("companyRegistrationNumber") === undefined
                    ? true
                    : watch("companyRegistrationNumber").length < 10
                }
                onClick={() => checkComRegNum()}
                tabIndex={9}
              >
                인증
              </CheckOrSearchButton>
            </InputContainer>
          </ItemWrap>
          <Comment
            show={Boolean(errors.companyRegistrationNumber)}
            content={String(errors.companyRegistrationNumber?.message)}
          />
          <ItemWrap>
            <ItemTitle>업태*</ItemTitle>
            <InputContainer>
              <Controller
                name="sectorId"
                control={control}
                rules={{
                  required: "업태를 확인해주세요.",
                }}
                render={({ field }) => (
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          optionFontSize: 16,
                        },
                      },
                    }}
                  >
                    <SelectSectorId
                      {...field}
                      showSearch
                      placeholder="업태를 선택해주세요"
                      optionFilterProp="children"
                      onChange={(value) => field.onChange(value)}
                      onSearch={() => {}}
                      filterOption={filterOption}
                      options={sectorIdList?.map(({ id, name }: SectorId) => ({
                        label: name,
                        value: id,
                      }))}
                      style={{ width: "100%", height: "51px" }}
                      tabIndex={10}
                    />
                  </ConfigProvider>
                )}
              />
            </InputContainer>
          </ItemWrap>
          <Comment
            show={Boolean(errors.sectorId)}
            content={String(errors.sectorId?.message)}
          />
          <ItemWrap>
            <ItemTitle>종목*</ItemTitle>
            <Input
              type="text"
              {...register("involvedIn", {
                required: "종목을 확인해주세요.",
              })}
              tabIndex={11}
              placeholder={"종목을 입력해주세요"}
              maxLength={20}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.involvedIn)}
            content={String(errors.involvedIn?.message)}
          />
          <ItemWrap>
            <ItemTitle>연락처*</ItemTitle>
            <Input
              type="tel"
              {...register("phoneNumber", {
                required: "연락처를 확인해주세요.",
                onChange: (e) =>
                  // 파라미터는 phoneNumber이지만 회사 전화번호를 쓸 수도 있다고 생각하여
                  // 모든 전화번호 변환 함수를 사용함
                  setValue("phoneNumber", changeAllContactForm(e.target.value)),
              })}
              tabIndex={12}
              placeholder={"연락처를 입력해주세요"}
              maxLength={13}
            />
          </ItemWrap>
          <Comment
            show={Boolean(errors.phoneNumber)}
            content={String(errors.phoneNumber?.message)}
          />
          <AddressWrap>
            <ItemWrap>
              <ItemTitle>주소*</ItemTitle>
              <InputContainer>
                <Input
                  type="text"
                  {...register("address", {
                    required: "주소를 확인해주세요.",
                  })}
                  tabIndex={13}
                  placeholder={"주소를 입력해주세요"}
                />
                <CheckOrSearchButton
                  type="button"
                  onClick={onToggleModal}
                  tabIndex={14}
                >
                  주소 찾기
                </CheckOrSearchButton>
              </InputContainer>
            </ItemWrap>
            <Comment
              show={Boolean(errors.address)}
              content={String(errors.address?.message)}
            />
            <ItemWrap>
              <ItemTitle></ItemTitle>
              <Input
                type="text"
                {...register("addressDetail", {
                  required: "상세 주소를 확인해주세요.",
                })}
                tabIndex={15}
                placeholder={"상세 주소를 입력해주세요"}
              />
            </ItemWrap>
            <Comment
              show={Boolean(errors.addressDetail)}
              content={String(errors.addressDetail?.message)}
            />
          </AddressWrap>
        </RightInput>
      </InputWrap>
      <SignUpButton tabIndex={16}>가입하기</SignUpButton>
      {isOpen && (
        <Modal
          open={true}
          footer={null}
          onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
          width={550}
          bodyStyle={{ padding: "15px" }}
        >
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </Modal>
      )}
    </Form>
  );
}
