import { Modal } from "antd";
import React from "react";
import styled from "styled-components";

interface Props {
  type?: string;
  info: string;
  buttonCount?: number;
  onCancel: () => void;
  onOk: () => void;
}

export default function Popup({
  type,
  info,
  buttonCount,
  onCancel,
  onOk,
}: Props) {
  return (
    <CustomModal
      open={true}
      centered
      closeIcon={false}
      footer={null}
      transitionName="none"
      maskTransitionName="none"
      // onCancel={onCancel}
      width={520}
      maskStyle={{
        backgroundColor: "#707478",
      }}
    >
      <div
        style={{
          height:
            type === "login" || type === "phoneConfirm" || type === "consulting"
              ? 261
              : 241,
        }}
      >
        <div
          style={{
            color: "#2F2E39",
            fontSize: 20,
            fontWeight: "500",
            marginLeft: "24px",
            paddingTop: "24px",
          }}
        >
          안내사항
        </div>
        <div
          style={{
            marginTop: "21px",
            border: "1px #9DA5B1 solid",
          }}
        ></div>
        <div
          style={{
            color: "#000",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginTop: "38px",
            marginBottom: "35px",
            marginLeft: "24px",
          }}
        >
          {(type === "login" ||
            type === "phoneConfirm" ||
            type === "consulting") &&
            info.split("/").map((e) => <div>{e}</div>)}
          {type !== "login" &&
            type !== "phoneConfirm" &&
            type !== "consulting" &&
            info}
        </div>
        <div
          style={{
            marginTop: "21px",
            border: "1px #9DA5B1 solid",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            gap: "14px",
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          {buttonCount !== 1 && (
            <button
              style={{
                display: "flex",
                height: "39.892px",
                padding: "12px 24px",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                color: "#2F2E39",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={onCancel}
            >
              취소
            </button>
          )}
          <button
            style={{
              display: "flex",
              height: "39.892px",
              padding: "12px 24px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              border: "none",
              color: "#F0F4F7",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              background: "#009EE2",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={onOk}
          >
            확인
          </button>
        </div>
      </div>
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }
`;
