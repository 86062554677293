import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

const getProduct = async (productId: number) => {
  const { data } = await axiosClient.get(`/web/products/${productId}`);
  return data;
};

export default function useGetProduct(productId: number) {
  return useQuery(["getProduct", productId], () => getProduct(productId), {
    // cacheTime 0 : 다른 상품 보기 눌렀을때 ui상 데이터만 바뀌고 화면은 그대로라 인지가 잘 안됨(스크롤 유지)
    // 0으로 처리하여 cache없애고 스크롤 이동, 상품이 바뀌었다는 인지가 됨
    cacheTime: 0,
    onError: (err) => alert(err),
  });
}
