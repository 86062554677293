import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import DaumPostcodeEmbed from "react-daum-postcode";
import { Modal, Tooltip } from "antd";

import AppHeader from "../../components/Header";
import {
  Wrap,
  RegisterButton,
  RegisterButtonText,
  Main,
  TitleWrap,
  TitleText,
  Title,
  TitleInfo,
  TitleInfoText,
  // SubjectWrap,
  // SubjectText,
  // SubjectInput,
  MainInputWrap,
  ImgInputWrap,
  ImgPreviewWrap,
  ImgInputMenu,
  ImgInputStatus,
  ImgInputButtonWrap,
  ImgInputSearchFile,
  ImgInputDeleteFile,
  DetailInputWrap,
  Category,
  CommonTitle,
  CommonSelect,
  CategoryAndProdName,
  ProdName,
  Size,
  SizeTitle,
  SizeTitleWrap,
  SizeTitleInfo,
  SizeWrap,
  SizeUnit,
  SizeUnitInput,
  UsePeriod,
  UsePeriodInputWrap,
  UsePeriodYearInput,
  CommonUnitText,
  UsePeriodInputMonth,
  ProdNameInput,
  Condition,
  SellPrice,
  SellPriceTitleWrap,
  SellPriceTitle,
  SellPriceInputWrap,
  ProductStateTitle,
  ProductState,
  ProductStateSmallTitle,
  NormalWork,
  NormalWorkSelectWrap,
  ScratchState,
  ScratchStateSelect,
  DetailContentInput,
  DamageArea,
  DamageAreaSelectWrap,
  Option,
  OptionText,
  PickUpPlace,
  PickUpPlaceTitleWrap,
  PickUpPlaceTitle,
  PickUpPlaceTitleInfo,
  LoadFromUser,
  PickUpPlaceInputWrap,
  LoadFromUserCheck,
  LoadFromUserText,
  AddressWrap,
  AddressBasic,
  AddressBasicInput,
  SearchAddressButton,
  AddressDetailInput,
  ProductShipmentSchedule,
  PSSSelectWrap,
  PSSOption,
  ProductDesc,
  ProductDescInput,
  ImageInputSearchFileLabel,
  SignContainer,
  SellPriceInputContainer,
  CommissionInputWrap,
  DeliveryFeeInputWrap,
  FinalFeeWrap,
  SellPriceInputDesc,
  SellPriceInputMain,
  SellPriceInput,
  CommissionExplanation,
  CommissionInputDesc,
  CommissionInputMain,
  CommissionRate,
  CommissionInput,
  DeliveryFeeInputDesc,
  DeliveryFeeInputMain,
  DeliveryFeeInput,
  FinalFeeDesc,
  FinalFee,
  FinalFeeMain,
  Form,
  RadioCheckBox,
  PSSInput,
  DamageAreaInput,
  ImgInputStatusWrap,
  DeliveryFeeInfo,
  DeliveryFeeSettingInfo,
} from "./styles";
import { ReactComponent as TitleInfoIcon } from "../../assets/content/sell/ic-content-sell-info.svg";
import { ReactComponent as SellPriceQuestionIcon } from "../../assets/content/sell/ic-content-sell-question.svg";
import { UserDBIdState } from "../../recoil/login";
import { axiosAuthClient } from "../../apis/general";
import { ProductInfo } from "../../types/productInfo";
import ImageSlider from "./imageSlider";
import useGetMemberAddress from "../../hooks/auth/useGetMemberAddress";
import useGetCategories from "../../hooks/common/useGetCategories";
import useGetPrdCondition from "../../hooks/common/useGetPrdCondition";
import useGetPrdScratch from "../../hooks/common/useGetPrdScratch";
import useCurrentDate from "../../hooks/signup/useCurrentDate";
import {
  calDeliveryFee,
  changeCommaFormat,
  changeNumRealForm,
  changeNumberFormat,
  findDiffValueCount,
  // isTitleChanged,
} from "../../utils/utility";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/common";
import CustomTooltip from "../../components/Tooltip";

const MonthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export default function Sell() {
  const navigate = useNavigate();
  const [finalFee, setFinalFee] = useState("");
  const [imageFiles, setImageFiles] = useState<any>();
  const [showDeliveryFeeInfo, setShowDeliveryFeeInfo] = useState(false);
  const [loadFromUserCheck, setLoadFromUserCheck] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [userId] = useRecoilState(UserDBIdState);

  const { data: categories } = useGetCategories();
  const { data: productConditionList } = useGetPrdCondition();
  const { data: productScratchList } = useGetPrdScratch();
  const { data: memberAddress, refetch } = useGetMemberAddress({
    userId,
  });
  const currentDate = useCurrentDate();
  const isSubmit = useRef(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeliveryFeeOpen, setIsDeliveryFeeOpen] = useState(false);
  const [deliveryFeeList, setDeliveryFeeList] = useState([]);
  const [brokerageFeeRate, setBrokerageFeeRate] = useState([]);

  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const defaultInputValues = {
    userId,
    // productTitle: "",
    imageFiles: "", // 실제로 데이터 사용하진 않음. 유효성 검사하기 위해 세팅
    categoryId: 0,
    name: "",
    // width: 0,
    // depth: 0,
    // height: 0,
    // usedYear: 0,
    usedMonth: 0,
    conditionId: 0,

    normalOperatingStatus: "Y",
    scratchStatusId: 0,
    scratchDetail: "",
    damagedArea: "없음",
    damagedAreaDetail: "",

    pickUpAddress: "",
    pickUpAddressDetail: "",
    expectedShipmentDate: "빠른 시일 내에",
    specificShipmentDate: "",

    // price: 0,
    // brokerageFee: 0,
    // expectedDeliveryFee: 0,

    description: "",
    saveStatus: "저장완료",
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    resetField,
    handleSubmit,
    trigger,
    formState: { isValid },
  } = useForm<ProductInfo>({
    defaultValues: defaultInputValues,
  });

  useEffect(() => {
    const getDefaultValues = async () => {
      try {
        const {
          data: { result },
        } = await axiosAuthClient.get(`/web/default-values`);
        setDeliveryFeeList(result?.deliveryFees);
        setBrokerageFeeRate(result?.brokerageFeeRate.brokerageFeeRate);
      } catch (error) {
        alert(error);
      }
    };
    getDefaultValues();
  }, []);

  useEffect(() => {
    if (loadFromUserCheck) {
      setValue("pickUpAddress", memberAddress?.result?.address);
      setValue("pickUpAddressDetail", memberAddress?.result?.addressDetail);
      trigger("pickUpAddress");
      trigger("pickUpAddressDetail");
    }
  }, [memberAddress, loadFromUserCheck]);

  useEffect(() => {
    const DeliveryFee = calDeliveryFee(
      watch("width"),
      watch("depth"),
      watch("height"),
      deliveryFeeList
    );
    const SizeSum = watch("width") + watch("depth") + watch("height");
    setValue("expectedDeliveryFee", changeCommaFormat(DeliveryFee));
    setShowDeliveryFeeInfo(SizeSum > 600 ? true : false);
    setFinalFee(
      changeCommaFormat(
        Number(watch("price")) +
          Number(
            (
              Number(watch("price")) *
              (Number(brokerageFeeRate) * 0.01)
            ).toFixed(0)
          ) +
          DeliveryFee
      )
    );
  }, [watch("price"), watch("width"), watch("depth"), watch("height")]);

  /** 임시저장(페이지 이탈시) */
  useEffect(() => {
    return () => {
      if (isSubmit.current === true) return;
      // 주소, 상세주소는 defaultValue는 "" 값이나 처음 화면 진입시 값을 넣어주기 때문에
      // 그 값이 초기값이라고 봐야함. 따라서 바뀐 개수 체크에 포함하지 않음
      // 그래서 바뀐개수가 2보다 커야하는 것으로 개발
      if (findDiffValueCount(defaultInputValues, getValues()) > 2) {
        onSubmit({
          ...getValues(),
          saveStatus: "임시저장",
        });
      }
    };
  }, []);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data?.address.split(" ").splice(2).join(" ");
    setValue("pickUpAddress", sidoSigungu + splitAddress);
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const requestSellProduct = useMutation({
    mutationFn: (param: any) => {
      axiosAuthClient.defaults.headers.post["Content-Type"] =
        "multipart/form-data";
      return axiosAuthClient.post("/web/products", param);
    },
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        navigate("/sell/success");
      } else {
        alert(`판매등록에 실패했습니다(code: ${code})`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  // 임시저장 요청
  const requestTemporayStorage = useMutation({
    mutationFn: (param: any) => {
      axiosAuthClient.defaults.headers.post["Content-Type"] =
        "multipart/form-data";
      return axiosAuthClient.post("/web/products", param);
    },
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        // 사용자는 임시저장 기능을 모르기 때문에 알림이 뜨면 안됨
      } else {
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const onSubmit = (data: ProductInfo) => {
    // 금액 부분 number로 변환(price, brokerageFee, expectedDeliveryFee)
    const { price, brokerageFee, expectedDeliveryFee } = data;
    data.price = changeNumRealForm(price);
    data.brokerageFee = changeNumRealForm(brokerageFee);
    data.expectedDeliveryFee = changeNumRealForm(expectedDeliveryFee);

    if (data.damagedAreaDetail) {
      data.damagedArea = data.damagedAreaDetail;
    }

    if (data.specificShipmentDate) {
      data.expectedShipmentDate = data.specificShipmentDate;
    }

    const formData = new FormData();
    imageFiles?.forEach((item: File) => {
      formData.append("files", item);
    });
    formData.append("productData", JSON.stringify(data));

    data.saveStatus === "임시저장"
      ? requestTemporayStorage.mutate(formData)
      : requestSellProduct.mutate(formData);
  };

  if (requestSellProduct.isLoading) {
    return <div>상품을 등록중입니다..</div>;
  }

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Main>
            <TitleWrap>
              <Title>
                <TitleText>판매하기</TitleText>
              </Title>
              <TitleInfo>
                <TitleInfoIcon />
                <TitleInfoText>
                  글 작성이 막막하다면, 상담 신청 버튼(우측 상단)을 눌러주세요.
                  운영 현황에 따라 수수료 및 배송비는 업데이트 될 수 있습니다.
                </TitleInfoText>
              </TitleInfo>
            </TitleWrap>

            {/* <SubjectWrap>
              <SubjectText>제목</SubjectText>
              <SubjectInput
                {...register("productTitle", {
                  required: true,
                })}
                placeholder="제목을 입력해주세요."
                tabIndex={1}
                maxLength={50}
              />
            </SubjectWrap> */}
            <MainInputWrap>
              <ImgInputWrap>
                <ImgPreviewWrap>
                  <ImageSlider imageFiles={imageFiles} />
                </ImgPreviewWrap>
                <ImgInputMenu>
                  <ImgInputStatusWrap>
                    <ImgInputStatus>
                      파일 {imageFiles ? imageFiles.length : 0}/5 업로드
                    </ImgInputStatus>
                  </ImgInputStatusWrap>
                  <ImgInputButtonWrap>
                    <ImageInputSearchFileLabel
                      htmlFor="image"
                      disabled={imageFiles?.length === 5}
                      tabIndex={2}
                    >
                      파일찾기
                    </ImageInputSearchFileLabel>
                    <ImgInputSearchFile
                      {...register("imageFiles")}
                      type="file"
                      id="image"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        setImageFiles(
                          imageFiles
                            ? [...imageFiles, e.target.files?.[0]]
                            : [e.target.files?.[0]]
                        );
                      }}
                      disabled={imageFiles?.length === 5}
                    />
                    <ImgInputDeleteFile
                      type="button"
                      onClick={() => {
                        if (imageFiles && imageFiles.length !== 0) {
                          onTogglePopUp();
                        }
                      }}
                      disabled={!imageFiles || imageFiles?.length === 0}
                      tabIndex={3}
                    >
                      삭제
                    </ImgInputDeleteFile>
                  </ImgInputButtonWrap>
                </ImgInputMenu>
              </ImgInputWrap>
              <DetailInputWrap>
                <CategoryAndProdName>
                  <ProdName>
                    <CommonTitle>상품명</CommonTitle>
                    <ProdNameInput
                      {...register("name", {
                        required: true,
                      })}
                      placeholder="상품명을 입력해주세요."
                      tabIndex={4}
                      maxLength={20}
                    />
                  </ProdName>
                  <Category>
                    <CommonTitle>카테고리</CommonTitle>
                    <CommonSelect
                      {...register("categoryId", {
                        valueAsNumber: true,
                        validate: (value) => value > 0,
                      })}
                      tabIndex={5}
                    >
                      <option value={0}>카테고리 선택</option>
                      {categories?.map(({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ))}
                    </CommonSelect>
                  </Category>
                </CategoryAndProdName>
                <Size>
                  <SizeTitleWrap>
                    <SizeTitle>사이즈</SizeTitle>
                    <SizeTitleInfo>
                      상품이 들어갈 수 있는 상자가 있다고 가정하여 상자의 가로,
                      세로, 높이를 측정해주세요.
                    </SizeTitleInfo>
                  </SizeTitleWrap>
                  <SizeWrap>
                    <SizeUnit>
                      <CommonUnitText>가로</CommonUnitText>
                      <SizeUnitInput
                        {...register("width", {
                          valueAsNumber: true,
                          validate: (value) => value > 0,
                          onChange: (e) => {
                            setValue(
                              "width",
                              changeNumberFormat(e.target.value)
                            );
                          },
                        })}
                        placeholder={"필수 입력"}
                        tabIndex={6}
                        min="0"
                      />
                      <CommonUnitText>cm</CommonUnitText>
                    </SizeUnit>
                    <SizeUnit>
                      <CommonUnitText>세로</CommonUnitText>
                      <SizeUnitInput
                        {...register("depth", {
                          valueAsNumber: true,
                          validate: (value) => value > 0,
                          onChange: (e) => {
                            setValue(
                              "depth",
                              changeNumberFormat(e.target.value)
                            );
                          },
                        })}
                        placeholder={"필수 입력"}
                        tabIndex={7}
                        min="0"
                      />
                      <CommonUnitText>cm</CommonUnitText>
                    </SizeUnit>
                    <SizeUnit>
                      <CommonUnitText>높이</CommonUnitText>
                      <SizeUnitInput
                        {...register("height", {
                          valueAsNumber: true,
                          validate: (value) => value > 0,
                          onChange: (e) => {
                            setValue(
                              "height",
                              changeNumberFormat(e.target.value)
                            );
                          },
                        })}
                        placeholder={"필수 입력"}
                        tabIndex={8}
                        min="0"
                      />
                      <CommonUnitText>cm</CommonUnitText>
                    </SizeUnit>
                  </SizeWrap>
                  {showDeliveryFeeInfo && (
                    <DeliveryFeeInfo>
                      입력할 수 있는 범위를 초과했습니다.
                    </DeliveryFeeInfo>
                  )}
                </Size>
                <UsePeriod>
                  <CommonTitle>사용 기간</CommonTitle>
                  <UsePeriodInputWrap>
                    <UsePeriodYearInput
                      {...register("usedYear", {
                        valueAsNumber: true,
                        validate: (value) => Number(value) >= 0,
                        onChange: (e) => {
                          setValue(
                            "usedYear",
                            changeNumberFormat(e.target.value)
                          );
                        },
                      })}
                      placeholder={"필수 입력"}
                      tabIndex={9}
                      min="0"
                    />
                    <CommonUnitText>년</CommonUnitText>
                    <UsePeriodInputMonth
                      {...register("usedMonth", {
                        required: true,
                        valueAsNumber: true,
                      })}
                      tabIndex={10}
                    >
                      {MonthList.map((i) => (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      ))}
                    </UsePeriodInputMonth>
                    <CommonUnitText>개월</CommonUnitText>
                  </UsePeriodInputWrap>
                </UsePeriod>
                <Condition>
                  <CommonTitle>컨디션</CommonTitle>
                  <CommonSelect
                    {...register("conditionId", {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    tabIndex={11}
                  >
                    <option value={0}>컨디션을 선택해주세요.</option>
                    {productConditionList?.map(({ id, condition }) => (
                      <option value={id} key={id}>
                        {condition}
                      </option>
                    ))}
                  </CommonSelect>
                </Condition>
                <ProductState>
                  <ProductStateTitle>상품 상태 상세</ProductStateTitle>
                  <NormalWork>
                    <ProductStateSmallTitle>정상 작동</ProductStateSmallTitle>
                    <NormalWorkSelectWrap>
                      <Option>
                        <RadioCheckBox
                          type="radio"
                          {...register("normalOperatingStatus", {
                            required: true,
                          })}
                          value="Y"
                          tabIndex={12}
                        />
                        <OptionText>YES</OptionText>
                      </Option>
                      <Option>
                        <RadioCheckBox
                          type="radio"
                          {...register("normalOperatingStatus", {
                            required: true,
                          })}
                          value="N"
                          tabIndex={13}
                        />
                        <OptionText>NO</OptionText>
                      </Option>
                    </NormalWorkSelectWrap>
                  </NormalWork>
                  <ScratchState>
                    <ProductStateSmallTitle>기스 상태</ProductStateSmallTitle>
                    <ScratchStateSelect
                      {...register("scratchStatusId", {
                        valueAsNumber: true,
                        validate: (value) => value > 0,
                      })}
                      tabIndex={14}
                    >
                      <option value={0}>기스 상태를 선택해주세요.</option>
                      {productScratchList?.map(({ id, scratch }) => (
                        <option value={id} key={id}>
                          {scratch}
                        </option>
                      ))}
                    </ScratchStateSelect>
                    <DetailContentInput
                      {...register("scratchDetail")}
                      disabled={[0, 1].includes(
                        Number(watch("scratchStatusId"))
                      )}
                      placeholder="상세 내용을 입력해주세요."
                      tabIndex={15}
                      maxLength={50}
                    />
                  </ScratchState>
                  <DamageArea>
                    <ProductStateSmallTitle>파손 부위</ProductStateSmallTitle>
                    <DamageAreaSelectWrap>
                      <Option>
                        <RadioCheckBox
                          type="radio"
                          {...register("damagedArea", {
                            required: true,
                            validate: () =>
                              !(
                                watch("damagedArea") === "있음" &&
                                watch("damagedAreaDetail")?.length === 0
                              ),
                          })}
                          value="없음"
                          tabIndex={16}
                        />
                        <OptionText>없음</OptionText>
                      </Option>
                      <Option>
                        <RadioCheckBox
                          type="radio"
                          {...register("damagedArea", {
                            required: true,
                          })}
                          value="있음"
                          tabIndex={17}
                        />
                        <DamageAreaInput
                          {...register("damagedAreaDetail")}
                          disabled={watch("damagedArea") === "없음"}
                          placeholder="파손 부위를 입력해주세요."
                          maxLength={50}
                          tabIndex={18}
                        />
                      </Option>
                    </DamageAreaSelectWrap>
                  </DamageArea>
                </ProductState>
                <PickUpPlace>
                  <PickUpPlaceTitleWrap>
                    <PickUpPlaceTitle>픽업장소</PickUpPlaceTitle>
                    <PickUpPlaceTitleInfo>
                      구매자에게는 노출되지 않는 정보니 안심하세요
                    </PickUpPlaceTitleInfo>
                  </PickUpPlaceTitleWrap>
                  <PickUpPlaceInputWrap>
                    <LoadFromUser>
                      <LoadFromUserCheck
                        checked={loadFromUserCheck}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setLoadFromUserCheck(true);
                            refetch();
                            return;
                          }
                          setValue("pickUpAddress", "");
                          setValue("pickUpAddressDetail", "");
                          setLoadFromUserCheck(false);
                          trigger("pickUpAddress");
                          trigger("pickUpAddressDetail");
                        }}
                        tabIndex={19}
                      />
                      <LoadFromUserText>
                        회원 정보에서 불러오기
                      </LoadFromUserText>
                    </LoadFromUser>
                    <AddressWrap>
                      <AddressBasic>
                        <AddressBasicInput
                          {...register("pickUpAddress", {
                            required: true,
                          })}
                          tabIndex={20}
                        />
                        <SearchAddressButton
                          type="button"
                          onClick={onToggleModal}
                          tabIndex={21}
                        >
                          주소 찾기
                        </SearchAddressButton>
                      </AddressBasic>
                      <AddressDetailInput
                        {...register("pickUpAddressDetail", {
                          required: true,
                        })}
                        tabIndex={22}
                      />
                    </AddressWrap>
                  </PickUpPlaceInputWrap>
                </PickUpPlace>
                <ProductShipmentSchedule>
                  <CommonTitle>상품 출하 일정</CommonTitle>
                  <PSSSelectWrap>
                    <Option>
                      <RadioCheckBox
                        type="radio"
                        {...register("expectedShipmentDate", {
                          required: true,
                          validate: () =>
                            !(
                              watch("expectedShipmentDate") ===
                                "특정 날짜 이후에" &&
                              watch("specificShipmentDate")?.length === 0
                            ),
                        })}
                        value="빠른 시일 내에"
                        tabIndex={23}
                      />
                      <OptionText>빠른 시일내</OptionText>
                    </Option>
                    <PSSOption>
                      <RadioCheckBox
                        type="radio"
                        {...register("expectedShipmentDate", {
                          required: true,
                        })}
                        value="특정 날짜 이후에"
                        tabIndex={24}
                      />
                      <PSSInput
                        type="date"
                        disabled={
                          watch("expectedShipmentDate") === "빠른 시일 내에"
                        }
                        {...register("specificShipmentDate")}
                        min={currentDate}
                        tabIndex={25}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </PSSOption>
                  </PSSSelectWrap>
                </ProductShipmentSchedule>
              </DetailInputWrap>
            </MainInputWrap>
            <SellPrice>
              <SellPriceTitleWrap>
                <SellPriceTitle>판매 금액</SellPriceTitle>
              </SellPriceTitleWrap>
              <SellPriceInputContainer>
                <SellPriceInputWrap>
                  <SellPriceInputDesc>
                    판매 금액 입력
                    <Tooltip
                      placement="right"
                      title={"물건을 판매하여 받게 될 금액이에요"}
                      overlayInnerStyle={{
                        color: "#000",
                        background: "#ffffff",
                        border: "1px solid #000",
                      }}
                      arrow={false}
                    >
                      <SellPriceQuestionIcon width="18" height="18" />
                    </Tooltip>
                  </SellPriceInputDesc>
                  <SellPriceInputMain>
                    <SellPriceInput
                      {...register("price", {
                        required: true,
                        onChange: (e) => {
                          const value = changeNumberFormat(e.target.value);
                          setValue("price", value);
                          setValue(
                            "brokerageFee",
                            changeCommaFormat(
                              Number(
                                (
                                  value *
                                  (Number(brokerageFeeRate) * 0.01)
                                ).toFixed(0)
                              )
                            )
                          );
                        },
                      })}
                      placeholder="￦ 가격을 입력해주세요."
                      tabIndex={26}
                      style={{
                        borderWidth: "1.5px",
                        borderColor: "var(--Main)",
                        outline: "none",
                      }}
                    />
                    <CommonUnitText>원</CommonUnitText>
                  </SellPriceInputMain>
                </SellPriceInputWrap>
                <SignContainer>+</SignContainer>
                <CommissionInputWrap>
                  <CommissionInputDesc>
                    중개 수수료
                    <CommissionExplanation>
                      판매 금액의 n%
                    </CommissionExplanation>
                  </CommissionInputDesc>
                  <CommissionInputMain>
                    <CommissionRate
                      value={brokerageFeeRate + "%"}
                      style={{ color: "#979797" }}
                      disabled
                    />
                    <CommissionInput
                      {...register("brokerageFee")}
                      placeholder=""
                      readOnly
                    />
                    <CommonUnitText>원</CommonUnitText>
                  </CommissionInputMain>
                </CommissionInputWrap>
                <SignContainer>+</SignContainer>
                <DeliveryFeeInputWrap>
                  <DeliveryFeeInputDesc>예상 배송비</DeliveryFeeInputDesc>
                  <DeliveryFeeInputMain>
                    <DeliveryFeeInput
                      {...register("expectedDeliveryFee")}
                      readOnly
                    />
                    <CommonUnitText>원</CommonUnitText>
                  </DeliveryFeeInputMain>
                </DeliveryFeeInputWrap>
                <SignContainer>=</SignContainer>
                <FinalFeeWrap>
                  <FinalFeeDesc>
                    최종 판매 금액
                    <Tooltip
                      placement="right"
                      title={"구매자가 상품 구매를 위해 지불할 금액이에요."}
                      overlayStyle={{ maxWidth: "500px" }}
                      overlayInnerStyle={{
                        color: "#000",
                        background: "#ffffff",
                        border: "1px solid #000",
                      }}
                      arrow={false}
                    >
                      <SellPriceQuestionIcon width="18" height="18" />
                    </Tooltip>
                  </FinalFeeDesc>
                  <FinalFeeMain>
                    <FinalFee value={finalFee} readOnly />
                    <CommonUnitText>원</CommonUnitText>
                  </FinalFeeMain>
                </FinalFeeWrap>
              </SellPriceInputContainer>
            </SellPrice>
            <SellPrice>
              <div
                style={{
                  width: "100%",
                  height: "22px",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsDeliveryFeeOpen(!isDeliveryFeeOpen)}
              >
                <SellPriceTitleWrap>
                  <SellPriceTitle>배송비 책정 안내</SellPriceTitle>
                  <DeliveryFeeSettingInfo>
                    예상 배송비는 입력하신 상품의 크기를 기반으로 책정됩니다.
                    안내드린 가격에서 변경될 수 있습니다.
                  </DeliveryFeeSettingInfo>
                </SellPriceTitleWrap>
                <div
                  style={{
                    marginRight: "20px",
                    transform: isDeliveryFeeOpen
                      ? "rotate(180deg)"
                      : "rotate(0)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    viewBox="0 0 20 12"
                    fill="none"
                  >
                    <path
                      d="M19 1.67188L10.033 9.26563L1.06605 1.67188"
                      stroke="#33363F"
                      strokeWidth="3"
                    />
                  </svg>
                </div>
              </div>
              {isDeliveryFeeOpen && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <CustomTooltip />
                </div>
              )}
            </SellPrice>
            <ProductDesc>
              <CommonTitle>상품 설명</CommonTitle>
              <ProductDescInput
                {...register("description")}
                placeholder="상품 설명을 작성해주세요."
                tabIndex={27}
                maxLength={200}
              />
            </ProductDesc>
          </Main>
          <RegisterButton
            type="submit"
            disabled={
              !(imageFiles?.length > 0 && isValid) ||
              showDeliveryFeeInfo === true
            }
            tabIndex={28}
            onClick={() => {
              isSubmit.current = true;
            }}
          >
            <RegisterButtonText>등록하기</RegisterButtonText>
          </RegisterButton>
          {isOpen && (
            <Modal
              open={true}
              footer={null}
              onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
              width={550}
              bodyStyle={{ padding: "15px" }}
            >
              <DaumPostcodeEmbed onComplete={handleComplete} />
            </Modal>
          )}
          {isPopUpOpen && (
            <Popup
              info={"업로드한 이미지를 전부 삭제하시겠습니까?"}
              onCancel={onTogglePopUp}
              onOk={() => {
                imageFiles.forEach((item: any) => URL.revokeObjectURL(item[0]));
                resetField("imageFiles");
                setImageFiles([]);
                onTogglePopUp();
              }}
            />
          )}
        </Form>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
