import axios, { AxiosInstance } from "axios";
import { JWT_KEY } from "../../config/constant";

export const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,

  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosAuthClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,

  headers: {
    "Content-Type": "application/json",
  },
});

axiosAuthClient.interceptors.request.use(
  (config) => {
    const jwt = localStorage.getItem(JWT_KEY);
    if (jwt) {
      config.headers = {
        "x-access-token": `${jwt}`,
      };
      return config;
    }
    alert("로그인을 해주세요.");
    window.location.href = "/login";
    return config;
  },
  (error) => {
    alert(error);
    return Promise.reject(error);
  }
);

let isAlertShown = false;

axiosAuthClient.interceptors.response.use(
  (response) => {
    if (response.data.code === 2000 && !isAlertShown) {
      isAlertShown = true;
      localStorage.removeItem(JWT_KEY);
      alert("인증시간이 만료되었습니다. 다시 로그인을 해주세요.");
      window.location.href = "/login";
      return response;
    }
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
