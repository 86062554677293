import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import DefaultLayout from "./layout/DefaultLayout";

import Login from "./pages/login";
import Page404 from "./pages/page404/Page404";
import Page500 from "./pages/page500/Page500";
import ProductInquiry from "./pages/productInquiry";
import Product from "./pages/product";
import Cart from "./pages/cart";
import Pay from "./pages/pay";
import MyPage from "./pages/mypage";
import MyPageModify from "./pages/mypage_modify";
import SignUp from "./pages/signup";
import SignUpBiz from "./pages/signup/biz";
import SignUpCommon from "./pages/signup/common";
import FindID from "./pages/find/id";
import FindPW from "./pages/find/password";
import Sell from "./pages/sell";
import SellModify from "./pages/sell_modify";
import TradingStatus from "./pages/tradingstatus";
import Settlement from "./pages/settlement";
import SettlementApply from "./pages/settlement_apply";
import Payments from "./pages/payments";
import PaymentsSuccess from "./pages/payments_success";
import PaymentsFail from "./pages/payments_fail";
import PaymentsTossSuccess from "./pages/payments/toss";
import SellSuccess from "./pages/sell_success";
import { isMobile } from "react-device-detect";
import MobilePage from "./pages/mobile_page";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      {isMobile ? <MobilePage /> :
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/" element={<DefaultLayout />} />
          <Route path="/:url" element={<DefaultLayout />} />
          <Route path="/productinquiry" element={<ProductInquiry />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/mypage/modify" element={<MyPageModify />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup/biz" element={<SignUpBiz />} />
          <Route path="/signup/common" element={<SignUpCommon />} />
          <Route path="/findid" element={<FindID />} />
          <Route path="/findpw" element={<FindPW />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/sell/modify/:id" element={<SellModify />} />
          <Route path="/tradingstatus" element={<TradingStatus />} />
          <Route path="/settlement" element={<Settlement />} />
          <Route path="/settlement/apply" element={<SettlementApply />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/payments/success" element={<PaymentsSuccess />} />
          <Route path="/payments/fail" element={<PaymentsFail />} />
          <Route
            path="/payments/toss/success"
            element={<PaymentsTossSuccess />}
          />
          <Route path="/sell/success" element={<SellSuccess />} />
        </Routes>
      }
    </BrowserRouter>
  );
};

export default App;
