import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

const checkMyPage = async (userDBId: number) => {
  const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
  return data;
};

export default function useCheckMyPageQuery(userDBId: number) {
  return useQuery(["checkMyPage"], () => checkMyPage(userDBId), {
    select: (res) => res.result.user,
    onError: (err) => alert(err),
  });
}
