import React, { useEffect, useRef, useState } from "react";
import {
  CartItem,
  CartItemWrap,
  CartDetail,
  CartItemInfo,
  ItemSummary,
  CartWrap,
  Container,
  CartStatus,
  ItemSummaryWrap,
  ButtonWrap,
  DeleteButton,
  FisrtItemText,
  SecondItemText,
  ImgWrapper,
  Filter,
  FilterAndSearch,
  MySelling,
  FilterText,
  MyBuying,
  Search,
  SearchInput,
  SearchIconWrap,
  NoItemWrapper,
  NoItemComment,
  LoadingText,
  SellModifyButton,
  DeletePurchasedProductButton,
} from "./styles";

// import PopularItem01 from "../../assets/content/popularItem/img-content-popularItem-01.png";
import { MyPaginate } from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import useGetSellingStatus from "../../hooks/trading/useGetSellingStatus";
import { changeCommaFormat } from "../../utils/utility";
import { ReactComponent as SearchIcon } from "../../assets/header/ic-header-search.svg";
import useDeleteSellingWriting from "../../hooks/trading/useDeleteSellingWriting";
import Popup from "../../components/Popup/common";
// import MyTooltip from "../../components/CustomTooltip";

interface SalesProduct {
  id: number;
  productName: string;
  price: number;
  expectedDeliveryFee: number;
  oldExpectedDeliveryFee: number;
  oldExpectedDeliveryFeeCheckStatus: string;
  brokerageFee: number;
  oldBrokerageFee: number;
  oldBrokerageFeeCheckStatus: string;
  productImageUrl: string;
  productStatus: string;
  date: string;
}

interface Props {
  onSelect: (type: string) => void;
  selling: boolean;
  buying: boolean;
}

export default function SellingStatus({ onSelect, selling, buying }: Props) {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [productName, setProductName] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const productInput = useRef("");
  const { data, isLoading } = useGetSellingStatus(page, productName);
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const requestDelete = useDeleteSellingWriting(
    onToggleModal,
    page,
    productName
  );

  return (
    <>
      <FilterAndSearch>
        <Filter>
          <MySelling select={selling} onClick={() => onSelect("selling")}>
            <FilterText select={selling}>나의 판매</FilterText>
          </MySelling>
          <MyBuying select={buying} onClick={() => onSelect("buying")}>
            <FilterText select={buying}>나의 구매</FilterText>
          </MyBuying>
        </Filter>
        <Search>
          <SearchInput
            onChange={(e) => (productInput.current = e.target.value)}
            placeholder="상품 정보를 입력해주세요."
            tabIndex={1}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                setProductName(productInput.current);
              }
            }}
          />
          <SearchIconWrap
            onClick={() => setProductName(productInput.current)}
            tabIndex={2}
          >
            <SearchIcon />
          </SearchIconWrap>
        </Search>
      </FilterAndSearch>
      <CartWrap>
        {data?.salesProducts?.map(
          ({
            id,
            productName,
            price,
            // expectedDeliveryFee,
            // oldExpectedDeliveryFee,
            // oldExpectedDeliveryFeeCheckStatus,
            // brokerageFee,
            // oldBrokerageFee,
            // oldBrokerageFeeCheckStatus,
            productImageUrl,
            productStatus,
            date,
          }: SalesProduct) => (
            <Container key={id}>
              <CartItemWrap>
                <CartStatus
                  style={{
                    color:
                      productStatus !== "판매중" && productStatus !== "결제대기"
                        ? "#979797"
                        : "#000",
                  }}
                >
                  {productStatus !== "판매중" && productStatus !== "결제대기"
                    ? "판매완료"
                    : productStatus}
                  {/* {(expectedDeliveryFee !== oldExpectedDeliveryFee ||
                    brokerageFee !== oldBrokerageFee) && (
                    <MyTooltip
                      productName={productName}
                      expectedDeliveryFee={expectedDeliveryFee}
                      oldExpectedDeliveryFee={oldExpectedDeliveryFee}
                      brokerageFee={brokerageFee}
                      oldBrokerageFee={oldBrokerageFee}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M12.5 8V12M12.5 16H12.49M2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12Z"
                            stroke="#009EE2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </MyTooltip>
                  )} */}
                </CartStatus>
                <CartItem>
                  <CartDetail>
                    <ImgWrapper>
                      <img
                        src={productImageUrl}
                        alt="상품 이미지"
                        width={158}
                        height={104}
                        style={{
                          borderRadius: "8px",
                          opacity:
                            productStatus !== "판매중" &&
                            productStatus !== "결제대기"
                              ? "0.4"
                              : "1.0",
                        }}
                      />
                    </ImgWrapper>
                    <CartItemInfo>
                      <ItemSummaryWrap>
                        <ItemSummary>
                          <FisrtItemText
                            style={{
                              color:
                                productStatus !== "판매중" &&
                                productStatus !== "결제대기"
                                  ? "#979797"
                                  : "#000",
                            }}
                          >
                            {productName}
                          </FisrtItemText>
                          <FisrtItemText
                            style={{
                              color:
                                productStatus !== "판매중" &&
                                productStatus !== "결제대기"
                                  ? "#979797"
                                  : "#000",
                            }}
                          >
                            {productStatus === "판매중" ? "-" : productStatus}
                          </FisrtItemText>
                        </ItemSummary>
                        <ItemSummary>
                          <SecondItemText
                            style={{
                              color:
                                productStatus !== "판매중" &&
                                productStatus !== "결제대기"
                                  ? "#979797"
                                  : "#000",
                            }}
                          >
                            {changeCommaFormat(price)}원
                          </SecondItemText>
                          <SecondItemText
                            style={{
                              color:
                                productStatus !== "판매중" &&
                                productStatus !== "결제대기"
                                  ? "#979797"
                                  : "#000",
                            }}
                          >
                            {productStatus === "결제대기" &&
                              "가상계좌 입금 기한 : "}
                            {productStatus === "판매중" ? "-" : date}{" "}
                            {/* {productStatus === "배송 일정 확정" ||
                            productStatus === "배송중"
                              ? "예정"
                              : productStatus === "구매자 결제 완료"
                              ? "결제"
                              : "완료"} */}
                          </SecondItemText>
                        </ItemSummary>
                      </ItemSummaryWrap>
                      <ButtonWrap>
                        <SellModifyButton
                          isPurchased={
                            productStatus !== "판매중" &&
                            productStatus !== "결제대기"
                          }
                          onClick={() => {
                            if (
                              productStatus !== "판매중" &&
                              productStatus !== "결제대기"
                            ) {
                              window.open(`/product/${id}`, "_blank");
                            } else {
                              navigate(`/sell/modify/${id}`);
                            }
                          }}
                        >
                          판매 정보{" "}
                          {productStatus !== "판매중" &&
                          productStatus !== "결제대기"
                            ? "조회"
                            : "수정"}
                        </SellModifyButton>
                        {productStatus === "판매중" && (
                          <DeleteButton
                            onClick={() => {
                              setProductId(id);
                              onToggleModal();
                            }}
                          >
                            삭제
                          </DeleteButton>
                        )}
                        {productStatus !== "판매중" &&
                          productStatus !== "결제대기" && (
                            <DeletePurchasedProductButton
                              onClick={() => {
                                setProductId(id);
                                onToggleModal();
                              }}
                            >
                              삭제
                            </DeletePurchasedProductButton>
                          )}
                      </ButtonWrap>
                    </CartItemInfo>
                  </CartDetail>
                </CartItem>
              </CartItemWrap>
            </Container>
          )
        )}
        {isOpen && (
          <Popup
            info={"판매글을 삭제하시겠습니까?"}
            onCancel={onToggleModal}
            onOk={() => requestDelete.mutate({ productId })}
          />
        )}
        {isLoading && <LoadingText>데이터를 불러오는 중입니다.</LoadingText>}
        {data?.salesProducts.length === 0 && (
          <NoItemWrapper>
            <NoItemComment>판매내역이 없습니다.</NoItemComment>
          </NoItemWrapper>
        )}
      </CartWrap>
      {!(data?.salesProducts.length === 0) && (
        <MyPaginate
          marginTop="50px"
          forcePage={0}
          previousLabel={"〈"}
          nextLabel={"〉"}
          breakLabel={"..."}
          pageCount={Math.ceil((data?.totalCount ?? 1) / 5)}
          marginPagesDisplayed={0}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => {
            setPage(selected + 1);
          }}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </>
  );
}
