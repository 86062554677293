import React, { useEffect, useState } from "react";
import {
  InfoTitleWrap,
  InfoTitle,
  Address,
  InputWrap,
  Container,
  CheckOrSearchButton,
  CheckOrSearchButtonText,
  ItemWrap,
  ItemTitle,
  ItemInput,
  AddressTitle,
  InfoContent,
  AddressBasicInput,
  AddressDetailInput,
  InfoMain,
  InfoUnit,
  BasicInput,
  InfoButton,
  InfoButtonText,
} from "../../MyPage/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonWrap } from "../../../pages/mypage_modify/styles";
import useSignupStates from "../../../hooks/signup/useSignupStates";
import useModifyMyPageMutation from "../../../hooks/mypage/useModifyMyPageMutation";
import { UserDBIdState } from "../../../recoil/login";
import { useRecoilState } from "recoil";
import { Modal } from "antd";
import DaumPostcodeEmbed from "react-daum-postcode";
import Popup from "../../Popup/common";

interface PropsType {
  address: string;
  addressDetail: string;
  name: string;
  email: string;
  phoneNumber: string;
  userId: string;
}

const InputBorderStyle = {
  border: "none",
};

export default function MyPageModifyCommonUser(props: PropsType) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const {
    address: propsAddress,
    addressDetail: propsAddressDetail,
    name: propsName,
    email: propsEmail,
    phoneNumber,
    userId,
  } = props;
  const [userDBId] = useRecoilState(UserDBIdState);

  const {
    state: { name, email, address, addressDetail },
    action,
  } = useSignupStates();

  const AllInputsFilled =
    name &&
    name.length > 1 &&
    name.length < 21 &&
    email &&
    address &&
    addressDetail;

  useEffect(() => {
    action({ type: "address", payload: propsAddress });
    action({ type: "addressDetail", payload: propsAddressDetail });
    action({ type: "name", payload: propsName });
    action({ type: "email", payload: propsEmail });
  }, []);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data.address.split(" ").splice(2).join(" ");
    action({ type: "address", payload: sidoSigungu + splitAddress });
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const requestModify = useModifyMyPageMutation({ isBiz: false });

  const onRequetModify = () => {
    onTogglePopUp();
  };

  return (
    <>
      <InfoMain>
        <InfoUnit>
          <InfoTitleWrap>
            <InfoTitle>계정 정보</InfoTitle>
          </InfoTitleWrap>
          <InfoContent>
            <ItemWrap>
              <ItemTitle>아이디*</ItemTitle>
              <Container>
                <InputWrap>
                  <BasicInput
                    type="text"
                    value={userId}
                    style={InputBorderStyle}
                    readOnly
                  />
                </InputWrap>
              </Container>
            </ItemWrap>
          </InfoContent>
        </InfoUnit>
        <InfoUnit>
          <InfoTitleWrap>
            <InfoTitle>회원 정보</InfoTitle>
          </InfoTitleWrap>
          <InfoContent>
            <ItemWrap>
              <ItemTitle>이름*</ItemTitle>
              <ItemInput
                type="text"
                value={name}
                onChange={(e) =>
                  action({ type: "name", payload: e.target.value })
                }
                minLength={2}
                maxLength={20}
              />
            </ItemWrap>
            <ItemWrap>
              <ItemTitle>연락처*</ItemTitle>
              <ItemInput type="tel" placeholder={phoneNumber} disabled />
            </ItemWrap>
            <ItemWrap>
              <ItemTitle>이메일*</ItemTitle>
              <ItemInput
                type="email"
                value={email}
                onChange={(e) =>
                  action({ type: "email", payload: e.target.value })
                }
              />
            </ItemWrap>
            <Address>
              <AddressTitle>주소*</AddressTitle>
              <Container>
                <InputWrap>
                  <AddressBasicInput
                    type="text"
                    value={address}
                    onChange={(e) =>
                      action({ type: "address", payload: e.target.value })
                    }
                  />
                  <CheckOrSearchButton onClick={onToggleModal}>
                    <CheckOrSearchButtonText>주소 찾기</CheckOrSearchButtonText>
                  </CheckOrSearchButton>
                </InputWrap>
                <InputWrap>
                  <AddressDetailInput
                    type="text"
                    value={addressDetail}
                    onChange={(e) =>
                      action({ type: "addressDetail", payload: e.target.value })
                    }
                  />
                </InputWrap>
              </Container>
            </Address>
          </InfoContent>
        </InfoUnit>
      </InfoMain>
      <ButtonWrap>
        <ButtonLink to="/mypage">
          <InfoButton border="" background="#EEE">
            <InfoButtonText color="#ACB1BA">취소</InfoButtonText>
          </InfoButton>
        </ButtonLink>
        <ButtonLink to="">
          <InfoButton
            onClick={onRequetModify}
            border={AllInputsFilled ? "Main" : ""}
            background={AllInputsFilled ? "Main" : ""}
          >
            <InfoButtonText color={AllInputsFilled ? "#fff" : "#ACB1BA"}>
              변경사항 저장
            </InfoButtonText>
          </InfoButton>
        </ButtonLink>
      </ButtonWrap>
      {isOpen && (
        <Modal
          open={true}
          footer={null}
          onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
          width={550}
          bodyStyle={{ padding: "15px" }}
        >
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </Modal>
      )}
      {isPopUpOpen && (
        <Popup
          info={"변경된 내용을 저장하시겠습니까?"}
          onCancel={onTogglePopUp}
          onOk={() => {
            requestModify.mutate({
              id: userDBId,
              email,
              address,
              addressDetail,
              name,
            });
            onTogglePopUp();
          }}
        />
      )}
    </>
  );
}

const ButtonLink = styled(Link)`
  flex: 1;
  text-decoration: none;
`;
