import React from "react";
import Carousel from "react-material-ui-carousel";
import ImgPreviewIcon from "../../assets/content/sell/ic-content-sell-imgPreview.png";
import { ImgPreviewText } from "./styles";

interface Props {
  imageFiles: any[];
}

const styles = {
  Wrap: {
    width: "inherit",
    height: "inherit",
  },
  NoImageBox: {
    width: "460px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "17px",
  },
};

export default function ImageSlider({ imageFiles }: Props) {
  return (
    <div style={styles.Wrap}>
      {(!imageFiles || imageFiles?.length === 0) && (
        <div style={styles.NoImageBox as React.CSSProperties}>
          <img
            width={48}
            height={48}
            src={ImgPreviewIcon}
            alt="이미지 미리보기 아이콘"
          />
          <ImgPreviewText>최대 5장까지 등록 가능합니다</ImgPreviewText>
        </div>
      )}
      {imageFiles && imageFiles.length > 0 && (
        <Carousel
          swipe={false}
          autoPlay={false}
          indicators={true}
          indicatorContainerProps={{
            style: {
              position: "relative",
              bottom: "40px",
              zIndex: "2",
            },
          }}
        >
          {imageFiles?.map((item, index) => {
            let src;
            item.hasOwnProperty("productImageUrl")
              ? (src = item.productImageUrl)
              : (src = URL.createObjectURL(item));
            return <img src={src} key={index} width={460} height={300} />;
          })}
        </Carousel>
      )}
    </div>
  );
}
