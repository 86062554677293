import React from "react";
import AppHeader from "../../components/Header";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  InfoWrap,
  Info,
} from "../../components/MyPage/styles";
import MyPageModifyBizUser from "../../components/MyPageModify/bizUser";
import MyPageModifyCommonUser from "../../components/MyPageModify/commonUser";
import { UserBizState, UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";
import useCheckMyPageQuery from "../../hooks/mypage/useCheckMyPageQuery";

export default function MyPageModify() {
  const [userBiz] = useRecoilState(UserBizState);
  const [userDBId] = useRecoilState(UserDBIdState);

  const { data } = useCheckMyPageQuery(userDBId);

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>마이페이지</Title>
          </TitleWrap>
          <InfoWrap>
            <Info>
              {userBiz === "Y"
                ? data && <MyPageModifyBizUser {...data} />
                : data && <MyPageModifyCommonUser {...data} />}
            </Info>
          </InfoWrap>
        </Content>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
