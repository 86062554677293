import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

const getMemberAddress = async (userId: number) => {
  const { data } = await axiosAuthClient.get(`/web/users/${userId}/address`);
  return data;
};

interface Props {
  userId: number;
}

export default function useGetMemberAddress({ userId }: Props) {
  return useQuery(["getMemberAddress"], () => getMemberAddress(userId), {
    onError: (err) => alert(err),
  });
}
