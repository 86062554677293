import styled from "styled-components";

export const Text = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px; /* 80.357% */
  letter-spacing: 0.262px;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  /* flex: 1 0 0; */
  flex-wrap: wrap;
  /* border: 1px solid red; */
`;

export const ItemDescription = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.gap};
  flex: 1 0 0;
`;

export const ItemTitle = styled(Text)<{ fontSize: string }>`
  color: #212529;
  font-family: Pretendard;
  font-size: ${(props) => props.fontSize};
  font-weight: 700;
`;

export const ItemType = styled.div<{ isBiz: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* flex: 1 0 0; */
  width: 47px;

  border-radius: 8px;
  background: ${(props) => (props.isBiz === "Y" ? "#b3e2f6" : "#ffddbd")};
`;

export const ItemTypeText = styled(Text)<{ isBiz: string }>`
  color: ${(props) => (props.isBiz === "Y" ? "#009EE2" : "#FF8C22")};
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;

export const ItemTypeDisabled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* flex: 1 0 0; */
  width: 47px;

  border-radius: 8px;
  background: #9797974d;
`;

export const ItemTypeTextDisabled = styled(Text)`
  color: #979797;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;

export const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export const ItemDetailElement = styled.div<{ gap: string }>`
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.gap};
  align-self: stretch;
`;

export const ItemDetailElementText = styled(Text)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? "#979797" : "#212529")};
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
`;

export const Line = styled.hr`
  width: 192px;
  height: 1px;
  background: #d8d8d8;
  border-width: 0;
  color: gray;
`;

export const ItemPriceWrap = styled.div`
  width: 100%;
  text-align: right;
`;

export const ItemPriceText = styled(Text)<{
  fontSize: string;
  disabled?: boolean;
}>`
  color: ${(props) => (props.disabled ? "#979797" : "#212529")};
  font-family: Pretendard;
  font-size: ${(props) => props.fontSize};
`;

export const NoItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoItemComment = styled.p`
  color: var(--button-gray);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 85.714% */

  margin-top: 100px;
`;
