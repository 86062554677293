import React, { useState } from "react";
import {
  InfoTitleWrap,
  InfoTitle,
  Address,
  InputWrap,
  Container,
  CheckOrSearchButton,
  CheckOrSearchButtonText,
  ItemWrap,
  ItemTitle,
  ItemInput,
  AddressTitle,
  InfoContent,
  AddressBasicInput,
  AddressDetailInput,
  InfoMain,
  InfoUnit,
  BasicInput,
  InfoButton,
  InfoButtonText,
  Form,
} from "../../MyPage/styles";
import { ButtonWrap } from "../../../pages/mypage_modify/styles";
import { ConfigProvider, Modal } from "antd";
import DaumPostcodeEmbed from "react-daum-postcode";
import { Controller, useForm } from "react-hook-form";
import useModifyMyPageMutation from "../../../hooks/mypage/useModifyMyPageMutation";
import { UserDBIdState } from "../../../recoil/login";
import { useRecoilState } from "recoil";
import { SelectSectorId, filterOption } from "../../Auth/SelectSectorId";
import useGetSectorId from "../../../hooks/signup/useGetSectorId";
import { useNavigate } from "react-router-dom";
import { changeAllContactForm } from "../../../utils/utility";
import Popup from "../../Popup/common";

interface SectorId {
  id: number;
  name: string;
}

interface PersonalInfo {
  userId?: string;
  businessName: string;
  companyRegistrationNumber?: string;
  representativeName: string;
  companyEmail?: string;
  phoneNumber: string;
  address: string;
  addressDetail: string;
  involvedIn: string;
  sector: string; // 서버에서 주는 값(처음엔 sector. 중간에 sectorId로 바뀜)
  sectorId?: string; // 서버에 보낼 값
  openingDate?: string;
}

const InputBorderStyle = {
  border: "none",
};

export default function MyPageModifyBizUser(props: PersonalInfo) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };
  const { data: sectorIdList } = useGetSectorId();
  const [userDBId] = useRecoilState(UserDBIdState);
  const {
    userId,
    businessName,
    companyRegistrationNumber,
    representativeName,
    companyEmail,
    phoneNumber,
    address,
    addressDetail,
    involvedIn,
    sector,
    openingDate,
  } = props;

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      userId,
      businessName,
      companyRegistrationNumber,
      representativeName,
      companyEmail,
      phoneNumber,
      address,
      addressDetail,
      involvedIn,
      sectorId: sector,
      openingDate,
    },
  });

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data.address.split(" ").splice(2).join(" ");
    setValue("address", sidoSigungu + splitAddress);
    trigger("address");
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const requestModify = useModifyMyPageMutation({ isBiz: true });

  const onSubmit = (data: Omit<PersonalInfo, "sector">) => {
    window.localStorage.setItem("mypageInfo", JSON.stringify(data));
    onTogglePopUp();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InfoMain>
          <InfoUnit>
            <InfoTitleWrap>
              <InfoTitle>계정 정보</InfoTitle>
            </InfoTitleWrap>
            <InfoContent>
              <ItemWrap>
                <ItemTitle>아이디*</ItemTitle>
                <Container>
                  <InputWrap>
                    <BasicInput
                      type="text"
                      {...register("userId")}
                      style={InputBorderStyle}
                      readOnly
                    />
                  </InputWrap>
                </Container>
              </ItemWrap>
            </InfoContent>
          </InfoUnit>
          <InfoUnit>
            <InfoTitleWrap>
              <InfoTitle>사업자용 회원</InfoTitle>
            </InfoTitleWrap>
            <InfoContent>
              <ItemWrap>
                <ItemTitle>사업자명*</ItemTitle>
                <ItemInput
                  type="text"
                  {...register("businessName", { required: true })}
                  maxLength={50}
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>개업일자*</ItemTitle>
                <ItemInput type="text" {...register("openingDate")} disabled />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>사업자 등록번호</ItemTitle>
                <ItemInput
                  type="text"
                  {...register("companyRegistrationNumber")}
                  disabled
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>업태*</ItemTitle>
                <Controller
                  name="sectorId"
                  control={control}
                  rules={{
                    required: "업태를 확인해주세요.",
                  }}
                  render={({ field }) => (
                    <ConfigProvider
                      theme={{
                        components: {
                          Select: {
                            optionFontSize: 16,
                          },
                        },
                      }}
                    >
                      <SelectSectorId
                        {...field}
                        showSearch
                        placeholder="업태를 선택해주세요"
                        optionFilterProp="children"
                        onChange={(value) => field.onChange(value)}
                        onSearch={() => {}}
                        filterOption={filterOption}
                        options={sectorIdList?.map(
                          ({ id, name }: SectorId) => ({
                            label: name,
                            value: id,
                          })
                        )}
                        style={{ height: "44px", flex: "1 1 0" }}
                        tabIndex={10}
                      />
                    </ConfigProvider>
                  )}
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>종목*</ItemTitle>
                <ItemInput
                  type="text"
                  {...register("involvedIn", { required: true })}
                  maxLength={20}
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>담당자명*</ItemTitle>
                <ItemInput
                  type="text"
                  {...register("representativeName", {
                    validate: (value) =>
                      String(value).length >= 2 && String(value).length <= 20,
                  })}
                  minLength={2}
                  maxLength={20}
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>법인 이메일*</ItemTitle>
                <ItemInput
                  type="email"
                  {...register("companyEmail")}
                  disabled
                />
              </ItemWrap>
              <ItemWrap>
                <ItemTitle>연락처*</ItemTitle>
                <ItemInput
                  type="tel"
                  {...register("phoneNumber", { required: true })}
                  onChange={({ target: { value } }) =>
                    setValue("phoneNumber", changeAllContactForm(value))
                  }
                  maxLength={13}
                />
              </ItemWrap>
              <Address>
                <AddressTitle>주소*</AddressTitle>
                <Container>
                  <InputWrap>
                    <AddressBasicInput
                      type="text"
                      {...register("address", {
                        required: true,
                      })}
                    />
                    <CheckOrSearchButton type="button" onClick={onToggleModal}>
                      <CheckOrSearchButtonText>
                        주소 찾기
                      </CheckOrSearchButtonText>
                    </CheckOrSearchButton>
                  </InputWrap>
                  <InputWrap>
                    <AddressDetailInput
                      type="text"
                      {...register("addressDetail", { required: true })}
                    />
                  </InputWrap>
                </Container>
              </Address>
            </InfoContent>
          </InfoUnit>
        </InfoMain>
        <ButtonWrap>
          <InfoButton
            type="button"
            border=""
            background="#EEE"
            onClick={() => navigate("/mypage")}
          >
            <InfoButtonText color="#ACB1BA">취소</InfoButtonText>
          </InfoButton>
          <InfoButton
            type="submit"
            border={isValid ? "Main" : ""}
            background={isValid ? "Main" : ""}
          >
            <InfoButtonText color={isValid ? "#fff" : "#ACB1BA"}>
              변경사항 저장
            </InfoButtonText>
          </InfoButton>
        </ButtonWrap>
      </Form>
      {isOpen && (
        <Modal
          open={true}
          footer={null}
          onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
          width={550}
          bodyStyle={{ padding: "15px" }}
        >
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </Modal>
      )}
      {isPopUpOpen && (
        <Popup
          info={"변경된 내용을 저장하시겠습니까?"}
          onCancel={onTogglePopUp}
          onOk={() => {
            const data = JSON.parse(
              window.localStorage.getItem("mypageInfo") ?? "{}"
            );
            const {
              businessName,
              sectorId,
              involvedIn,
              representativeName,
              phoneNumber,
              address,
              addressDetail,
            } = data;

            // 서버에서 저장된 업종값을 한글 그대로 받기 때문에 변환해야함
            // 그 조건을 sectorId?.length >= 3 으로 함(string 21은 length가 2)
            let newSectorId: number = 0;
            if (sectorId?.length !== undefined && sectorId?.length >= 3) {
              sectorIdList.forEach((item: SectorId) => {
                if (item["name"] === sectorId) {
                  newSectorId = item["id"];
                }
              });
            } else {
              newSectorId = Number(sectorId);
            }

            requestModify.mutate({
              id: userDBId,
              businessName,
              sectorId: String(newSectorId),
              involvedIn,
              representativeName,
              phoneNumber,
              address,
              addressDetail,
            });
            onTogglePopUp();
          }}
        />
      )}
    </>
  );
}
