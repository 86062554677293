import styled from "styled-components";

export const Wrap = styled.div`
  padding: 20px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  background: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Subject = styled.div`
  display: flex;
  width: 381px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
`;

export const SubjectText = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const SubjectDesc = styled(Subject)``;

export const SubjectDescText = styled(SubjectText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const LoadFromUser = styled.div`
  display: flex;
  width: 469px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const LoadFromUserTitle = styled.div`
  display: flex;
  width: 363px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const LoadFromUesrTitleText = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const LoadFromUserCheckWrap = styled.div`
  width: 363px;
  height: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const LoadFromuserCheck = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
`;

export const LoadFromUserCheckText = styled.span`
  flex: 1 0 0;

  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
`;

export const InputTitle = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const Input = styled.input.attrs({
  type: "text",
})`
  display: flex;
  height: 30px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;

  &::placeholder {
    color: var(--gray-300);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;

export const RadioWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
`;

export const RadioItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  gap: 10px;
  align-self: stretch;
`;

export const RadioButton = styled.input`
  appearance: none;
  border: 0.2em solid gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:checked {
    border: 0.3em solid var(--Main);
  }
  cursor: pointer;
`;

export const RadioText = styled.span`
  color: var(--gray-900);
  position: relative;
  top: 2px;

  font-family: Pretendard;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
`;

export const ExtraMessage = styled.textarea`
  display: flex;
  height: 107px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;

  &::placeholder {
    color: var(--button-gray);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
`;
