import React from "react";
import { Button, ConfirmGuideText, Container, InputWrap } from "./CommonStyles";

interface propsType {
  onNext: () => void;
  id: string;
}

export default function IDShow({ onNext, id }: propsType) {
  return (
    <Container gap="3.75rem">
      <InputWrap>
        <ConfirmGuideText>가입하신 ID는 [{id}] 입니다</ConfirmGuideText>
      </InputWrap>
      <Button onClick={onNext}>비밀번호 재설정</Button>
    </Container>
  );
}
