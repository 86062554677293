import React, { useState } from "react";
import AppHeader from "../../../components/Header";
import styled from "styled-components";
import { Wrap, Container } from "./styles";
import { ReactComponent as Logo } from "../../../assets/login/img-login-logo.svg";
import SignupForm from "../../../components/Auth/common/signupForm/signupForm";
import PhoneConfirm from "../../../components/Auth/common/phoneConfirm";
import { useRecoilState } from "recoil";
import { PhoneNumberState } from "../../../recoil/auth";
import SignUpPhoneInput from "../../../components/Auth/common/signupPhoneInput";

export default function SignupCommon() {
  const [step, setStep] = useState<"휴대폰인증" | "본인인증" | "가입양식">(
    "휴대폰인증"
  );
  const [verifyCode, setVerifyCode] = useState<number>();
  const [, setPhoneNumber] = useRecoilState(PhoneNumberState);

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container step={step}>
          <Logo width={151} height={38} />
          <Show condition={step === "휴대폰인증"}>
            <SignUpPhoneInput
              onNext={(verifyCode: number, phoneNumber: string) => {
                setVerifyCode(verifyCode);
                setStep("본인인증");
                setPhoneNumber(phoneNumber);
              }}
            />
          </Show>
          <Show condition={step === "본인인증"}>
            <PhoneConfirm
              verifyCode={verifyCode}
              onNext={() => setStep("가입양식")}
              purpose="sign-up"
            />
          </Show>
          <Show condition={step === "가입양식"}>
            <SignupForm />
          </Show>
        </Container>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

interface showPropsType {
  condition: boolean;
  children: any;
}

function Show({ condition, children }: showPropsType) {
  return condition === true ? children : null;
}
