import React, { useEffect, useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  FilterAndDelete,
  FilterText,
  FilterCheckbox,
  Filter,
  Delete,
  DeleteText,
  CartItem,
  CartItemWrap,
  CheckButton,
  CartDetail,
  CartItemInfo,
  ItemStatusWrap,
  ItemStatus,
  ItemSummary,
  ItemText,
  CartWrap,
  CartPolicy,
  Calculation,
  CalContent,
  CalProductPrice,
  CalPlus,
  CalDeliveryPrice,
  CalEqual,
  CalFinalPrice,
  CalTitleText,
  CartPolicyText,
  CalValueText,
  BuyWrap,
  Buy,
  BuyText,
  NoItemWrapper,
  NoItemText,
  NoItemButton,
} from "./styles";

// import PopularItem01 from "../../assets/content/popularItem/img-content-popularItem-01.png";
import { useNavigate } from "react-router-dom";
import { UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";
import useDeleteCart from "../../hooks/cart/useDeleteCart";
import { changeCommaFormat } from "../../utils/utility";
import { CartProduct } from "../../hooks/cart/useGetCart";
import { axiosAuthClient } from "../../apis/general";
import {
  CartProductIdsState,
  PayPathState,
  PayProductIdsState,
} from "../../recoil/pay";
import { SelectedCategoryIdState } from "../../recoil/home";

export default function Cart() {
  const navigate = useNavigate();
  const [userId] = useRecoilState(UserDBIdState);
  const [excludeCompletedProduct, setExcludeCompletedProduct] = useState(false);
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set<number>());
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const requestDelete = useDeleteCart();
  const [, setPayProductIds] = useRecoilState(PayProductIdsState);
  const [, setCartProductIds] = useRecoilState(CartProductIdsState);
  const [, setPayPath] = useRecoilState(PayPathState);
  const [, setSelectedCategoryId] = useRecoilState(SelectedCategoryIdState);

  const updateSet = (set: Set<number>, id: number) => {
    const updatedSet = new Set(set);
    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    } else {
      updatedSet.add(id);
    }
    return updatedSet;
  };

  const handleOnChange = (id: number) => {
    setCheckedIdsSet((prevSet: Set<number>) => updateSet(prevSet, id));
  };

  useEffect(() => {
    const getCart = async (
      userId: number,
      excludeCompletedProduct: boolean
    ) => {
      try {
        let {
          data: {
            result: { cartProducts },
          },
        } = await axiosAuthClient.get(`/web/carts?userId=${userId}`);
        // 최신 추가순으로 재정렬
        cartProducts = cartProducts.sort((a: CartProduct, b: CartProduct) => {
          return b.cartProductId - a.cartProductId;
        });
        // 거래 완료 상품 제외
        if (excludeCompletedProduct) {
          cartProducts = cartProducts.filter(
            (item: CartProduct) => item.purchaseStatus === "판매중"
          );
        }
        // 판매 완료 아닌 것 모두 체크하기
        let checkedList = new Set<number>();
        cartProducts.forEach((item: CartProduct) => {
          if (item.purchaseStatus !== "판매 완료") {
            // 거래완료 상품 제외일땐 item.purchaseStatus !== "판매 완료" 조건 없어도 됨
            // 현재 로직이 떠오르지 않아 이렇게 처리
            checkedList.add(item.cartProductId);
          }
        });
        setCheckedIdsSet(checkedList);
        setCartProducts(cartProducts);
      } catch (error) {
        console.error(error);
      }
    };
    getCart(userId, excludeCompletedProduct);
  }, [excludeCompletedProduct]);

  useEffect(() => {
    const sum = cartProducts
      ?.filter((item: CartProduct) => checkedIdsSet.has(item.cartProductId))
      .reduce((prev: number, cur: CartProduct) => {
        return prev + (cur.price + cur.brokerageFee);
      }, 0);
    setTotalPrice(sum);
    const deliveryFeeSum = cartProducts
      ?.filter((item: CartProduct) => checkedIdsSet.has(item.cartProductId))
      .reduce((prev: number, cur: CartProduct) => {
        return prev + cur.expectedDeliveryFee;
      }, 0);
    setDeliveryFee(deliveryFeeSum);
  }, [cartProducts, checkedIdsSet]);

  const handleDelete = () => {
    if (confirm("선택한 상품(들)을 장바구니에서 삭제하시겠습니까?")) {
      requestDelete.mutate({
        cartId: cartProducts[0]?.cartId,
        cartProductIds: [...checkedIdsSet],
      });
    }
  };

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>장바구니</Title>
          </TitleWrap>
          {cartProducts && cartProducts.length > 0 && (
            <>
              <FilterAndDelete>
                <Filter>
                  <FilterText>거래 완료 상품 제외</FilterText>
                  <FilterCheckbox
                    checked={excludeCompletedProduct}
                    onChange={() =>
                      setExcludeCompletedProduct(!excludeCompletedProduct)
                    }
                  />
                </Filter>
                <Delete
                  onClick={handleDelete}
                  disabled={checkedIdsSet.size === 0}
                >
                  <DeleteText disabled={checkedIdsSet.size === 0}>
                    삭제
                  </DeleteText>
                </Delete>
              </FilterAndDelete>
              <CartWrap>
                {cartProducts?.map(
                  ({
                    cartProductId,
                    productName,
                    price,
                    brokerageFee,
                    purchaseStatus,
                    productImageUrl,
                  }: CartProduct) => (
                    <CartItemWrap key={cartProductId}>
                      <CartItem>
                        <CheckButton
                          checked={checkedIdsSet.has(cartProductId)}
                          onChange={() => handleOnChange(cartProductId)}
                        />
                        <CartDetail>
                          <ImgWrap>
                            <img
                              src={productImageUrl}
                              alt="장바구니 상품"
                              width={193}
                              height={145}
                              loading="lazy"
                              style={{
                                borderRadius: "8px",
                                opacity:
                                  purchaseStatus !== "판매중" ? "0.4" : "1.0",
                              }}
                            />
                          </ImgWrap>
                          <CartItemInfo>
                            <ItemStatusWrap
                              borderColor={
                                purchaseStatus !== "판매중"
                                  ? "#979797"
                                  : "#009ee2"
                              }
                            >
                              <ItemStatus
                                color={
                                  purchaseStatus !== "판매중"
                                    ? "#979797"
                                    : "#009ee2"
                                }
                              >
                                {purchaseStatus}
                              </ItemStatus>
                            </ItemStatusWrap>
                            <ItemSummary>
                              <ItemText
                                style={{
                                  color:
                                    purchaseStatus !== "판매중"
                                      ? "#979797"
                                      : "#212529",
                                }}
                              >
                                {productName}
                              </ItemText>
                              <ItemText
                                style={{
                                  color:
                                    purchaseStatus !== "판매중"
                                      ? "#979797"
                                      : "#212529",
                                }}
                              >
                                {changeCommaFormat(price + brokerageFee)}원
                              </ItemText>
                            </ItemSummary>
                          </CartItemInfo>
                        </CartDetail>
                      </CartItem>
                    </CartItemWrap>
                  )
                )}
                <CartPolicy>
                  <CartPolicyText>
                    장바구니 내 상품은 최대 30일까지 보관됩니다.
                  </CartPolicyText>
                </CartPolicy>
                <Calculation>
                  <CalContent>
                    <CalProductPrice>
                      <CalTitleText>상품 가격</CalTitleText>
                    </CalProductPrice>
                    <CalPlus>
                      <CalTitleText></CalTitleText>
                    </CalPlus>
                    <CalDeliveryPrice>
                      <CalTitleText>배송비</CalTitleText>
                    </CalDeliveryPrice>
                    <CalEqual></CalEqual>
                    <CalFinalPrice>
                      <CalTitleText>총 결제 가격</CalTitleText>
                    </CalFinalPrice>
                  </CalContent>
                  <CalContent>
                    <CalProductPrice>
                      <CalValueText>
                        {changeCommaFormat(totalPrice)}원
                      </CalValueText>
                    </CalProductPrice>
                    <CalPlus>
                      <CalValueText>+</CalValueText>
                    </CalPlus>
                    <CalDeliveryPrice>
                      <CalValueText>
                        {changeCommaFormat(deliveryFee)}원
                      </CalValueText>
                    </CalDeliveryPrice>
                    <CalEqual>
                      <CalValueText>=</CalValueText>
                    </CalEqual>
                    <CalFinalPrice>
                      <CalValueText finalPrice>
                        {changeCommaFormat(totalPrice + deliveryFee)}원
                      </CalValueText>
                    </CalFinalPrice>
                  </CalContent>
                </Calculation>
              </CartWrap>
              <BuyWrap>
                <Buy
                  onClick={() => {
                    setCartProductIds([...checkedIdsSet]);
                    setPayProductIds(
                      cartProducts
                        ?.filter((item: CartProduct) =>
                          checkedIdsSet.has(item.cartProductId)
                        )
                        .map((e) => e.productId)
                    );
                    setPayPath("cart");
                    navigate("/pay", { state: { path: "cart" } });
                  }}
                  disabled={
                    checkedIdsSet.size === 0 ||
                    Boolean(
                      cartProducts
                        ?.filter((item: CartProduct) =>
                          checkedIdsSet.has(item.cartProductId)
                        )
                        .find((e) => e.purchaseStatus !== "판매중")
                    )
                  }
                >
                  <BuyText
                    disabled={
                      checkedIdsSet.size === 0 ||
                      Boolean(
                        cartProducts
                          ?.filter((item: CartProduct) =>
                            checkedIdsSet.has(item.cartProductId)
                          )
                          .find((e) => e.purchaseStatus !== "판매중")
                      )
                    }
                  >
                    상품 구매하기
                  </BuyText>
                </Buy>
              </BuyWrap>
            </>
          )}
          {(!cartProducts || cartProducts.length === 0) && (
            <NoItemWrapper>
              <NoItemText>장바구니에 담긴 상품이 없습니다</NoItemText>
              <NoItemButton
                onClick={() => {
                  setSelectedCategoryId("");
                  navigate("/productinquiry");
                }}
              >
                상품 보러가기
              </NoItemButton>
            </NoItemWrapper>
          )}
        </Content>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgWrap = styled.div`
  width: 193px;
  height: 145px;
`;
