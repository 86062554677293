import styled from "styled-components";
import ArrowIcon from "../../assets/content/sell/ic-content-sell-arrow.png";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const PayWrapForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const CommonText = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const InfoCommonWrap = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  border-radius: 8px;
  background: #f8f8f8;
`;

export const InfoTitleWrap = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const InfoTitle = styled(CommonText)``;

export const ProductInfo = styled(InfoCommonWrap)``;

export const PriceInfo = styled(InfoCommonWrap)``;

export const PayMethodInfo = styled(InfoCommonWrap)``;

export const ProcessInfo = styled(InfoCommonWrap)`
  justify-content: center;
  align-items: center;
  background: #e5f7ff;
`;

export const BuyerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
`;

export const PIContent = styled.div`
  display: flex;
  width: 460px;
  align-items: flex-start;
  gap: 20px;
`;

export const PIContentSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const PIName = styled(CommonText)``;

export const PIPrice = styled(CommonText)`
  font-size: 16px;
`;

export const PriceInfoContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
`;

export const PriceInfoContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ItemPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const ItemPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const DeliveryPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const DeliveryPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 500;
`;

export const TotalPriceTitle = styled(CommonText)`
  font-size: 18px;
  font-weight: 700;
`;

export const TotalPriceValue = styled(CommonText)`
  font-size: 18px;
  font-weight: 700;
`;

export const PayMethodContent = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 10px;
  border: 1px solid #dfe3e6;
  background: #fff;
`;

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const EasyPay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const CommonPay = styled(EasyPay)``;

export const Line = styled.hr`
  width: 860px;
  height: 1px;
  background: #f3f7fb;
`;

export const ExchangeAndRefund = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const BottomInfoTitleWrap = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const BottomInfoTitle = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
`;

export const ExchangeAndRefundContent = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Explanation = styled.span<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : "#5f6368")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

export const EasyPayContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const EasyPayDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 24px; */
  flex: 1 0 0;
`;

export const EasyPayTitle = styled(CommonText)`
  font-size: 18px;
  line-height: 24px;
`;

export const CommonPayContent = styled(EasyPayContent)``;

export const CommonPayDesc = styled(EasyPayDesc)``;

export const CommonPayTitle = styled(CommonText)`
  font-size: 18px;
  line-height: 24px;
`;

export const EasyPayTermWrap = styled.div`
  display: flex;
  gap: 10px;
`;

export const EasyPayTerm = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
`;

export const EasyPayTermDetail = styled(EasyPayTerm)`
  text-decoration-line: underline;
  cursor: pointer;
`;

export const EasyPayCheckboxWrap = styled.div`
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const EasyPayCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EasyPaySummary = styled(CommonText)`
  color: #8b97a2;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
`;

export const EasyPayAddButton = styled(Button)`
  display: flex;
  height: 32px;
  padding: 0px 14px;
  align-items: center;
  gap: 8px;

  border-radius: 30px;
  border: 1px solid #dfe3e6;
`;

export const EasyPayCardWrap = styled.div`
  display: flex;
  padding: 0px 32px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const EasyPayCard = styled.div`
  display: flex;
  width: 270px;
  height: 150px;
  padding: 40px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 10px;
  border: 1px solid #acb1ba;
  background: #f8f8f8;
`;

export const EasyPayCardComment = styled.span`
  color: #212529;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;

export const CPCheckboxWrap = styled.div`
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: flex-start;
`;

export const CPCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CPTitle = styled(CommonText)`
  font-size: 18px;
  line-height: 24px;
`;

export const ProcessInfoTitleWrap = styled(InfoTitleWrap)`
  width: 100%;
  justify-content: flex-start;
`;

export const ProcessInfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Step = styled.div`
  display: flex;
  height: 142px;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #b3b3b3;
  background: #fff;
`;

export const StepContent = styled.div`
  display: flex;
  height: 103px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const StepTextWrap = styled.div`
  display: flex;
  width: 74px;
  height: 45px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

export const StepText = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const BuyerDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  align-self: stretch;
`;

export const PayButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  height: 48px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  border: ${(props) => (props.disabled ? "none" : "1px solid #009ee2")};
  background: ${(props) => (props.disabled ? "#EEE" : "#009ee2")};
`;

export const PayButtonText = styled.span<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? "var(--button-gray)" : "#FFF")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
`;

export const BuyerBasicDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const BuyerBasicDetailContent = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const LoadFromUser = styled.div`
  display: flex;
  width: 338px;
  align-items: center;
  gap: 10px;
`;

export const BuyerName = styled.div`
  display: flex;
  width: 461px;
  align-items: center;
  gap: 20px;
`;

export const BuyerContact = styled.div`
  display: flex;
  width: 460px;
  align-items: center;
  gap: 20px;
`;

export const BuyerAddress = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const LoadFromUserCheckboxWrap = styled.div`
  display: flex;
  height: 23px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const LoadFromUserCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadFromUserText = styled(CommonText)`
  font-size: 16px;
  font-weight: 500;
`;

export const BuyerNameTitleWrap = styled.div`
  width: 81px;
  height: 22px;
  flex-shrink: 0;
`;

export const BuyerCommonTitle = styled(CommonText)`
  color: #5f6268;
  font-size: 16px;
  font-weight: 500;
`;

export const BuyerCommonInput = styled.input.attrs({
  type: "text",
})`
  padding: 10px 16px;
  flex: 1 0 0;
  line-height: 22px; /* 137.5% */
  font-size: 16px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;

  &::placeholder {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
`;

export const BuyerNameTitle = styled(BuyerCommonTitle)``;

export const BuyerNameInput = styled(BuyerCommonInput)``;

export const BuyerContactTitle = styled(BuyerCommonTitle)`
  width: 80px;
`;

export const BuyerContactInput = styled(BuyerCommonInput)``;

export const BuyerAddressTitle = styled(BuyerCommonTitle)`
  width: 80px;
`;

export const BuyerAddressWrap = styled.div`
  display: flex;
  width: 768px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const BuyerAddressInputWrap = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const BuyerAddressInput1 = styled(BuyerCommonInput)`
  width: 563px;
  flex: none;
  box-sizing: border-box;
`;

export const BuyerAddressInput2 = styled(BuyerCommonInput)`
  width: 563px;
  flex: 1 0 0;
  box-sizing: border-box;
`;

export const SearchAddressButton = styled(Button)`
  display: flex;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #009ee2;
`;

export const SearchAddressText = styled.span`
  color: #009ee2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
`;

export const DeliveryMessage = styled.textarea`
  display: flex;
  height: 126px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 1px solid #d8d8d8;
  font-size: 16px;

  &::placeholder {
    color: #b3b3b3;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
`;

export const NormalWork = styled.div`
  display: flex;
  width: 506px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const NormalWorkSelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const CashReceiptSelectWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const OptionText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  top: 2px;
`;

export const RadioCheckBox = styled.input`
  appearance: none;
  border: 0.2em solid gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:checked {
    border: 0.3em solid var(--Main);
  }
  cursor: pointer;
`;

export const EasyPayTypeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const EasyPayTypeButton = styled.button<{ select: boolean }>`
  display: flex;
  width: 192px;
  height: 46px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  border: 2px solid ${(props) => (props.select ? "#009ee2" : "#B5BDC4")};
  background: #fff;
  cursor: pointer;

  color: ${(props) => (props.select ? "#009ee2" : "#5F6368")};
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CommonPayTypeWrap = styled(EasyPayTypeWrap)`
  flex-wrap: nowrap;
`;

export const CommonPayTypeButton = styled(EasyPayTypeButton)`
  width: 263px;
`;

export const BuyerAgreement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const BuyerAgreementTermContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const BuyerAgreementTermWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const BuyerAgreementTerm = styled(EasyPayTerm)<{ color: string }>`
  color: ${(props) => props.color && props.color};
`;

export const BuyerAgreementTermDetail = styled(EasyPayTermDetail)``;

export const CommonPayContentByType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const LoadPhoneNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const LoadText = styled.span`
  color: #5f6368;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
`;

export const CashReceiptInputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CashReceiptInputTitle = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
`;

export const CashReceiptInput = styled(BuyerCommonInput)`
  width: 377px;
  height: 15px;
`;

export const CommonSelect = styled.select`
  display: flex;
  width: 190px;
  padding: 8px 16px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 8px;
  border: 1px solid #dfe2e9;
  background: #fff;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;

  color: #8b97a2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const PlusIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 6.667px;
  box-sizing: border-box;
  color: #009ee2;
  font-size: 20px;

  border-radius: 30px;
  background: #fff;
`;
