import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";

interface returnType {
  code: number;
  isSuccess: boolean;
  message: string;
  result: {
    productConditions: { id: number; condition: string }[];
  };
}

export const getPrdCondition = async (): Promise<returnType> => {
  const { data } = await axiosAuthClient.get(`/web/products/conditions`);
  return data;
};

export default function useGetPrdCondition() {
  return useQuery(["getPrdCondition"], getPrdCondition, {
    onError: (err) => alert(err),
    select: (res) => res.result.productConditions,
  });
}
