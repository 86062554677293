import React, { useState } from "react";
import AppHeader from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  JwtState,
  LoginState,
  UserDBIdState,
  UserBizState,
} from "../../recoil/login";
import styled from "styled-components";
import {
  Button,
  ButtonWrap,
  Content,
  Input,
  InputContainer,
  InputTitle,
  InputWrap,
  LoginButton,
  LoginButtonText,
  Main,
  Wrap,
} from "./styles";
import { ReactComponent as Logo } from "../../assets/login/img-login-logo.svg";
import { ReactComponent as PWView } from "../../assets/login/ic-login-pw-view.svg";
import { ReactComponent as PWHide } from "../../assets/login/ic-login-pw-hide.svg";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";
import useLoginStates, { loginInfo } from "../../hooks/login/useLoginStates";
import { JWT_KEY } from "../../config/constant";
import Comment from "../../components/Auth/Comment";
import { isIDValid, isPasswordValid } from "../../utils/utility";

export default function Login() {
  const navigate = useNavigate();
  const [showPW, setShowPW] = useState<boolean>(false);
  const [, setIsLoggedIn] = useRecoilState(LoginState);
  const [, setJwt] = useRecoilState(JwtState);
  const [, setUserDBId] = useRecoilState(UserDBIdState);
  const [, setUserBiz] = useRecoilState(UserBizState);
  const {
    state: { userId, password },
    action,
  } = useLoginStates();

  const [idWarn, setIdWarn] = useState(false);
  const [idWarnContent, setIdWarnContent] = useState("");
  const [passwordWarn, setPasswordWarn] = useState(false);
  const [passwordWarnContent, setPasswordWarnContent] = useState("");

  const AllInputsFilled = userId.length !== 0 && password.length !== 0;

  const requestLogin = useMutation({
    mutationFn: (param: loginInfo) =>
      axiosClient.post("/web/auth/sign-in", param),
    onSuccess: ({ data }) => {
      const { code } = data;
      if (code === 1000) {
        const {
          result: { jwt, id, isBiz },
        } = data;
        setIsLoggedIn(true);
        setJwt(jwt);
        setUserDBId(id);
        setUserBiz(isBiz);
        localStorage.setItem(JWT_KEY, jwt);
        navigate("/productinquiry");
      }
      if (code === 2156) {
        setIdWarn(true);
        setIdWarnContent("존재하지 않는 아이디입니다.");
      }
      if (code === 2157) {
        setPasswordWarn(true);
        setPasswordWarnContent("비밀번호를 확인해주세요.");
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const onRequestLogin = (): void => {
    if (!isIDValid(userId)) {
      setIdWarn(true);
      setIdWarnContent("아이디 형식이 올바르지 않습니다.");
      return;
    } else {
      setIdWarn(false);
    }
    if (!isPasswordValid(password)) {
      setPasswordWarn(true);
      setPasswordWarnContent("비밀번호 형식이 올바르지 않습니다.");
    } else {
      setPasswordWarn(false);
    }
    requestLogin.mutate({ userId, password });
  };

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Logo width={151} height={38} />
        <Content>
          <Main>
            <InputContainer>
              <InputWrap>
                <InputTitle>아이디</InputTitle>
                <Input
                  type="text"
                  value={userId}
                  onChange={(e) =>
                    action({ type: "userId", payload: e.target.value })
                  }
                  tabIndex={1}
                  placeholder="ID를 입력해주세요."
                  minLength={8}
                  maxLength={20}
                />
              </InputWrap>
              <Comment show={idWarn} content={idWarnContent} />
              <InputWrap>
                <InputTitle>비밀번호</InputTitle>
                <Input
                  type={showPW ? "text" : "password"}
                  value={password}
                  pw="pw"
                  onChange={(e) =>
                    action({ type: "password", payload: e.target.value })
                  }
                  tabIndex={2}
                  placeholder="비밀번호를 입력해주세요."
                  minLength={8}
                  maxLength={20}
                />
                {showPW ? (
                  <div style={{ position: "absolute", left: "465px" }}>
                    <PWView onClick={() => setShowPW(false)} />
                  </div>
                ) : (
                  <div style={{ position: "absolute", left: "465px" }}>
                    <PWHide onClick={() => setShowPW(true)} />
                  </div>
                )}
              </InputWrap>
              <Comment show={passwordWarn} content={passwordWarnContent} />
            </InputContainer>
            <LoginButton
              disabled={!AllInputsFilled}
              isActive={AllInputsFilled}
              onClick={onRequestLogin}
              tabIndex={3}
            >
              <LoginButtonText isActive={AllInputsFilled}>
                로그인
              </LoginButtonText>
            </LoginButton>
          </Main>
          <ButtonWrap>
            <Link to="/findid">
              <Button tabIndex={4}>ID 찾기</Button>
            </Link>
            <Link to="/findpw">
              <Button tabIndex={5}>PW 찾기</Button>
            </Link>
            <Link to="/signup">
              <Button tabIndex={6}>가입하기</Button>
            </Link>
          </ButtonWrap>
        </Content>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
