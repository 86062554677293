import React from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import {
  Button,
  Container,
  Root,
  Text,
  Title,
  Wrap,
} from "../payments_success";
import { PayPathState } from "../../recoil/pay";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

export default function PaymentsFail() {
  const navigate = useNavigate();
  // 장바구니, 상품조회에서 이동시 경로를 atom에 저장
  const [payPath] = useRecoilState(PayPathState);
  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container>
          <Title>결제 오류</Title>
          <Text>결제가 완료되지 않았어요</Text>
          <Button
            onClick={() => navigate("/pay", { state: { path: payPath } })}
          >
            결제 화면 이동
          </Button>
        </Container>
      </Wrap>
      <AppFooter />
    </Root>
  );
}
