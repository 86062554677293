import React, { useEffect, useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  ProductInfo,
  PriceInfo,
  PayMethodInfo,
  ProcessInfo,
  BuyerInfo,
  InfoTitleWrap,
  InfoTitle,
  PIContent,
  PIContentSummary,
  PIName,
  PIPrice,
  PriceInfoContent,
  PriceInfoContentWrap,
  ItemPriceTitle,
  ItemPriceValue,
  DeliveryPriceTitle,
  DeliveryPriceValue,
  TotalPriceTitle,
  TotalPriceValue,
  PayMethodContent,
  EasyPay,
  Line,
  // CommonPay,
  EasyPayContent,
  EasyPayDesc,
  EasyPayTitle,
  // EasyPayCardWrap,
  // EasyPayCard,
  // EasyPayCardComment,
  // CPCheckboxWrap,
  CPCheckbox,
  // CPTitle,
  Step,
  StepContent,
  StepTextWrap,
  StepText,
  ProcessInfoTitleWrap,
  ProcessInfoContent,
  BuyerDetail,
  PayButton,
  PayButtonText,
  NormalWork,
  // NormalWorkSelectWrap,
  Option,
  RadioCheckBox,
  OptionText,
  EasyPayTypeButton,
  EasyPayTypeWrap,
  EasyPayTermWrap,
  EasyPayTerm,
  EasyPayTermDetail,
  ExchangeAndRefund,
  BottomInfo,
  BottomInfoTitle,
  BottomInfoTitleWrap,
  ExchangeAndRefundContent,
  Explanation,
  BuyerAgreement,
  BuyerAgreementTermWrap,
  BuyerAgreementTerm,
  BuyerAgreementTermContainer,
  BuyerAgreementTermDetail,
  CommonPay,
  CommonPayContent,
  CommonPayDesc,
  CommonPayTitle,
  CommonPayTypeWrap,
  CommonPayTypeButton,
  CommonPayContentByType,
  CommonSelect,
  BuyerBasicDetail,
  BuyerBasicDetailContent,
  BuyerName,
  BuyerContact,
  BuyerAddress,
  LoadFromUser,
  LoadFromUserCheckbox,
  LoadFromUserText,
  LoadFromUserCheckboxWrap,
  BuyerNameTitle,
  BuyerNameInput,
  BuyerContactInput,
  BuyerContactTitle,
  BuyerAddressTitle,
  BuyerAddressInputWrap,
  BuyerAddressWrap,
  SearchAddressButton,
  SearchAddressText,
  BuyerAddressInput1,
  BuyerAddressInput2,
  DeliveryMessage,
  PayWrapForm,
  EasyPayCardComment,
  EasyPayCard,
  EasyPayCardWrap,
  EasyPaySummary,
  PlusIcon,
  CashReceiptSelectWrap,
  LoadPhoneNum,
  EasyPayCheckbox,
  LoadText,
  CashReceiptInputWrap,
  CashReceiptInputTitle,
  CashReceiptInput,
} from "./styles";

import { ReactComponent as Logo } from "../../assets/header/img-header-logo.svg";
// import EasyPayCardAddImg from "../../assets/content/pay/img-pay-addcard.png";
import ImgVisa from "../../assets/content/pay/img-pay-visa.png";
import PayCompletedImg from "../../assets/content/pay/step/img-pay-step-01.png";
import DeliveryReadyImg from "../../assets/content/pay/step/img-pay-step-02.png";
import DeliveryCompletedImg from "../../assets/content/pay/step/img-pay-step-03.png";
import BuyConfirmImg from "../../assets/content/pay/step/img-pay-step-04.png";
import StepArrowImg from "../../assets/content/pay/step/img-pay-step-arrrow.png";
import { useRecoilState } from "recoil";
import {
  PayOrderIdState,
  PayOrderInfoState,
  PayProductIdState,
  PayProductIdsState,
  CartProductIdsState,
} from "../../recoil/pay";
import {
  CardList,
  changeComRegNumForm,
  changeCommaFormat,
  changePhoneContactForm,
} from "../../utils/utility";
import EasyPayExplanaion from "./easyPayExplanaion";
import { useForm } from "react-hook-form";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import CommonPayExplanaion from "./commonPayExplanaion";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosAuthClient, axiosClient } from "../../apis/general";
import { UserDBIdState } from "../../recoil/login";
import uuid from "react-uuid";
import { Modal } from "antd";
import DaumPostcodeEmbed from "react-daum-postcode";
import { CartProduct } from "../../hooks/cart/useGetCart";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import Popup from "../../components/Popup/common";
// import CommonPayExplanaion2 from "./commonPayExplanaion2";

const EasyPayTypeList = [
  { id: "KAKAOPAY", text: "카카오페이" },
  { id: "NAVERPAY", text: "네이버페이" },
  { id: "SAMSUNGPAY", text: "삼성페이" },
  { id: "APPLEPAY", text: "Apple Pay" },
  { id: "PAYCO", text: "페이코" },
];

const CommonPayTypeList = [
  { id: "creditAndCheck", text: "신용/체크카드" },
  { id: "virtualAccount", text: "가상계좌" },
  { id: "accountTransfer", text: "계좌이체" },
];

const BuyerAgreementTermList = [
  { id: "termSelect_1", text: "[필수] 개인정보 수집 동의" },
  { id: "termSelect_2", text: "[필수] 개인정보 제 3자 이용 동의" },
  { id: "termSelect_3", text: "[필수] 전자결제대행 이용 동의" },
];

const imageStyle = {
  width: "158px",
  height: "104px",
  borderRadius: "6px",
};

export default function Pay() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { path: string };
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const {
    register,
    formState: { isValid },
    setValue,
    watch,
    handleSubmit,
    trigger,
  } = useForm({
    shouldFocusError: false,
  });

  const [, setOrderId] = useRecoilState(PayOrderIdState);
  const [, setOrderInfo] = useRecoilState(PayOrderInfoState);
  const [userId] = useRecoilState(UserDBIdState);
  const [payProductId] = useRecoilState(PayProductIdState);
  const [payProductIds] = useRecoilState(PayProductIdsState);
  const [cartProductIds] = useRecoilState(CartProductIdsState);

  // const { data, isLoading } = useGetProduct(payProductId);
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState(0);
  const [brokerageFee, setBrokerageFee] = useState(0);
  const [expectedDeliveryFee, setExpectedDeliveryFee] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any[]>();
  const [productsPrice, setProductsPrice] = useState(0);
  const [productsDeliveryFee, setProductsDeliveryFee] = useState(0);

  const [selectedEasyPayType, setSelectedEasyPayType] = useState("카카오페이");
  const [selectedCommonPayType, setSelectedCommonPayType] =
    useState("신용/체크카드");
  const [easyPayMethod, setEasyPayMethod] = useState<any>("KAKAOPAY");
  const [cardCompany, setCardCompany] = useState<any>("");
  const [loadCheckbox, setLoadCheckbox] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const clientKey = process.env.REACT_APP_PAYMENTS_CLIENT_KEY || "";
  const [loadComRegNumCheckbox, setLoadComRegNumCheckbox] = useState(false);
  const [loadPhoneNumCheckbox, setLoadPhoneNumCheckbox] = useState(false);

  const toggleCheck = (id: string) => {
    setValue(id, !watch(id));
  };

  const selectAll = (e: any) => {
    if (e.target.checked) {
      setValue("termSelect_1", true);
      setValue("termSelect_2", true);
      setValue("termSelect_3", true);
    } else {
      setValue("termSelect_1", false);
      setValue("termSelect_2", false);
      setValue("termSelect_3", false);
    }
  };

  const loadMyInfo = async (userDBId: number) => {
    try {
      const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
      if (data.code === 1000) {
        const {
          result: {
            user: { name, businessName, phoneNumber, address, addressDetail },
          },
        } = data;
        setValue("name", name || businessName);
        setValue("phoneNumber", phoneNumber);
        setValue("address", address);
        setValue("addressDetail", addressDetail);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    let allChecked = false;
    if (
      Object.values([
        watch("termSelect_1"),
        watch("termSelect_2"),
        watch("termSelect_3"),
      ]).every((item) => item)
    ) {
      allChecked = true;
    }
    setValue("termSelect_all", allChecked);
    trigger();
  }, [watch("termSelect_1"), watch("termSelect_2"), watch("termSelect_3")]);

  useEffect(() => {
    setValue("payMethod", "일반");
    setValue("cashReceiptMethod", "미발행");
    if (locationState.path === "product") {
      const getProduct = async (productId: number) => {
        try {
          setLoading(true);
          const { data: productData } = await axiosClient.get(
            `/web/products/${productId}`
          );
          if (productData.result.product?.purchaseStatus !== "판매중") {
            alert("판매 완료된 상품입니다.");
            navigate(-1);
          } else {
            setProductName(productData.result.product?.productName);
            setPrice(productData.result.product?.price);
            setBrokerageFee(productData.result.product?.brokerageFee);
            setExpectedDeliveryFee(
              productData.result.product?.expectedDeliveryFee
            );
            setProductImages(productData.result.product?.productImages);
            setLoading(false);

            loadMyInfo(userId);
          }
        } catch (error) {
          alert(error);
        }
      };
      getProduct(payProductId);
    } else {
      const getProducts = async (
        userId: number,
        excludeCompletedProduct: boolean
      ) => {
        try {
          setLoading(true);
          let {
            data: {
              result: { cartProducts },
            },
          } = await axiosAuthClient.get(`/web/carts?userId=${userId}`);
          // 최신 추가순으로 재정렬
          cartProducts = cartProducts
            .filter((e: CartProduct) =>
              cartProductIds.includes(e.cartProductId)
            )
            .sort((a: CartProduct, b: CartProduct) => {
              return b.cartProductId - a.cartProductId;
            });
          // 거래 완료 상품 제외
          if (excludeCompletedProduct) {
            cartProducts = cartProducts.filter(
              (item: CartProduct) => item.purchaseStatus === "판매중"
            );
          }

          if (cartProducts.length === 0) {
            alert("판매 완료된 상품입니다.");
            navigate(-1);
          } else {
            setLoading(false);
            setProducts(cartProducts);
            setProductName(
              `${cartProducts[0]?.productName} 외 ${
                cartProducts.length - 1
              }개 상품`
            );
            setProductsPrice(
              cartProducts?.reduce((prev: CartProduct, cur: CartProduct) => {
                return {
                  price: prev?.price + cur?.price,
                };
              })?.price +
                cartProducts?.reduce((prev: CartProduct, cur: CartProduct) => {
                  return {
                    brokerageFee: prev?.brokerageFee + cur?.brokerageFee,
                  };
                })?.brokerageFee
            );
            setProductsDeliveryFee(
              cartProducts?.reduce((prev: CartProduct, cur: CartProduct) => ({
                expectedDeliveryFee:
                  prev?.expectedDeliveryFee + cur?.expectedDeliveryFee,
              }))?.expectedDeliveryFee
            );

            loadMyInfo(userId);
          }
        } catch (error) {
          alert(error);
        }
      };
      getProducts(userId, true);
    }
  }, []);

  useDidMountEffect(() => {
    if (loadCheckbox) {
      // const loadMyInfo = async (userDBId: number) => {
      //   try {
      //     const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
      //     if (data.code === 1000) {
      //       const {
      //         result: {
      //           user: {
      //             name,
      //             businessName,
      //             phoneNumber,
      //             address,
      //             addressDetail,
      //           },
      //         },
      //       } = data;
      //       setValue("name", name || businessName);
      //       setValue("phoneNumber", phoneNumber);
      //       setValue("address", address);
      //       setValue("addressDetail", addressDetail);
      //     }
      //   } catch (error) {
      //     alert(error);
      //   }
      // };
      loadMyInfo(userId);
    } else {
      setValue("name", "");
      setValue("phoneNumber", "");
      setValue("address", "");
      setValue("addressDetail", "");
    }
  }, [loadCheckbox]);

  useEffect(() => {
    if (loadComRegNumCheckbox) {
      const loadMyInfo = async (userDBId: number) => {
        try {
          const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
          if (data.code === 1000) {
            const {
              result: {
                user: { companyRegistrationNumber },
              },
            } = data;
            setValue("companyRegNum", companyRegistrationNumber);
            trigger("companyRegNum");
          }
        } catch (error) {
          alert(error);
        }
      };
      loadMyInfo(userId);
    } else {
      setValue("companyRegNum", "");
      trigger("companyRegNum");
    }
  }, [loadComRegNumCheckbox]);

  useEffect(() => {
    if (loadPhoneNumCheckbox) {
      const loadMyInfo = async (userDBId: number) => {
        try {
          const { data } = await axiosAuthClient.get(`/web/users/${userDBId}`);
          if (data.code === 1000) {
            const {
              result: {
                user: { phoneNumber },
              },
            } = data;
            setValue("phoneNum", phoneNumber);
            trigger("phoneNum");
          }
        } catch (error) {
          alert(error);
        }
      };
      loadMyInfo(userId);
    } else {
      setValue("phoneNum", "");
      trigger("phoneNum");
    }
  }, [loadPhoneNumCheckbox]);

  if (loading) {
    return <p>데이터를 불러오는 중입니다.</p>;
  }

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data: any) => {
    // 시.도 저장
    const sido = data.sido;
    // 구.군 저장
    const sigungu =
      data.sigungu.length > 3
        ? data.sigungu.split("").splice(0, 3).join("")
        : data.sigungu;

    const sidoSigungu = `${sido} ${sigungu}`;
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    let splitAddress = data.address.split(" ").splice(2).join(" ");
    setValue("address", sidoSigungu + splitAddress);
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면 됨
  };

  const onSubmit = (data: any): void => {
    if (!isBtnClicked) {
      return;
    }
    setIsBtnClicked(!isBtnClicked);
    const { name, phoneNumber, address, addressDetail, deliveryMessage } = data;
    let { payMethod } = data;

    const payInfo: any = {
      amount:
        locationState.path === "product"
          ? price + brokerageFee + expectedDeliveryFee
          : productsPrice + productsDeliveryFee,
      orderId: uuid(),
      orderName: productName,
      customerName: name,
      // "http://localhost:3000/payments/toss/success",
      successUrl:
        process.env.REACT_APP_ENV === "development"
          ? "https://dev.gipum.net/payments/toss/success"
          : "https://www.gipum.net/payments/toss/success",
      //"http://localhost:3000/pay",
      failUrl:
        process.env.REACT_APP_ENV === "development"
          ? "https://dev.gipum.net/pay"
          : "https://www.gipum.net/pay",
      flowMode: "DIRECT",
    };
    if (payMethod === "간편") {
      payMethod = "카드";
      payInfo["easyPay"] = easyPayMethod;
    }
    if (payMethod === "일반") {
      ["가상계좌", "계좌이체"].includes(selectedCommonPayType)
        ? (payMethod = selectedCommonPayType)
        : (payMethod = "카드");

      if (selectedCommonPayType === "신용/체크카드") {
        if (cardCompany === "") {
          onTogglePopUp();
          return;
        }
        payInfo["cardCompany"] = cardCompany;
      }
      if (selectedCommonPayType === "가상계좌") {
        payInfo["validHours"] = 48;
        payInfo["cashReceipt"] = {
          type: watch("cashReceiptMethod"),
        };
        delete payInfo["flowMode"];
      }
      if (selectedCommonPayType === "계좌이체") {
        payInfo["cashReceipt"] = {
          type: watch("cashReceiptMethod"),
        };
        delete payInfo["flowMode"];
      }
    }

    const SaveOrder = async () => {
      try {
        const res = await axiosAuthClient.post("/web/orders", {
          userId,
          productIds:
            locationState.path === "product" ? [payProductId] : payProductIds,
          totalPrice:
            locationState.path === "product"
              ? price + brokerageFee
              : productsPrice,
          deliveryFee:
            locationState.path === "product"
              ? expectedDeliveryFee
              : productsDeliveryFee,
          buyerName: name,
          buyerPhoneNumber: phoneNumber,
          buyerAddress: address,
          buyerAddressDetail: addressDetail,
          deliveryMessage: deliveryMessage,
        });
        setOrderId(Number(res?.data?.result?.orderId));
        setOrderInfo({
          paymentMethod: selectedCommonPayType,
          cashRecepit: {
            cashRecepitMethod: watch("cashReceiptMethod"),
            companyRegistrationNumber: watch("companyRegNum"),
            phoneNumber: watch("phoneNum"),
          },
        });
        loadTossPayments(clientKey).then((tossPayments) => {
          tossPayments
            .requestPayment(payMethod, payInfo)
            .catch(function (error) {
              if (error.code === "USER_CANCEL") {
                // 결제 고객이 결제창을 닫았을 때 에러 처리
              } else if (error.code === "INVALID_CARD_COMPANY") {
                // 유효하지 않은 카드 코드에 대한 에러 처리
              }
            });
        });
      } catch (error) {
        alert(error);
      }
    };
    SaveOrder();
  };

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <TitleWrap>
            <Title>결제하기</Title>
          </TitleWrap>
          <PayWrapForm onSubmit={handleSubmit(onSubmit)} noValidate>
            {locationState.path === "product" && (
              <ProductInfo>
                <InfoTitleWrap>
                  <InfoTitle>주문 상품 정보</InfoTitle>
                </InfoTitleWrap>
                <PIContent>
                  <img
                    src={productImages?.[0]?.["productImageUrl"]}
                    alt="상품"
                    style={imageStyle}
                  />
                  <PIContentSummary>
                    <PIName>{productName}</PIName>
                    <PIPrice>
                      {changeCommaFormat(price + brokerageFee)}원
                    </PIPrice>
                  </PIContentSummary>
                </PIContent>
              </ProductInfo>
            )}
            {locationState.path === "cart" &&
              products?.map((e) => (
                <ProductInfo key={e.productId}>
                  <InfoTitleWrap>
                    <InfoTitle>주문 상품 정보</InfoTitle>
                  </InfoTitleWrap>
                  <PIContent>
                    <img
                      src={e.productImageUrl}
                      alt="상품"
                      style={imageStyle}
                    />
                    <PIContentSummary>
                      <PIName>{e?.productName}</PIName>
                      <PIPrice>
                        {changeCommaFormat(e.price + e.brokerageFee)}원
                      </PIPrice>
                    </PIContentSummary>
                  </PIContent>
                </ProductInfo>
              ))}
            <PriceInfo>
              <InfoTitleWrap>
                <InfoTitle>결제 금액</InfoTitle>
              </InfoTitleWrap>
              <PriceInfoContentWrap>
                <PriceInfoContent>
                  <ItemPriceTitle>상품 금액</ItemPriceTitle>
                  <ItemPriceValue>
                    +{" "}
                    {locationState.path === "product" &&
                      changeCommaFormat(price + brokerageFee)}
                    {locationState.path === "cart" &&
                      changeCommaFormat(productsPrice)}
                    원
                  </ItemPriceValue>
                </PriceInfoContent>
                <PriceInfoContent>
                  <DeliveryPriceTitle>배송비</DeliveryPriceTitle>
                  <DeliveryPriceValue>
                    +{" "}
                    {locationState.path === "product" &&
                      changeCommaFormat(expectedDeliveryFee)}
                    {locationState.path === "cart" &&
                      changeCommaFormat(productsDeliveryFee)}
                    원
                  </DeliveryPriceValue>
                </PriceInfoContent>
                <PriceInfoContent>
                  <TotalPriceTitle>총 결제 금액</TotalPriceTitle>
                  <TotalPriceValue>
                    {locationState.path === "product" &&
                      changeCommaFormat(
                        price + brokerageFee + expectedDeliveryFee
                      )}
                    {locationState.path === "cart" &&
                      changeCommaFormat(productsPrice + productsDeliveryFee)}
                    원
                  </TotalPriceValue>
                </PriceInfoContent>
              </PriceInfoContentWrap>
            </PriceInfo>
            <PayMethodInfo>
              <InfoTitleWrap>
                <InfoTitle>결제 수단</InfoTitle>
              </InfoTitleWrap>
              {/* <PayMethodContent>
                <NormalWork>
                  <NormalWorkSelectWrap>
                    <Option>
                      <RadioCheckBox
                        type="radio"
                        {...register("payMethod", {
                          required: true,
                        })}
                        value="브랜드페이"
                      />
                      <OptionText>기업의 품격 페이</OptionText>
                    </Option>
                    <Option>
                      <RadioCheckBox
                        type="radio"
                        {...register("payMethod", {
                          required: true,
                        })}
                        value="간편"
                      />
                      <OptionText>간편 결제</OptionText>
                    </Option>
                    <Option>
                      <RadioCheckBox
                        type="radio"
                        {...register("payMethod", {
                          required: true,
                        })}
                        value="일반"
                      />
                      <OptionText>일반 결제</OptionText>
                    </Option>
                  </NormalWorkSelectWrap>
                </NormalWork>
              </PayMethodContent> */}
              <PayMethodContent>
                {watch("payMethod") === "브랜드페이" && (
                  <EasyPay>
                    <EasyPayContent>
                      <EasyPayDesc>
                        <EasyPayTitle>기업의 품격 페이</EasyPayTitle>
                        <EasyPaySummary>
                          결제수단을 등록하시면 간편하게 결제하실 수 있어요
                        </EasyPaySummary>
                      </EasyPayDesc>
                    </EasyPayContent>
                    <EasyPayCardWrap>
                      <EasyPayCard>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Logo width={80} height={20} />
                          <PlusIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.66667 0H6.33333V6.33333H0V7.66667H6.33333V14H7.66667V7.66667H14V6.33333H7.66667V0Z"
                                fill="#009EE2"
                              />
                            </svg>
                          </PlusIcon>
                        </div>
                        <EasyPayCardComment>
                          기업의 품격 결제 전용 카드 추가
                        </EasyPayCardComment>
                      </EasyPayCard>
                      <EasyPayCard>
                        <img src={ImgVisa} width="260" height="140" />
                      </EasyPayCard>
                    </EasyPayCardWrap>
                  </EasyPay>
                )}
                {watch("payMethod") === "간편" && (
                  <EasyPay>
                    <EasyPayContent>
                      <EasyPayDesc>
                        <EasyPayTitle>간편 결제</EasyPayTitle>
                      </EasyPayDesc>
                    </EasyPayContent>
                    <EasyPayTypeWrap>
                      {EasyPayTypeList.map((e) => (
                        <EasyPayTypeButton
                          key={e.id}
                          select={selectedEasyPayType === e.text}
                          onClick={() => {
                            setSelectedEasyPayType(e.text);
                            setEasyPayMethod(e.id);
                          }}
                        >
                          {e.text}
                        </EasyPayTypeButton>
                      ))}
                    </EasyPayTypeWrap>
                    <EasyPayExplanaion type={selectedEasyPayType} />
                    <EasyPayTermWrap>
                      <CPCheckbox
                        {...register("easyPayTerm", {
                          required: watch("payMethod") === "간편" && true,
                        })}
                      />
                      <EasyPayTerm>
                        [필수] 결제 서비스 이용 약관, 개인정보 처리 동의
                      </EasyPayTerm>
                      <EasyPayTermDetail
                        onClick={() => {
                          window.open(
                            "https://jagged-tiara-c80.notion.site/f80513b2af6541cca7442a7e466fd692?pvs=4",
                            "_blank"
                          );
                        }}
                      >
                        자세히
                      </EasyPayTermDetail>
                    </EasyPayTermWrap>
                  </EasyPay>
                )}
                {watch("payMethod") === "일반" && (
                  <CommonPay>
                    <CommonPayContent>
                      <CommonPayDesc>
                        <CommonPayTitle>일반 결제</CommonPayTitle>
                      </CommonPayDesc>
                    </CommonPayContent>
                    <CommonPayTypeWrap>
                      {CommonPayTypeList.map((e) => (
                        <CommonPayTypeButton
                          key={e.id}
                          select={selectedCommonPayType === e.text}
                          onClick={() => setSelectedCommonPayType(e.text)}
                        >
                          {e.text}
                        </CommonPayTypeButton>
                      ))}
                    </CommonPayTypeWrap>
                    <CommonPayContentByType>
                      {selectedCommonPayType === "신용/체크카드" && (
                        <CommonSelect
                          onChange={(e) => {
                            setCardCompany(e.target.value);
                          }}
                          tabIndex={4}
                        >
                          <option value="">카드 선택</option>
                          {CardList.map(({ id, cardCompany }) => (
                            <option key={id} value={cardCompany}>
                              {id}
                            </option>
                          ))}
                        </CommonSelect>
                      )}
                      {selectedCommonPayType === "가상계좌" && (
                        <CommonPayExplanaion />
                      )}
                    </CommonPayContentByType>
                  </CommonPay>
                )}
              </PayMethodContent>
              {watch("payMethod") === "일반" &&
                selectedCommonPayType !== "신용/체크카드" && (
                  <PayMethodContent>
                    <CommonPay>
                      <CommonPayContent>
                        <CommonPayDesc>
                          <CommonPayTitle>현금 영수증</CommonPayTitle>
                        </CommonPayDesc>
                      </CommonPayContent>
                      <NormalWork>
                        <CashReceiptSelectWrap>
                          <Option>
                            <RadioCheckBox
                              type="radio"
                              {...register("cashReceiptMethod", {
                                required: true,
                              })}
                              value="미발행"
                            />
                            <OptionText>미발행</OptionText>
                          </Option>
                          <Option>
                            <RadioCheckBox
                              type="radio"
                              {...register("cashReceiptMethod", {
                                required: true,
                              })}
                              value="소득공제"
                            />
                            <OptionText>소득공제</OptionText>
                          </Option>
                          <Option>
                            <RadioCheckBox
                              type="radio"
                              {...register("cashReceiptMethod", {
                                required: true,
                              })}
                              value="지출증빙"
                            />
                            <OptionText>지출증빙</OptionText>
                          </Option>
                        </CashReceiptSelectWrap>
                        {watch("cashReceiptMethod") === "지출증빙" && (
                          <CommonPayContentByType>
                            <LoadPhoneNum>
                              <EasyPayCheckbox
                                checked={loadComRegNumCheckbox}
                                onChange={() =>
                                  setLoadComRegNumCheckbox(
                                    !loadComRegNumCheckbox
                                  )
                                }
                              />
                              <LoadText>사업자등록번호 불러오기</LoadText>
                            </LoadPhoneNum>
                            <CashReceiptInputWrap>
                              <CashReceiptInputTitle>
                                사업자등록번호
                              </CashReceiptInputTitle>
                              <CashReceiptInput
                                {...register("companyRegNum", {
                                  required: true,
                                  onChange: (e) => {
                                    setValue(
                                      "companyRegNum",
                                      changeComRegNumForm(e.target.value)
                                    );
                                  },
                                })}
                                maxLength={10}
                              />
                            </CashReceiptInputWrap>
                          </CommonPayContentByType>
                        )}
                        {watch("cashReceiptMethod") === "소득공제" && (
                          <CommonPayContentByType>
                            <LoadPhoneNum>
                              <EasyPayCheckbox
                                checked={loadPhoneNumCheckbox}
                                onChange={() =>
                                  setLoadPhoneNumCheckbox(!loadPhoneNumCheckbox)
                                }
                              />
                              <LoadText>핸드폰번호 불러오기</LoadText>
                            </LoadPhoneNum>
                            <CashReceiptInputWrap>
                              <CashReceiptInputTitle>
                                핸드폰 번호
                              </CashReceiptInputTitle>
                              <CashReceiptInput
                                {...register("phoneNum", {
                                  required: true,
                                  onChange: (e) => {
                                    setValue(
                                      "phoneNum",
                                      changePhoneContactForm(e.target.value)
                                    );
                                  },
                                })}
                                maxLength={13}
                              />
                            </CashReceiptInputWrap>
                          </CommonPayContentByType>
                        )}
                      </NormalWork>
                    </CommonPay>
                  </PayMethodContent>
                )}
              <BottomInfo>
                <Line />
                <ExchangeAndRefund>
                  <BottomInfoTitleWrap>
                    <BottomInfoTitle>교환 및 환불 안내</BottomInfoTitle>
                  </BottomInfoTitleWrap>
                  <ExchangeAndRefundContent>
                    <Explanation color="#000">
                      결제하신 수단으로 취소됩니다.
                    </Explanation>
                    <Explanation>
                      • 상품을 받아보신 뒤 7일 이내 환불 신청이 가능합니다.
                      환불을 원하실 경우 고객 센터로 문의주세요.
                    </Explanation>
                    <Explanation>
                      • 기업의 품격에서 판매하는 상품은 중고 물품이므로, 구매
                      확정 이후에는 교환 및 환불이 불가능합니다.
                    </Explanation>
                  </ExchangeAndRefundContent>
                </ExchangeAndRefund>
                <Line />
                <BuyerAgreement>
                  <BottomInfoTitleWrap>
                    <BottomInfoTitle>주문자 동의</BottomInfoTitle>
                  </BottomInfoTitleWrap>
                  <BuyerAgreementTermContainer>
                    <BuyerAgreementTermWrap>
                      <CPCheckbox
                        {...register(`termSelect_all`)}
                        onChange={selectAll}
                      />
                      <BuyerAgreementTerm color={"#000"}>
                        전체 동의하기
                      </BuyerAgreementTerm>
                    </BuyerAgreementTermWrap>
                    {BuyerAgreementTermList.map((e) => (
                      <BuyerAgreementTermWrap key={e.id}>
                        <CPCheckbox
                          {...register(`${e.id}`, {
                            validate: (value) => value === true,
                          })}
                          // checked={termsFlag[index]}
                          onChange={() => {
                            toggleCheck(e.id);
                          }}
                        />
                        <BuyerAgreementTerm color="#5f6368">
                          {e.text}
                        </BuyerAgreementTerm>
                        <BuyerAgreementTermDetail
                          onClick={() => {
                            let url = "";
                            if (e.id === "termSelect_1")
                              url =
                                "https://jagged-tiara-c80.notion.site/5810ccf95d674c2d97ad6c8006e45e82?pvs=4";
                            if (e.id === "termSelect_2")
                              url =
                                "https://jagged-tiara-c80.notion.site/3-f7e089c6bbf445f080beb82d8fb118cc?pvs=4";
                            if (e.id === "termSelect_3")
                              url =
                                "https://jagged-tiara-c80.notion.site/4eaaf9dda3424f719c64b0456aaef209?pvs=4";
                            window.open(url, "_blank");
                          }}
                        >
                          자세히
                        </BuyerAgreementTermDetail>
                      </BuyerAgreementTermWrap>
                    ))}
                  </BuyerAgreementTermContainer>
                </BuyerAgreement>
              </BottomInfo>
            </PayMethodInfo>
            <ProcessInfo>
              <ProcessInfoTitleWrap>
                <InfoTitle>절차 안내</InfoTitle>
              </ProcessInfoTitleWrap>
              <ProcessInfoContent>
                <Step>
                  <StepContent>
                    <img src={PayCompletedImg} alt="결제 완료 이미지" />
                    <StepTextWrap>
                      <StepText>결제 완료</StepText>
                    </StepTextWrap>
                  </StepContent>
                </Step>
                <img src={StepArrowImg} alt="화살표 이미지" />
                <Step>
                  <StepContent>
                    <img src={DeliveryReadyImg} alt="배송 준비 이미지" />
                    <StepTextWrap>
                      <StepText>배송 준비</StepText>
                    </StepTextWrap>
                  </StepContent>
                </Step>
                <img src={StepArrowImg} alt="화살표 이미지" />
                <Step>
                  <StepContent>
                    <img src={DeliveryCompletedImg} alt="배송 완료 이미지" />
                    <StepTextWrap>
                      <StepText>배송 완료</StepText>
                    </StepTextWrap>
                  </StepContent>
                </Step>
                <img src={StepArrowImg} alt="화살표 이미지" />
                <Step>
                  <StepContent>
                    <img src={BuyConfirmImg} alt="구매 확정 이미지" />
                    <StepTextWrap>
                      <StepText>구매 확정</StepText>
                    </StepTextWrap>
                  </StepContent>
                </Step>
              </ProcessInfoContent>
            </ProcessInfo>
            <BuyerInfo>
              <BuyerDetail>
                <BuyerBasicDetail>
                  <InfoTitleWrap>
                    <InfoTitle>구매자 정보</InfoTitle>
                  </InfoTitleWrap>
                  <BuyerBasicDetailContent>
                    <LoadFromUser>
                      <LoadFromUserCheckboxWrap>
                        <LoadFromUserCheckbox
                          checked={loadCheckbox}
                          onChange={() => setLoadCheckbox(!loadCheckbox)}
                        />
                      </LoadFromUserCheckboxWrap>
                      <LoadFromUserText>회원정보에서 불러오기</LoadFromUserText>
                    </LoadFromUser>
                    <BuyerName>
                      <BuyerNameTitle>구매자 이름*</BuyerNameTitle>
                      <BuyerNameInput
                        {...register("name", { required: true })}
                      />
                    </BuyerName>
                    <BuyerContact>
                      <BuyerContactTitle>연락처*</BuyerContactTitle>
                      <BuyerContactInput
                        {...register("phoneNumber", { required: true })}
                      />
                    </BuyerContact>
                    <BuyerAddress>
                      <BuyerAddressTitle>주소*</BuyerAddressTitle>
                      <BuyerAddressWrap>
                        <BuyerAddressInputWrap>
                          <BuyerAddressInput1
                            {...register("address", { required: true })}
                          />
                          <SearchAddressButton
                            type="button"
                            onClick={onToggleModal}
                          >
                            <SearchAddressText>주소 찾기</SearchAddressText>
                          </SearchAddressButton>
                        </BuyerAddressInputWrap>
                        <BuyerAddressInputWrap>
                          <BuyerAddressInput2
                            {...register("addressDetail", { required: true })}
                          />
                        </BuyerAddressInputWrap>
                      </BuyerAddressWrap>
                    </BuyerAddress>
                  </BuyerBasicDetailContent>
                </BuyerBasicDetail>
                <DeliveryMessage
                  {...register("deliveryMessage")}
                  placeholder="배송 메시지(선택)"
                  maxLength={50}
                />
              </BuyerDetail>
              <PayButton
                disabled={!isValid}
                onClick={() => setIsBtnClicked(!isBtnClicked)}
              >
                <PayButtonText disabled={!isValid}>결제하기</PayButtonText>
              </PayButton>
            </BuyerInfo>
          </PayWrapForm>
          {isOpen && (
            <Modal
              open={true}
              footer={null}
              onCancel={onToggleModal} // isOpen이 false가 되고 화면이 리렌더되면서 모달이 뜨지 않는다.
              width={550}
              bodyStyle={{ padding: "15px" }}
            >
              <DaumPostcodeEmbed onComplete={handleComplete} />
            </Modal>
          )}
          {isPopUpOpen && (
            <Popup
              type="pay"
              info={"카드사를 선택해주세요."}
              buttonCount={1}
              onCancel={onTogglePopUp}
              onOk={() => {
                onTogglePopUp();
              }}
            />
          )}
        </Content>
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
