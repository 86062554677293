import React, { useState } from "react";
import MainBannerImg from "../../assets/content/banner/img-main-banner-final.png";
import { ReactComponent as CategoryImage01 } from "../../assets/content/category/img-content-category-01.svg";
import { ReactComponent as CategoryImage02 } from "../../assets/content/category/img-content-category-02.svg";
import { ReactComponent as CategoryImage03 } from "../../assets/content/category/img-content-category-03.svg";
import { ReactComponent as CategoryImage04 } from "../../assets/content/category/img-content-category-04.svg";
import { ReactComponent as CategoryImage05 } from "../../assets/content/category/img-content-category-05.svg";
import { ReactComponent as CategoryImage06 } from "../../assets/content/category/img-content-category-06.svg";
import { ReactComponent as CategoryImage07 } from "../../assets/content/category/img-content-category-07.svg";
// import MiddleBannerImg from "../../assets/content/banner/img-middle-banner.png";
import { ReactComponent as DescItemImg01 } from "../../assets/content/description/img-description-item-01.svg";
import { ReactComponent as DescItemImg02 } from "../../assets/content/description/img-description-item-02.svg";
import { ReactComponent as DescItemImg03 } from "../../assets/content/description/img-description-item-03.svg";
// import BottomBannerImg from "../../assets/content/banner/img-bottom-banner.png";
import { ReactComponent as BottomBannerImage } from "../../assets/content/banner/img-bottom-banner.svg";

import {
  Wrap,
  Content,
  Category,
  Text,
  CategoryItemWrap,
  CategoryItem,
  CategoryItemText,
  NonCategory,
  PopularItem,
  Filter,
  FilterItem,
  FilterItemText,
  Card,
  CardItem,
  ItemDescription,
  ItemTitle,
  ItemType,
  ItemTypeText,
  ItemDetail,
  ItemDetailElement,
  ItemDetailElementText,
  Line,
  ItemPriceWrap,
  ItemPriceText,
  DescAndBanner,
  Desc,
  DescTitle,
  DescTitleText,
  DescItemWrap,
  DescItem,
  DescItemContentWrap,
  DescItemContent,
  DescItemContentTopText,
  DescItemContentBottomText,
  MiddleBannerTextTop,
  MiddleBannerTextBottom,
  MiddleBannerWrap,
} from "./styles";
import useGetCategories from "../../hooks/common/useGetCategories";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedCategoryIdState } from "../../recoil/home";
import useGetTrendProducts from "../../hooks/common/useGetTrendProducts";
import { changeCommaFormat } from "../../utils/utility";
import useGetCountByCategory from "../../hooks/common/useGetCountByCategory";
import Consulting from "../Header/consulting";

const DescItemContentList = [
  {
    descItemImg: <DescItemImg01 />,
    contentTop: "상담 전화 \n 한 통이면 끝이에요",
    contentBottom:
      "촬영, 판매, 수거, 배송, 회계처리까지 척척 \n 대표님은 정산금만 받으시면 돼요",
  },
  {
    descItemImg: <DescItemImg02 />,
    contentTop: "합리적인 가격으로 \n 빠른 판매를 도와드려요",
    contentBottom:
      "AI기반 적정 판매까지 단 5분 \n 합리적인 가격에 똑똑하게 거래하세요",
  },
  {
    descItemImg: <DescItemImg03 />,
    contentTop: "ESG 경영의 첫 걸음 \n 기업의 품격에서 시작하세요",
    contentBottom: "더 이상 의미없던 상품에 \n 새로운 가치를 더해보세요",
  },
];

const SplitAndMap = (item: string) => {
  return item.split("\n").map((txt: string, index) => (
    <div key={index}>
      {txt}
      <br />
    </div>
  ));
};

const CategoryImgList: any = {
  1: <CategoryImage01 />,
  2: <CategoryImage02 />,
  3: <CategoryImage03 />,
  4: <CategoryImage04 />,
  5: <CategoryImage05 />,
  6: <CategoryImage06 />,
  7: <CategoryImage07 />,
};

const trendImgStyle = {
  borderRadius: "8px",
};

interface categoriesType {
  id: number;
  name: string;
}

interface TrendProduct {
  isBiz: string;
  id: number;
  name: string;
  price: number;
  brokerageFee: number;
  condition: string;
  usedYear: number;
  usedMonth: number;
  productImageUrl: string;
}

const AppContent = () => {
  const navigate = useNavigate();
  const [byNewRegistration, setByNewRegistration] = useState<boolean>(true);
  const [byView, setByView] = useState<boolean>(false);
  const { data: categories } = useGetCategories();
  const { data: productCountsByCategory } = useGetCountByCategory();
  const [, setSelectedCategoryId] = useRecoilState(SelectedCategoryIdState);
  const { data: trendProducts } = useGetTrendProducts(
    byNewRegistration ? "new" : "view"
  );
  const [isOpen, setIsOpen] = useState(false);
  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Wrap>
      <img
        src={MainBannerImg}
        alt="메인 배너"
        width="100%"
        style={{ marginTop: "67px" }}
      />
      <Content>
        <Category>
          <Text>카테고리</Text>
          <CategoryItemWrap>
            {categories?.map(({ id, name }: categoriesType) => (
              <CategoryItem
                key={id}
                onClick={() => {
                  setSelectedCategoryId(name);
                  navigate("/productinquiry");
                }}
              >
                {CategoryImgList[id]}
                <CategoryItemText>{name}</CategoryItemText>
              </CategoryItem>
            ))}
          </CategoryItemWrap>
        </Category>
        <NonCategory>
          <PopularItem>
            <Text>인기 상품 트렌드</Text>
            <Filter>
              <FilterItem
                selected={byNewRegistration}
                onClick={() => {
                  setByNewRegistration(true);
                  setByView(false);
                }}
              >
                <FilterItemText>신규 등록순</FilterItemText>
              </FilterItem>
              <FilterItem
                selected={byView}
                onClick={() => {
                  setByNewRegistration(false);
                  setByView(true);
                }}
              >
                <FilterItemText>조회순</FilterItemText>
              </FilterItem>
            </Filter>
            <Card>
              {trendProducts?.map(
                ({
                  isBiz,
                  id,
                  name,
                  price,
                  brokerageFee,
                  condition,
                  usedYear,
                  usedMonth,
                  productImageUrl,
                }: TrendProduct) => (
                  <CardItem key={id} onClick={() => navigate(`/product/${id}`)}>
                    <img
                      src={productImageUrl}
                      alt="트렌드 상품"
                      width={192}
                      height={144}
                      style={trendImgStyle}
                    />
                    <ItemDescription>
                      <ItemTitle>{name}</ItemTitle>
                      <ItemType isBiz={isBiz}>
                        <ItemTypeText isBiz={isBiz}>
                          {isBiz === "Y" ? "사업자" : "개인"}
                        </ItemTypeText>
                      </ItemType>
                      <ItemDetail>
                        <ItemDetailElement gap="10px">
                          <ItemDetailElementText>
                            사용기간
                          </ItemDetailElementText>
                          <ItemDetailElementText>
                            {usedYear}년 {usedMonth}개월
                          </ItemDetailElementText>
                        </ItemDetailElement>
                        <ItemDetailElement gap="21px">
                          <ItemDetailElementText>컨디션</ItemDetailElementText>
                          <ItemDetailElementText>
                            {condition}
                          </ItemDetailElementText>
                        </ItemDetailElement>
                      </ItemDetail>
                      <Line />
                      <ItemPriceWrap>
                        <ItemPriceText>
                          {changeCommaFormat(price+brokerageFee)}원
                        </ItemPriceText>
                      </ItemPriceWrap>
                    </ItemDescription>
                  </CardItem>
                )
              )}
            </Card>
          </PopularItem>
          <MiddleBannerWrap
            onClick={() => {
              setSelectedCategoryId("카페/식당");
              navigate("/productinquiry");
            }}
          >
            <MiddleBannerTextTop>
              카페 상품도 기업의 품격에서!
            </MiddleBannerTextTop>
            <MiddleBannerTextBottom>
              총{" "}
              {productCountsByCategory?.find((e: any) => e.id === 2)
                ?.productCount || 0}
              개의 상품이 등록되어 있어요
            </MiddleBannerTextBottom>
          </MiddleBannerWrap>
          {/* <img src={MiddleBannerImg} alt="중간 배너" /> */}
          <DescAndBanner>
            <Desc>
              <DescTitle>
                <DescTitleText>
                  똑똑한 사무실 물품 정리 <br /> 손쉽게 시작하세요
                </DescTitleText>
              </DescTitle>
              <DescItemWrap>
                {DescItemContentList.map((item, index) => (
                  <DescItem key={index}>
                    {item.descItemImg}
                    <DescItemContentWrap>
                      <DescItemContent>
                        <DescItemContentTopText>
                          {SplitAndMap(item.contentTop)}
                        </DescItemContentTopText>
                      </DescItemContent>
                      <DescItemContent>
                        <DescItemContentBottomText>
                          {SplitAndMap(item.contentBottom)}
                        </DescItemContentBottomText>
                      </DescItemContent>
                    </DescItemContentWrap>
                  </DescItem>
                ))}
              </DescItemWrap>
            </Desc>
            <BottomBannerImage
              style={{ cursor: "pointer" }}
              onClick={() => onToggleModal()}
            />
            {/* <img
              src={BottomBannerImg}
              alt="하단 배너"
              style={{ cursor: "pointer" }}
              onClick={() => onToggleModal()}
            /> */}
          </DescAndBanner>
        </NonCategory>
      </Content>
      {isOpen && <Consulting onToggleModal={onToggleModal} />}
    </Wrap>
  );
};

export default React.memo(AppContent);
