import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

export const getCountByCategory = async () => {
  const { data } = await axiosClient.get("/web/products/count-by-category");
  return data;
};

export default function useGetCountByCategory() {
  return useQuery(["getCountByCategory"], getCountByCategory, {
    select: (res) => res.result.productCountsByCategory,
  });
}
