import React, { useEffect, useRef, useState } from "react";
import {
  CartItem,
  CartItemWrap,
  CartDetail,
  CartItemInfo,
  ItemSummary,
  CartWrap,
  Container,
  CartStatus,
  ItemSummaryWrap,
  ButtonWrap,
  ModifyButton,
  FisrtItemText,
  SecondItemText,
  ImgWrapper,
  Filter,
  FilterAndSearch,
  MySelling,
  FilterText,
  MyBuying,
  Search,
  SearchInput,
  SearchIconWrap,
  TitleInfoWrap,
  TitleInfo,
  TitleInfoText,
  NoItemWrapper,
  NoItemComment,
  LoadingText,
} from "./styles";

// import PopularItem01 from "../../assets/content/popularItem/img-content-popularItem-01.png";
import { MyPaginate } from "../../components/Pagination";
// import { useNavigate } from "react-router-dom";
import useGetBuyingStatus from "../../hooks/trading/useGetBuyingStatus";
import { changeCommaFormat } from "../../utils/utility";
import { ReactComponent as SearchIcon } from "../../assets/header/ic-header-search.svg";
import { ReactComponent as TitleInfoIcon } from "../../assets/content/sell/ic-content-sell-info.svg";
import { axiosAuthClient } from "../../apis/general";
import Popup from "../../components/Popup/common";

interface SalesProduct {
  id: number;
  productName: string;
  price: number;
  expectedDeliveryFee: number;
  brokerageFee: number;
  cardNumber: string;
  orderDate: string;
  productImageUrl: string;
  productStatus: string;
  paymentMethod: string;
  cashRecepitMethod: string;
  trackingNumber: number;
  cardName: string;
  companyRegistrationNumber: number;
  phoneNumber: number;
  deliveryManagerPhoneNumber: string;
  date: string;
  deliveryMethod: string;
  expectedDeliveryDate: string;
  expectedArrivalDate: string;
}

interface Props {
  onSelect: (type: string) => void;
  selling: boolean;
  buying: boolean;
}

export default function BuyingStatus({ onSelect, selling, buying }: Props) {
  // const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [productName, setProductName] = useState<string>("");
  const productInput = useRef("");
  const { data, isLoading } = useGetBuyingStatus(page, productName);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const ConfirmPurchasing = async (productId: number) => {
    try {
      await axiosAuthClient.post(
        `/web/products/${productId}/purchase-complete`
      );
      window.location.reload();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <FilterAndSearch>
        <Filter>
          <MySelling select={selling} onClick={() => onSelect("selling")}>
            <FilterText select={selling}>나의 판매</FilterText>
          </MySelling>
          <MyBuying select={buying} onClick={() => onSelect("buying")}>
            <FilterText select={buying}>나의 구매</FilterText>
          </MyBuying>
        </Filter>
        <Search>
          <SearchInput
            onChange={(e) => (productInput.current = e.target.value)}
            placeholder="상품 정보를 입력해주세요."
            tabIndex={1}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                setProductName(productInput.current);
              }
            }}
          />
          <SearchIconWrap
            onClick={() => setProductName(productInput.current)}
            tabIndex={2}
          >
            <SearchIcon />
          </SearchIconWrap>
        </Search>
      </FilterAndSearch>
      <CartWrap>
        <TitleInfoWrap>
          <TitleInfo>
            <TitleInfoIcon />
            <TitleInfoText>
              구매 취소를 원할 경우 7일이내에 고객센터로 문의부탁드립니다.
            </TitleInfoText>
          </TitleInfo>
        </TitleInfoWrap>
        {data?.purchasedProducts?.map(
          ({
            id,
            productName,
            price,
            expectedDeliveryFee,
            brokerageFee,
            orderDate,
            productImageUrl,
            paymentMethod,
            cashRecepitMethod,
            cardName,
            productStatus,
            phoneNumber,
            companyRegistrationNumber,
            expectedDeliveryDate,
            expectedArrivalDate,
            deliveryMethod,
            trackingNumber,
            deliveryManagerPhoneNumber,
          }: SalesProduct) => (
            <Container key={id}>
              <CartItemWrap>
                <CartStatus>{orderDate} 주문</CartStatus>
                <CartItem>
                  <CartDetail>
                    <ImgWrapper>
                      <img
                        src={productImageUrl}
                        alt="상품 이미지"
                        width={158}
                        height={104}
                        style={{ borderRadius: "8px" }}
                      />
                    </ImgWrapper>
                    <CartItemInfo>
                      <ItemSummaryWrap>
                        <ItemSummary>
                          <FisrtItemText>{productName}</FisrtItemText>
                          <FisrtItemText
                            style={{ fontWeight: 500, fontSize: 18 }}
                          >
                            배송예정일 : {expectedDeliveryDate || "-"}
                          </FisrtItemText>
                        </ItemSummary>
                        <ItemSummary>
                          <SecondItemText>
                            {changeCommaFormat(
                              price + expectedDeliveryFee + brokerageFee
                            )}
                            원{" "}
                            <span
                              style={{ fontSize: "17px", color: "#9e9a9a" }}
                            >
                              (배송비 {changeCommaFormat(expectedDeliveryFee)}원
                              포함)
                            </span>
                          </SecondItemText>
                          <SecondItemText
                            style={{ fontWeight: 500, fontSize: 18 }}
                          >
                            도착예정일 :{" "}
                            {[
                              "배송중",
                              "배송 완료",
                              "구매 확정 완료",
                              "배송 일정 확정",
                            ].includes(productStatus)
                              ? expectedArrivalDate || "-"
                              : "-"}
                          </SecondItemText>
                        </ItemSummary>
                        <ItemSummary>
                          <SecondItemText></SecondItemText>
                          <SecondItemText
                            style={{ fontWeight: 500, fontSize: 18 }}
                          >
                            {paymentMethod}
                            {(paymentMethod === "계좌이체" ||
                              paymentMethod === "가상계좌") && (
                              <>
                                {"("}현금영수증{" "}
                                {cashRecepitMethod !== "미발행"
                                  ? "발행"
                                  : cashRecepitMethod}
                                {")"}
                              </>
                            )}
                          </SecondItemText>
                        </ItemSummary>
                        <ItemSummary>
                          <SecondItemText></SecondItemText>
                          <SecondItemText
                            style={{
                              fontWeight: 500,
                              fontSize: 18,
                              color: "#ACB1BA",
                            }}
                          >
                            {paymentMethod === "계좌이체" ||
                            paymentMethod === "가상계좌"
                              ? cashRecepitMethod !== "미발행"
                                ? `${cashRecepitMethod} : ${
                                    cashRecepitMethod === "소득공제"
                                      ? phoneNumber
                                      : companyRegistrationNumber
                                  }`
                                : ""
                              : `${cardName}카드`}
                          </SecondItemText>
                        </ItemSummary>
                        <ItemSummary>
                          <SecondItemText></SecondItemText>
                          <SecondItemText style={{ fontWeight: 700 }}>
                            {productStatus}
                          </SecondItemText>
                        </ItemSummary>
                      </ItemSummaryWrap>
                    </CartItemInfo>
                  </CartDetail>
                  <ButtonWrap isBuying={true} style={{ marginTop: "20px" }}>
                    <ModifyButton
                      disabled={[
                        "구매자 결제 완료",
                        "구매 확정 완료",
                        "결제 진행중",
                      ].includes(productStatus)}
                      isBuying={true}
                      onClick={() => {
                        if (deliveryMethod === "용달") {
                          onTogglePopUp();
                        } else {
                          window.open(
                            `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${trackingNumber}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      배송 조회
                    </ModifyButton>
                    <ModifyButton
                      disabled={productStatus !== "배송 완료"}
                      isBuying={true}
                      onClick={() => ConfirmPurchasing(id)}
                    >
                      구매 확정
                    </ModifyButton>
                  </ButtonWrap>
                </CartItem>
              </CartItemWrap>
              {isPopUpOpen && (
                <Popup
                  type="login"
                  info={`배송 담당자 연락처 : ${deliveryManagerPhoneNumber}/해당 연락처로 문의 부탁드려요.`}
                  buttonCount={1}
                  onCancel={onTogglePopUp}
                  onOk={() => {
                    onTogglePopUp();
                  }}
                />
              )}
            </Container>
          )
        )}
        {isLoading && <LoadingText>데이터를 불러오는 중입니다.</LoadingText>}
        {data?.purchasedProducts.length === 0 && (
          <NoItemWrapper>
            <NoItemComment>구매내역이 없습니다.</NoItemComment>
          </NoItemWrapper>
        )}
      </CartWrap>
      {!(data?.purchasedProducts.length === 0) && (
        <MyPaginate
          marginTop="50px"
          forcePage={0}
          previousLabel={"〈"}
          nextLabel={"〉"}
          breakLabel={"..."}
          pageCount={Math.ceil((data?.totalCount ?? 1) / 5)}
          marginPagesDisplayed={0}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => {
            setPage(selected + 1);
          }}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </>
  );
}
