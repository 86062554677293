import React, { useEffect, useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import {
  Wrap,
  Content,
  TitleWrap,
  Title,
  PayWrap,
  ProductInfo,
  BuyerInfo,
  InfoTitleWrap,
  InfoTitle,
  PIContent,
  PIContentSummary,
  PIName,
  PIPrice,
  BuyerDetail,
  PayButton,
  PayButtonText,
  BuyerBasicDetail,
  BuyerBasicDetailContent,
  BuyerName,
  LoadFromUser,
  LoadFromUserCheckbox,
  LoadFromUserText,
  LoadFromUserCheckboxWrap,
  BuyerNameTitle,
  BuyerNameInput,
  SubTitle,
  SubTitleWrap,
  ProductInfoWrap,
  ProductInfoTitleWrap,
  TitleContainer,
  TitleDesc,
  AccountInputWrap,
  AccountSelect,
} from "./styles";

// import ItemImg from "../../assets/content/pay/img-content-pay-item.png";
import SettlementCompleted from "./completed";
import { useForm } from "react-hook-form";
import Comment from "../../components/Settlement/Comment";
import { UserBizState, UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";
import { axiosAuthClient } from "../../apis/general";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  accountRegExpMap,
  changeAccountForm,
  changeComRegNumForm,
  changeCommaFormat,
} from "../../utils/utility";
import { getSettlementMethod } from "../settlement";

interface FormValues {
  depositorName: string;
  bankName: string;
  accountNumber: string;
  businessName?: string;
  companyRegistrationNumber?: string;
}

interface Param extends FormValues {
  productId: number;
  userId: number;
  settlementFee: number;
}

export default function SettlementApply() {
  const [userId] = useRecoilState(UserDBIdState);
  const [isBiz] = useRecoilState(UserBizState);
  const [isCompleted, setIsCompleted] = useState(false);
  const location = useLocation();
  const [loadFromUserCheck, setLoadFromUserCheck] = useState(true);
  const [selectedBank, setSelectedBank] = useState("");

  const {
    register,
    formState: { errors, isValid },
    setValue,
    reset,
    handleSubmit,
    trigger,
  } = useForm();

  const { data: methodData } = useQuery(
    ["getSettlementMethod"],
    () => getSettlementMethod(userId),
    {
      select: (res) => res.result.settlementMethod,
    }
  );

  useEffect(() => {
    reset({
      depositorName: loadFromUserCheck ? methodData?.depositorName : "",
      bankName: loadFromUserCheck ? methodData?.bankName : "",
      accountNumber: loadFromUserCheck ? methodData?.accountNumber : "",
      businessName: loadFromUserCheck ? methodData?.businessName : "",
      companyRegistrationNumber: loadFromUserCheck
        ? methodData?.companyRegistrationNumber
        : "",
    });
    setSelectedBank(loadFromUserCheck ? methodData?.bankName ?? "" : "");
  }, [loadFromUserCheck]);

  const requestSettlement = useMutation({
    mutationFn: (param: Param) =>
      axiosAuthClient.post("/web/settlements", param),
    onSuccess: ({ data }) => {
      const { code, message } = data;
      if (code === 1000) {
        setIsCompleted(!isCompleted);
      } else {
        alert(`${message}(code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const onSubmit = (data: FormValues) => {
    requestSettlement.mutate({
      userId,
      productId: location.state?.productId,
      settlementFee: location.state?.price,
      ...data,
    });
  };

  return (
    <Root>
      <AppHeader />
      {isCompleted ? (
        <SettlementCompleted />
      ) : (
        <Wrap>
          <Content>
            <TitleWrap>
              <Title>정산 관리</Title>
            </TitleWrap>
            <PayWrap>
              <SubTitleWrap>
                <SubTitle>정산금 수령 신청하기</SubTitle>
              </SubTitleWrap>
              <ProductInfoWrap>
                <ProductInfoTitleWrap>
                  <InfoTitle>상품 정보</InfoTitle>
                </ProductInfoTitleWrap>
                <ProductInfo>
                  <PIContent>
                    <img
                      src={location.state?.productImageUrl}
                      alt="상품 이미지"
                      width={158}
                      height={104}
                      style={{ borderRadius: "8px" }}
                    />
                    <PIContentSummary>
                      <PIName>{location.state?.productName}</PIName>
                      <PIPrice>
                        정산금 {changeCommaFormat(location.state?.price)}원
                      </PIPrice>
                    </PIContentSummary>
                  </PIContent>
                </ProductInfo>
              </ProductInfoWrap>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <BuyerInfo>
                  <BuyerDetail>
                    <BuyerBasicDetail>
                      <InfoTitleWrap>
                        <InfoTitle>정산 수단 입력</InfoTitle>
                      </InfoTitleWrap>
                      <BuyerBasicDetailContent>
                        <LoadFromUser>
                          <LoadFromUserCheckboxWrap>
                            <LoadFromUserCheckbox
                              checked={loadFromUserCheck}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setLoadFromUserCheck(true);
                                  return;
                                }
                                setLoadFromUserCheck(false);
                              }}
                            />
                          </LoadFromUserCheckboxWrap>
                          <LoadFromUserText>정산수단 불러오기</LoadFromUserText>
                        </LoadFromUser>
                        <BuyerName>
                          <BuyerNameTitle>입금자명*</BuyerNameTitle>
                          <BuyerNameInput
                            {...register("depositorName", {
                              required: "이름을 확인해주세요",
                            })}
                            placeholder="이름을 입력해주세요"
                            maxLength={50}
                          />
                          <Comment
                            show={Boolean(errors.depositorName)}
                            content={String(errors.depositorName?.message)}
                          />
                        </BuyerName>
                        <BuyerName>
                          <TitleContainer>
                            <BuyerNameTitle>계좌번호*</BuyerNameTitle>
                            <TitleDesc>
                              사업자의 경우, 사업자 계좌를 입력해주세요
                            </TitleDesc>
                          </TitleContainer>
                          <AccountInputWrap>
                            <AccountSelect
                              {...register("bankName", {
                                required: "은행을 확인해주세요.",
                              })}
                              onChange={(e) => {
                                setSelectedBank(e.target.value);
                                trigger("bankName");
                              }}
                            >
                              <option value="">은행 선택</option>
                              <option value="(구)KB국민은행">
                                (구)KB국민은행
                              </option>
                              <option value="(신)KB국민은행">
                                (신)KB국민은행
                              </option>
                              <option value="IBK기업은행">IBK기업은행</option>
                              <option value="NH농협은행">NH농협은행</option>
                              <option value="(구)신한은행">(구)신한은행</option>
                              <option value="(신)신한은행">(신)신한은행</option>
                              <option value="우리은행">우리은행</option>
                              <option value="KEB하나은행">KEB하나은행</option>
                              <option value="(구)외환은행">(구)외환은행</option>
                              <option value="씨티은행">씨티은행</option>
                              <option value="DGB대구은행">DGB대구은행</option>
                              <option value="BNK부산은행">BNK부산은행</option>
                              <option value="SC제일은행">SC제일은행</option>
                              <option value="케이뱅크">케이뱅크</option>
                              <option value="카카오뱅크">카카오뱅크</option>
                            </AccountSelect>
                            <BuyerNameInput
                              {...register("accountNumber", {
                                required: "계좌번호를 확인해주세요",
                                pattern: {
                                  value:
                                    accountRegExpMap[selectedBank]?.[
                                      "finalRegExp"
                                    ],
                                  message: "",
                                },
                                onChange: (e) =>
                                  setValue(
                                    "accountNumber",
                                    changeAccountForm(
                                      e.target.value,
                                      selectedBank
                                    )
                                  ),
                              })}
                              placeholder="계좌번호를 입력해주세요"
                              maxLength={
                                accountRegExpMap[selectedBank]?.["maxLength"]
                              }
                            />
                          </AccountInputWrap>
                          <Comment
                            show={Boolean(errors.bankName)}
                            content={String(errors.bankName?.message)}
                          />
                          <Comment
                            show={Boolean(errors.accountNumber)}
                            content={String(errors.accountNumber?.message)}
                          />
                        </BuyerName>
                        {isBiz === "Y" && (
                          <>
                            <BuyerName>
                              <TitleContainer>
                                <BuyerNameTitle>사업자명*</BuyerNameTitle>
                                <TitleDesc>개인회원인 경우 빈칸</TitleDesc>
                              </TitleContainer>
                              <BuyerNameInput
                                {...register("businessName", {
                                  required:
                                    isBiz === "Y"
                                      ? "사업자명을 확인해주세요"
                                      : false,
                                  // disabled: isBiz === "N",
                                })}
                                maxLength={50}
                                placeholder="사업자명을 입력해주세요"
                              />
                              {isBiz && (
                                <Comment
                                  show={Boolean(errors.businessName)}
                                  content={String(errors.businessName?.message)}
                                />
                              )}
                            </BuyerName>
                            <BuyerName>
                              <TitleContainer>
                                <BuyerNameTitle>
                                  사업자 등록번호*
                                </BuyerNameTitle>
                                <TitleDesc>개인회원인 경우 빈칸</TitleDesc>
                              </TitleContainer>
                              <BuyerNameInput
                                {...register("companyRegistrationNumber", {
                                  required:
                                    isBiz === "Y"
                                      ? "사업자 등록번호를 확인해주세요"
                                      : false,
                                  // disabled: isBiz === "N",
                                  pattern: {
                                    value: /^(\d{3})-(\d{2})-(\d{5})$/,
                                    message: "",
                                  },
                                  onChange: (e) =>
                                    setValue(
                                      "companyRegistrationNumber",
                                      changeComRegNumForm(e.target.value)
                                    ),
                                })}
                                placeholder="사업자 등록번호를 입력해주세요"
                                maxLength={10}
                              />
                              {isBiz && (
                                <Comment
                                  show={Boolean(
                                    errors.companyRegistrationNumber
                                  )}
                                  content={String(
                                    errors.companyRegistrationNumber?.message
                                  )}
                                />
                              )}
                            </BuyerName>
                          </>
                        )}
                      </BuyerBasicDetailContent>
                    </BuyerBasicDetail>
                  </BuyerDetail>
                  <PayButton
                    border={isValid ? "Main" : ""}
                    background={isValid ? "Main" : "#EEE"}
                    disabled={!isValid}
                  >
                    <PayButtonText color={isValid ? "#fff" : "#ACB1BA"}>
                      정산금 요청
                    </PayButtonText>
                  </PayButton>
                </BuyerInfo>
              </form>
            </PayWrap>
          </Content>
        </Wrap>
      )}
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
