import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  place-items: center;
`;

export const Container = styled.div<{ step: string }>`
  display: flex;
  width: ${(props) => props.step !== "가입양식" && "480px"};
  margin-top: ${(props) => (props.step !== "가입양식" ? "180px" : "100px")};
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

export const CommonText = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;
