import React from "react";
import styled from "styled-components";

interface propsType {
  show: boolean;
  content: string;
}

export default function Comment({ show, content }: propsType) {
  if (show) {
    return <Content>{content}</Content>;
  }
  return null;
}

const Content = styled.span`
  padding-left: 0.93rem;
  font-size: 0.87rem;
  color: red;
`;
