import { useQuery } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";
import { UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";

const getSellingStatus = async (
  userId: number,
  page: number,
  productName?: string
) => {
  const { data } = await axiosAuthClient.get(
    `/web/products/sales?userId=${userId}&productName=${productName}&page=${page}`
  );
  return data;
};

export default function useGetSellingStatus(
  page: number,
  productName?: string
) {
  const [userId] = useRecoilState(UserDBIdState);
  return useQuery(
    ["getsellingstatus", page, productName],
    () => getSellingStatus(userId, page, productName),
    {
      onError: (err) => alert(err),
      select: ({ result: { salesProducts, totalCount } }) => ({
        salesProducts,
        totalCount,
      }),
    }
  );
}
