import React from "react";
import styled from "styled-components";

interface propsType {
  show: boolean;
  content: string;
}

export default function SignUpComment({ show, content }: propsType) {
  if (show) {
    return (
      <Wrap>
        <Empty></Empty>
        <Content>{content}</Content>
      </Wrap>
    );
  }
  return null;
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  align-self: stretch;
`;

const Empty = styled.span`
  width: 8.125rem;
`;

const Content = styled.span`
  padding-left: 0.93rem;
  font-size: 0.87rem;
  color: red;
`;
