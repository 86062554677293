import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";
import {
  JwtState,
  LoginState,
  UserBizState,
  UserDBIdState,
} from "../../recoil/login";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { JWT_KEY } from "../../config/constant";
import { IsBizType } from "../../types/memberInfo";

interface Param {
  isBiz: boolean;
}

export default function useSignUp({ isBiz }: Param) {
  const navigate = useNavigate();
  const [, setIsLoggedIn] = useRecoilState(LoginState);
  const [, setJwt] = useRecoilState(JwtState);
  const [, setUserDBId] = useRecoilState(UserDBIdState);
  const [, setUserBiz] = useRecoilState(UserBizState);

  return useMutation({
    mutationFn: (param: IsBizType<typeof isBiz>) =>
      axiosClient.post("/web/users", param),
    onSuccess: ({ data }) => {
      console.log(data);
      const { code } = data;
      if (code === 1000) {
        const {
          result: { jwt, id, isBiz },
        } = data;
        setIsLoggedIn(true);
        setJwt(jwt);
        setUserDBId(id);
        setUserBiz(isBiz);
        localStorage.setItem(JWT_KEY, jwt);
        alert("회원가입이 완료되었습니다");
        navigate("/");
      } else {
        alert(`회원 가입에 실패했습니다(서버 오류, code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });
}
