import styled from "styled-components";

export const Wrap = styled.div`
  padding: 20px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TopTitle = styled.div`
  display: flex;
  height: 58px;
  justify-content: center;
  align-items: center;

  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 78.571% */
`;

export const TopContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Supplier = styled.div`
  display: flex;
  height: 177px;
  align-items: flex-start;
  gap: -0.914px;
`;

export const SupplierTitle = styled.div`
  display: flex;
  width: 43.914px;
  padding: 9.14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  background: #eee;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const SupplierContent = styled.div`
  display: flex;
  width: 534px;
  height: 177px;
  flex-direction: column;
  align-items: flex-start;
  gap: -0.914px;
  flex-shrink: 0;
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: -0.914px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const CompanyRegNumber = styled.div`
  display: flex;
  width: 105px;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  background: #eee;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const CompanyRegNumberVal = styled.div`
  display: flex;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.108px; /* 167.569% */
`;

export const SecondLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: -0.914px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const CompanyName = styled.div`
  display: flex;
  width: 105px;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  background: #eee;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const CompanyNameVal = styled.div`
  display: flex;
  width: 184px;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.108px; /* 167.569% */
`;

export const Name = styled.div`
  display: flex;
  width: 91px;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const NameVal = styled.div`
  display: flex;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.108px; /* 167.569% */
`;

export const ThirdLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: -0.914px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const CompanyAddress = styled.div`
  display: flex;
  width: 105px;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  background: #eee;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const CompanyAddressVal = styled.div`
  display: flex;
  padding: 9.14px;
  justify-content: center;
  align-items: center;
  gap: 9.14px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.108px; /* 167.569% */
`;

export const ForthLine = styled(SecondLine)``;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TransactionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Category = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Column = styled.div<{ width: string }>`
  display: flex;
  width: ${(props) => props.width};
  height: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid var(--button-gray, #acb1ba);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.108px; /* 143.631% */
`;

export const ValueWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TotalValueWrap = styled.div`
  display: flex;
  align-items: flex-start;
  background: #eee;
`;
