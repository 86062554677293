import React, { useState } from "react";
import AppHeader from "../../../components/Header";
import styled from "styled-components";
import { Wrap, Container } from "./styles";
import { ReactComponent as Logo } from "../../../assets/login/img-login-logo.svg";
import PhoneInput from "../../../components/Auth/common/phoneInput";
import IDShow from "../../../components/Auth/IDShow";
import { useNavigate } from "react-router-dom";
import PhoneConfirm from "../../../components/Auth/common/phoneConfirm";

export default function FindID() {
  const navigate = useNavigate();
  const [step, setStep] = useState<"휴대폰인증" | "본인인증" | "ID확인">(
    "휴대폰인증"
  );
  const [id, setId] = useState("");
  const [verifyCode, setVerifyCode] = useState<number>();
  const [phoneNumber, setPhoneNumber] = useState<string>();

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container>
          <Logo width={151} height={38} />
          <Show condition={step === "휴대폰인증"}>
            <PhoneInput
              onNext={(verifyCode: number, phoneNumber: string) => {
                setVerifyCode(verifyCode);
                setStep("본인인증");
                setPhoneNumber(phoneNumber);
              }}
            />
          </Show>
          <Show condition={step === "본인인증"}>
            <PhoneConfirm
              verifyCode={verifyCode}
              phoneNumber={phoneNumber}
              onNext={() => setStep("ID확인")}
              onPrevious={() => setStep("휴대폰인증")}
              setId={(val: string) => setId(val)}
              purpose="find-id"
            />
          </Show>
          <Show condition={step === "ID확인"}>
            <IDShow onNext={() => navigate("/findpw")} id={id} />
          </Show>
        </Container>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

interface showPropsType {
  condition: boolean;
  children: any;
}

function Show({ condition, children }: showPropsType) {
  return condition === true ? children : null;
}
