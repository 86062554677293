import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

const checkDuplicateID = async (id: string | undefined) => {
  if (id === undefined) return Promise.reject("검증할 아이디가 없습니다.");
  const { data } = await axiosClient.get(`/web/auth/duplicate-id?userId=${id}`);
  return data;
};

export default function useCheckDuplicateID(
  userId: string | undefined,
  setIdDuplicateCheck: (val: boolean) => void
) {
  return useQuery(["duplicate-id"], () => checkDuplicateID(userId), {
    enabled: false,
    onSuccess: (data) => {
      if (data.code === 2013) {
        alert("사용 가능한 아이디입니다.");
        setIdDuplicateCheck(true);
      } else {
        alert("중복된 아이디입니다.");
        setIdDuplicateCheck(false);
      }
    },
    onError: (err) => alert(err),
  });
}
