import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";
import { changeNumStringForm } from "../../utils/utility";

const checkComRegNum = async (comRegNum: string) => {
  const { data } = await axiosClient.get(
    `/web/auth/company-registration-number?companyRegistrationNumber=${comRegNum}`
  );
  return data;
};

export default function useCheckComRegNum(
  comRegNum: string,
  setComRegNumCheck: (val: boolean) => void
) {
  comRegNum = changeNumStringForm(comRegNum);
  return useQuery(
    ["checkComRegNum", comRegNum],
    () => checkComRegNum(comRegNum),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.code === 1000) {
          alert("인증에 성공했습니다.");
          setComRegNumCheck(true);
        } else {
          alert("인증에 실패했습니다.");
          setComRegNumCheck(false);
        }
      },
      onError: (err) => alert(err),
    }
  );
}
