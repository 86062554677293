import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin-top: 180px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const CommonText = styled.span`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.35px;
`;

export const TitleText = styled(CommonText)`
  font-weight: 400;
`;

export const SignupChoice = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 60px;
`;

export const SignupWrap = styled.div`
  display: flex;
  width: 189px;
  height: 238px;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
`;

export const SignupContent = styled.div`
  display: flex;
  height: 199px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const SignupMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const SignupText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
`;

export const ContentText = styled(CommonText)<{ fontWeight: number }>`
  font-weight: ${(props) => props.fontWeight};
`;

export const Button = styled.button`
  width: 100%;
  height: 32px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid #009ee2;
  background: #fff;

  color: #009ee2;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  letter-spacing: 0.35px;
`;
