import React, { useState } from "react";
import AppHeader from "../../components/Header";
import AppFooter from "../../components/Footer";
import styled from "styled-components";
import {
  CartButton,
  CartButtonText,
  Content,
  Description,
  Detail,
  DetailWrap,
  Footer,
  Header,
  Information,
  Main,
  OtherProducts,
  OtherProductsTitle,
  OtherProductsWrap,
  Price,
  PriceDesc,
  PriceDescWrap,
  PriceWrap,
  ProductImgWrap,
  ProductTitle,
  // ReadingGlasses,
  // ReadingGlassesWrap,
  RowInfo,
  RowInfoDesc,
  RowInfoTitle,
  TextWrap,
  Wrap,
  Wrapper,
} from "./styles";
// import { ReactComponent as ProductImg } from "../../assets/content/product/img-product.svg";
import { ReactComponent as CartImg } from "../../assets/content/product/img-cart.svg";
// import { ReactComponent as Refrigerator } from "../../assets/content/popularItem/img-content-popularitem.svg";
import { CategorySelect } from "../../components/CategorySelect";
import {
  CardItem,
  ItemDescription,
  ItemDetail,
  ItemDetailElement,
  ItemDetailElementText,
  ItemPriceText,
  ItemPriceWrap,
  ItemTitle,
  ItemType,
  ItemTypeText,
  Line,
} from "../../components/CardItem/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import useGetProduct from "../../hooks/common/useGetProduct";
import { changeCommaFormat } from "../../utils/utility";
import ImageSlider from "./imageSlider";
import useAddCart from "../../hooks/cart/useAddCart";
import { UserDBIdState } from "../../recoil/login";
import { useRecoilState } from "recoil";
import { PayPathState, PayProductIdState } from "../../recoil/pay";
import Popup from "../../components/Popup/common";
import { axiosAuthClient, axiosClient } from "../../apis/general";
import { useQuery } from "@tanstack/react-query";

interface RelatedProducts {
  productId: number;
  productName: string;
  price: number;
  isBiz: string;
  condition: string;
  usedYear: number;
  usedMonth: number;
  productImageUrl: string;
}

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userId] = useRecoilState(UserDBIdState);
  const [, setPayProductId] = useRecoilState(PayProductIdState);
  const [, setPayPath] = useRecoilState(PayPathState);
  const { data, isLoading } = useGetProduct(Number(id));
  const requestAddCart = useAddCart();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };
  // 비동기 혹은 네트워크 이슈로 직접 useQuery 사용(커스텀 훅 useGetCountByCategory()를 안씀)
  // 간혹 useGetProduct()의 결괏값에 useGetCountByCategory() 결괏값이 담김
  // enabled 속성으로 동기 처리
  const { data: productCountsByCategory } = useQuery(
    ["getCountByCategory"],
    async () => {
      const { data } = await axiosClient.get("/web/products/count-by-category");
      return data;
    },
    {
      select: (res) => res.result.productCountsByCategory,
      enabled: !!data,
    }
  );

  if (isLoading) {
    return <p>데이터를 불러오는 중입니다.</p>;
  }

  const {
    result: {
      product: {
        productId,
        productImages,
        categoryName,
        productName,
        price,
        brokerageFee,
        usedYear,
        usedMonth,
        width,
        depth,
        height,
        condition,
        normalOperatingStatus,
        damagedArea,
        scratch,
        scratchDetail,
        description,
        purchaseStatus,
      },
      relatedProducts,
    },
  } = data;

  const handleAddCart = () => {
    onTogglePopUp();
  };

  const rowInfoList = [
    { title: "사용 기간", value: `${usedYear}년 ${usedMonth}개월` },
    { title: "사이즈", value: `${width} x ${depth} x ${height} cm` },
    { title: "컨디션", value: condition },
    {
      title: "정상 작동 여부",
      value: `${normalOperatingStatus === "Y" ? "YES" : "NO"}`,
    },
    { title: "파손 부위", value: damagedArea },
    {
      title: "기스 상태",
      value:
        `${scratch}` +
        (scratch !== "없음"
          ? scratchDetail === null
            ? ""
            : `(${scratchDetail})`
          : ""),
    },
  ];

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Content>
          <DetailWrap>
            <Detail>
              <ProductImgWrap>
                <ImageSlider imageFiles={productImages} />
                {/* <ReadingGlassesWrap>
                  <ReadingGlasses>🔎</ReadingGlasses>
                </ReadingGlassesWrap> */}
                {/* <ProductImg /> */}
              </ProductImgWrap>
              <Information>
                <Header>
                  <CategorySelect>
                    <option value={"임시"}>{categoryName}</option>
                  </CategorySelect>
                  <TextWrap>
                    <ProductTitle>{productName}</ProductTitle>
                  </TextWrap>
                </Header>
                <Main>
                  {rowInfoList.map((i, idx) => (
                    <RowInfo key={idx}>
                      <RowInfoTitle>{i.title}</RowInfoTitle>
                      <RowInfoDesc>{i.value}</RowInfoDesc>
                    </RowInfo>
                  ))}
                </Main>
                <Footer>
                  <PriceWrap style={{ gap: "8px" }}>
                    <Price>{changeCommaFormat(price + brokerageFee)}원</Price>
                    <span>(배송비 별도)</span>
                  </PriceWrap>
                  <PriceDescWrap>
                    <PriceDesc>품격 AI가 검증한 적정 가격이에요</PriceDesc>
                  </PriceDescWrap>
                </Footer>
              </Information>
            </Detail>
            <Wrapper>
              <CartButton
                onClick={() => {
                  if (purchaseStatus === "판매중") {
                    handleAddCart();
                  }
                }}
                background="#FFF"
                border={purchaseStatus !== "판매중" ? "#979797" : "#009ee2"}
              >
                {purchaseStatus === "판매중" && <CartImg />}
                <CartButtonText
                  color={purchaseStatus !== "판매중" ? "#979797" : "#009ee2"}
                >
                  장바구니
                </CartButtonText>
              </CartButton>
              <CartButton
                onClick={() => {
                  if (purchaseStatus === "판매중") {
                    setPayProductId(productId);
                    setPayPath("product");
                    navigate("/pay", { state: { path: "product" } });
                  }
                }}
                background={purchaseStatus !== "판매중" ? "#979797" : "#009ee2"}
                border={purchaseStatus !== "판매중" ? "#979797" : "#009ee2"}
              >
                <CartButtonText color="#FFFFFF">구매하기</CartButtonText>
              </CartButton>
            </Wrapper>
          </DetailWrap>
          <Description
            savedData={description ? true : false}
            value={description ?? ""}
            readOnly
          />
          {productCountsByCategory?.find((e: any) => e.name === categoryName)
            ?.productCount > 1 && (
            <OtherProductsWrap>
              <TextWrap>
                <OtherProductsTitle>다른 상품 보기</OtherProductsTitle>
              </TextWrap>
              <OtherProducts>
                {relatedProducts.map(
                  ({
                    productId,
                    productName,
                    price,
                    isBiz,
                    condition,
                    usedYear,
                    usedMonth,
                    productImageUrl,
                  }: RelatedProducts) => (
                    <ButtonLink to={`/product/${productId}`} key={productId}>
                      <CardItem>
                        {/* <Refrigerator /> */}
                        <img
                          src={productImageUrl}
                          alt="관련 상품"
                          width={193}
                          height={145}
                          style={{ borderRadius: "8px" }}
                        />
                        <ItemDescription gap="5px">
                          <ItemTitle fontSize="16px">{productName}</ItemTitle>
                          <ItemType isBiz={isBiz}>
                            <ItemTypeText isBiz={isBiz}>
                              {isBiz === "Y" ? "사업자" : "개인"}
                            </ItemTypeText>
                          </ItemType>
                          <ItemDetail>
                            <ItemDetailElement gap="10px">
                              <ItemDetailElementText>
                                사용기간
                              </ItemDetailElementText>
                              <ItemDetailElementText>
                                {usedYear}년 {usedMonth}개월
                              </ItemDetailElementText>
                            </ItemDetailElement>
                            <ItemDetailElement gap="21px">
                              <ItemDetailElementText>
                                컨디션
                              </ItemDetailElementText>
                              <ItemDetailElementText>
                                {condition}
                              </ItemDetailElementText>
                            </ItemDetailElement>
                          </ItemDetail>
                          <Line />
                          <ItemPriceWrap>
                            <ItemPriceText fontSize="20px">
                              {changeCommaFormat(price)}원
                            </ItemPriceText>
                          </ItemPriceWrap>
                        </ItemDescription>
                      </CardItem>
                    </ButtonLink>
                  )
                )}
              </OtherProducts>
            </OtherProductsWrap>
          )}
        </Content>
        {isPopUpOpen && (
          <Popup
            info={"해당 상품을 장바구니에 담으시겠습니까?"}
            onCancel={onTogglePopUp}
            onOk={async () => {
              let {
                data: {
                  result: { cartProducts },
                },
              } = await axiosAuthClient.get(`/web/carts?userId=${userId}`);
              const cartProductIds = cartProducts.map((e: any) => e?.productId);
              cartProductIds.includes(productId)
                ? alert("장바구니에 있는 상품입니다.")
                : requestAddCart.mutate({ userId, productId });
              onTogglePopUp();
            }}
          />
        )}
      </Wrap>
      <AppFooter />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  color: #d8d8d8;
`;
