import React, { useEffect, useState } from "react";
import {
  PayWrap,
  BuyerInfo,
  BuyerDetail,
  ButtonWrap,
  PayButton,
  PayButtonText,
  BuyerBasicDetail,
  BuyerBasicDetailContent,
  BuyerName,
  BuyerNameTitle,
  BuyerNameInput,
  TitleContainer,
  TitleDesc,
  AccountInputWrap,
  AccountSelect,
} from "../settlement_apply/styles";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { UserBizState, UserDBIdState } from "../../recoil/login";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { axiosAuthClient } from "../../apis/general";
import {
  accountRegExpMap,
  changeAccountForm,
  changeComRegNumForm,
} from "../../utils/utility";
import Popup from "../../components/Popup/common";

interface FormValues {
  depositorName: string;
  bankName: string;
  accountNumber: string;
  businessName?: string;
  companyRegistrationNumber?: string;
}

interface Param extends FormValues {
  userId: number;
}

interface MethodData extends FormValues {
  id: number; // 서버에서 주는 정산수단 데이터 식별자. FE에서 사용안함
}

export default function Method(methodData: MethodData) {
  const [userId] = useRecoilState(UserDBIdState);
  const [isBiz] = useRecoilState(UserBizState);
  const [editMode, setEditMode] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const onTogglePopUp = () => {
    setIsPopUpOpen((prev) => !prev);
  };

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    defaultValues: methodData,
  });

  useEffect(() => {
    setSelectedBank(methodData.bankName ?? "");
  }, []);

  const reqSettlementMethods = useMutation({
    mutationFn: (param: Param) =>
      axiosAuthClient.post("/web/users/settlement-methods", param),
    onSuccess: ({ data }) => {
      const { code, message } = data;
      if (code === 1000) {
        window.location.replace("/settlement");
      } else {
        alert(`${message}(code: ${code}).`);
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const onSubmit = (data: FormValues): void => {
    window.localStorage.setItem("settlementMethod", JSON.stringify(data));
    onTogglePopUp();
  };

  return (
    <MethodWrap>
      <Title>정산 수단 등록</Title>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <BuyerInfo>
          <BuyerDetail>
            <BuyerBasicDetail>
              <InfoWrap>
                <InfoTitle>
                  상품 판매 후 지급 받을 계좌를 입력해주세요
                </InfoTitle>
              </InfoWrap>
              <BuyerBasicDetailContent>
                <BuyerName>
                  <BuyerNameTitle>입금자명*</BuyerNameTitle>
                  <BuyerNameInput
                    {...register("depositorName", {
                      required: "이름을 확인해주세요.",
                      disabled: !editMode,
                    })}
                    maxLength={50}
                    placeholder="이름을 입력해주세요"
                  />
                </BuyerName>
                <BuyerName>
                  <TitleContainer>
                    <BuyerNameTitle>계좌번호*</BuyerNameTitle>
                    <TitleDesc>
                      사업자의 경우, 사업자 계좌를 입력해주세요
                    </TitleDesc>
                  </TitleContainer>
                  <AccountInputWrap>
                    <AccountSelect
                      {...register("bankName", {
                        required: "은행을 확인해주세요.",
                        disabled: !editMode,
                      })}
                      onChange={(e) => {
                        setSelectedBank(e.target.value);
                        trigger("bankName");
                      }}
                    >
                      <option value="">은행 선택</option>
                      <option value="(구)KB국민은행">(구)KB국민은행</option>
                      <option value="(신)KB국민은행">(신)KB국민은행</option>
                      <option value="IBK기업은행">IBK기업은행</option>
                      <option value="NH농협은행">NH농협은행</option>
                      <option value="(구)신한은행">(구)신한은행</option>
                      <option value="(신)신한은행">(신)신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="KEB하나은행">KEB하나은행</option>
                      <option value="(구)외환은행">(구)외환은행</option>
                      <option value="씨티은행">씨티은행</option>
                      <option value="DGB대구은행">DGB대구은행</option>
                      <option value="BNK부산은행">BNK부산은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="케이뱅크">케이뱅크</option>
                      <option value="카카오뱅크">카카오뱅크</option>
                    </AccountSelect>
                    <BuyerNameInput
                      {...register("accountNumber", {
                        required: "계좌번호를 확인해주세요",
                        disabled: !editMode || selectedBank === "",
                        pattern: {
                          value:
                            accountRegExpMap[selectedBank]?.["finalRegExp"],
                          message: "",
                        },
                        onChange: (e) => {
                          setValue(
                            "accountNumber",
                            changeAccountForm(e.target.value, selectedBank)
                          );
                        },
                      })}
                      placeholder="계좌번호를 입력해주세요"
                      maxLength={accountRegExpMap[selectedBank]?.["maxLength"]}
                    />
                  </AccountInputWrap>
                </BuyerName>
                {isBiz === "Y" && (
                  <>
                    <BuyerName>
                      <TitleContainer>
                        <BuyerNameTitle>사업자명*</BuyerNameTitle>
                        <TitleDesc>개인회원인 경우 빈칸</TitleDesc>
                      </TitleContainer>
                      <BuyerNameInput
                        {...register("businessName", {
                          required: "사업자명을 확인해주세요",
                          disabled: !editMode,
                        })}
                        maxLength={50}
                        placeholder="사업자명을 입력해주세요"
                      />
                    </BuyerName>
                    <BuyerName>
                      <TitleContainer>
                        <BuyerNameTitle>사업자 등록번호*</BuyerNameTitle>
                        <TitleDesc>개인회원인 경우 빈칸</TitleDesc>
                      </TitleContainer>
                      <BuyerNameInput
                        {...register("companyRegistrationNumber", {
                          required: "사업자 등록번호를 확인해주세요",
                          disabled: !editMode,
                          pattern: {
                            value: /^(\d{3})-(\d{2})-(\d{5})$/,
                            message: "",
                          },
                          onChange: (e) =>
                            setValue(
                              "companyRegistrationNumber",
                              changeComRegNumForm(e.target.value)
                            ),
                        })}
                        placeholder="사업자 등록번호를 입력해주세요"
                        maxLength={10}
                      />
                    </BuyerName>
                  </>
                )}
              </BuyerBasicDetailContent>
            </BuyerBasicDetail>
          </BuyerDetail>
          {editMode ? (
            <ButtonWrap>
              <PayButton
                type="button"
                onClick={() => setEditMode(!editMode)}
                border=""
                background="#EEE"
              >
                <PayButtonText color="#ACB1BA">취소</PayButtonText>
              </PayButton>
              <PayButton
                border={isValid ? "Main" : ""}
                background={isValid ? "Main" : "#EEE"}
                disabled={!isValid}
              >
                <PayButtonText color={isValid ? "#fff" : "#ACB1BA"}>
                  변경사항 저장
                </PayButtonText>
              </PayButton>
            </ButtonWrap>
          ) : (
            <PayButton
              type="button"
              onClick={() => setEditMode(!editMode)}
              border="Main"
              background="Main"
            >
              <PayButtonText color="#fff">수정하기</PayButtonText>
            </PayButton>
          )}
        </BuyerInfo>
      </form>
      {isPopUpOpen && (
        <Popup
          info={"변경된 내용을 저장하시겠습니까?"}
          onCancel={onTogglePopUp}
          onOk={() => {
            const data = JSON.parse(
              window.localStorage.getItem("settlementMethod") ?? "{}"
            );
            reqSettlementMethods.mutate({
              userId,
              ...data,
            });
            onTogglePopUp();
          }}
        />
      )}
    </MethodWrap>
  );
}

const MethodWrap = styled(PayWrap)`
  width: 41.87rem;
  gap: 0.625rem;
`;

const Title = styled.span`
  display: flex;
  width: 23.81rem;
  height: 2.25rem;
  flex-direction: column;
  justify-content: center;

  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.38rem; /* 61.111% */
`;

const InfoWrap = styled.div`
  display: flex;
  width: 23.81rem;
  height: 2.25rem;
  flex-direction: column;
  justify-content: center;
`;

const InfoTitle = styled.span`
  color: var(--gray-900);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.38rem; /* 137.5% */
`;
