import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../apis/general";

interface returnType {
  code: number;
  isSuccess: boolean;
  message: string;
  result: {
    categories: { id: number; name: string }[];
  };
}

export const getCategories = async (): Promise<returnType> => {
  const { data } = await axiosClient.get("/web/categories");
  return data;
};

export default function useGetCategories() {
  return useQuery(["categories"], getCategories, {
    select: (res) => res.result.categories,
  });
}
