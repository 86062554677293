import { useReducer } from "react";
import { CommonMember } from "../../types/memberInfo";

/**
 * 처음엔 reducer를 일반, 비즈 같이 사용하려 했으나
 * 개발하다보니 비즈는 안쓰게 됨(react-hook-form 사용)
 */

export default function useSignupStates(phoneNumber?: string) {
  const reducer = (
    state: CommonMember,
    action: { type: keyof CommonMember; payload: any }
  ): CommonMember => {
    const newValue = { [action.type]: action.payload };
    state = { ...state, ...newValue };
    return state;
  };

  const initialState: CommonMember = {
    isBiz: "N",
    userId: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
    phoneNumber: phoneNumber,
    address: "",
    addressDetail: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    action: dispatch,
  };
}
