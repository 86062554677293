import React from "react";
import { Container, Input, InputTitle, InputWrap } from "./CommonStyles";
import styled from "styled-components";
import { axiosClient } from "../../apis/general";

interface propsType {
  onNext: (isBiz: string) => void;
  userId: string;
  setUserId: (val: string) => void;
}

const checkIDAndGoNext = async (
  userId: string,
  onNext: (isBiz: string) => void
) => {
  try {
    const { data } = await axiosClient.get(
      `/web/auth/duplicate-id?userId=${userId}`
    );
    if (data.code === 1000) {
      onNext(String(data.result.isBiz));
      return;
    }
    if (data.code === 2158) {
      alert(data.message);
      return;
    }
    if (data.code === 2130) {
      alert(data.message);
      return;
    }
    if (data.code === 2013) {
      alert(data.message);
      return;
    }
  } catch (error) {
    alert(error);
  }
};

export default function IDCheck({ onNext, userId, setUserId }: propsType) {
  return (
    <Container>
      <InputWrap>
        <TitleWrap>
          <InputTitle>ID확인</InputTitle>
        </TitleWrap>
        <Input
          value={userId}
          onChange={({ target: { value } }) => setUserId(value)}
          placeholder="ID를 입력해주세요"
        />
      </InputWrap>
      <Button
        onClick={() => checkIDAndGoNext(userId, onNext)}
        disabled={!userId}
      >
        비밀번호 재설정
      </Button>
    </Container>
  );
}

const TitleWrap = styled.div`
  width: 100%;
  text-align: left;
`;

const Button = styled.button<{ disabled: boolean }>`
  display: flex;
  height: 3.62rem;
  min-height: 2rem;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  cursor: pointer;

  border-radius: 0.5rem;
  border: ${(props) => (props.disabled ? "none" : "1px solid #009ee2")};
  background: ${(props) => (props.disabled ? "#EEE" : "#009ee2")};

  color: ${(props) => (props.disabled ? "var(--button-gray)" : "#FFF")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 100% */
`;
