import { Select } from "antd";
import styled from "styled-components";

export const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const SelectSectorId = styled(Select)`
  .ant-select-selection-placeholder {
    position: relative;
    left: 5px;

    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .ant-select-selection-search:has(.ant-select-selection-search-input:focus)
    + .ant-select-selection-placeholder {
    display: none;
  }
  .ant-select-selection-item {
    position: relative;
    left: 5px;
    font-size: 16px;
  }
  .ant-select-selection-search-input {
    font-size: 16px;
    &:focus {
      position: relative;
      left: 5px;
    }
  }
  .ant-select-arrow {
    inset-inline-end: 16px;
  }
`;
