import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin-top: 300px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const Main = styled.div`
  display: flex;
  width: 506px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const LoginButton = styled.button<{ isActive: boolean }>`
  display: flex;
  height: 52px;
  min-height: 32px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;

  border: 0px;
  border-radius: 8px;
  background: ${(props) => (props.isActive ? "#009EE2" : "#eee")};
`;

export const LoginButtonText = styled.span<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? "#FFFFFF" : "#acb1ba")};
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
`;

export const InputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 60px;
  align-self: stretch;
`;

export const InputTitle = styled.span`
  width: 64px;
  color: #000;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
`;

export const Input = styled.input.attrs((props) => ({
  type: props.type,
}))<{ pw?: string }>`
  height: 51px;
  padding: ${(props) => (props.pw === "pw" ? "0px 45px 0px 16px" : "0px 16px")};
  flex: 1 0 0;
  font-size: 18px;

  border-radius: 8px;
  border: 1px solid #dfe2e9;

  &::placeholder {
    text-align: right;
    color: #8b97a2;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const Button = styled.button`
  border: 0;
  outline: 0;
  background-color: inherit;
  cursor: pointer;

  color: #009ee2;
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.35px;
  text-decoration-line: underline;
`;
