import { useReducer } from "react";

export interface loginInfo {
  userId: string;
  password: string;
}

// state 개수가 적으나 하나의 파일에서 관리하고 싶어 useReducer 사용
export default function useLoginStates() {
  const reducer = (
    state: loginInfo,
    action: { type: keyof loginInfo; payload: any }
  ): loginInfo => {
    const newValue = { [action.type]: action.payload };
    state = { ...state, ...newValue };
    return state;
  };

  const initialState: loginInfo = {
    userId: "",
    password: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    action: dispatch,
  };
}
