import styled from "styled-components";
import { CardItem } from "../../components/CardItem/styles";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
`;

export const Content = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const TitleWrap = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Title = styled.span`
  color: #212529;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 61.111% */
`;

export const FilteringWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const ExceptCompletedProduct = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ExceptCompletedProductText = styled.span`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const ExceptCompletedProductChk = styled.input.attrs({
  type: "checkbox",
})`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterBox = styled.select`
  border-radius: 5px;
  border: none;
  background: #fff;
  filter: blur(0px);
  display: flex;
  width: 85px;
  height: 42px;
  align-items: center;
`;

export const ProductList = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 554.5px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const CardItemWrap = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  border: 1px solid #d8d8d8;
  background: #fff;
  cursor: pointer;
`;

export const CardItemModified = styled(CardItem)`
  width: 100%;
  flex-direction: row;
  gap: 40px;
`;

export const TopButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 180px;
  z-index: 10;
  width: 52px;
  height: 52px;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 26px;
  border: 1px solid #d8d8d8;
  background: #fff;

  cursor: pointer;
`;
