import React, { useState } from "react";
import AppHeader from "../../../components/Header";
import styled from "styled-components";
import { Wrap, Container } from "./styles";
import { ReactComponent as Logo } from "../../../assets/login/img-login-logo.svg";

import SignUpEmailInput from "../../../components/Auth/biz/signupEmailInput";
import EmailConfirm from "../../../components/Auth/biz/emailConfirm";
import SignupForm from "../../../components/Auth/biz/signupForm/signupForm";
import { EmailState } from "../../../recoil/auth";
import { useRecoilState } from "recoil";

export default function SignupBiz() {
  const [step, setStep] = useState<"메일인증" | "인증확인" | "가입양식">(
    "메일인증"
  );
  const [verifyCode, setVerifyCode] = useState<number>();
  const [, setEmail] = useRecoilState(EmailState);

  return (
    <Root>
      <AppHeader />
      <Wrap>
        <Container step={step}>
          <Logo width={151} height={38} />
          <Show condition={step === "메일인증"}>
            <SignUpEmailInput
              onNext={(verifiCode: number, email: string) => {
                setVerifyCode(verifiCode);
                setStep("인증확인");
                setEmail(email);
              }}
            />
          </Show>
          <Show condition={step === "인증확인"}>
            <EmailConfirm
              verifyCode={verifyCode}
              onNext={() => setStep("가입양식")}
            />
          </Show>
          <Show condition={step === "가입양식"}>
            <SignupForm />
          </Show>
        </Container>
      </Wrap>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

interface showPropsType {
  condition: boolean;
  children: any;
}

function Show({ condition, children }: showPropsType) {
  return condition === true ? children : null;
}
