import React from "react";
import styled from "styled-components";
import MobilePublicTemplate from "../../components/MobileTemplates";
import MobileMain from "../../components/MobileMain";
import MobileFooter from "../../components/MobileFooter";

export default function MobilePage() {
  return (
    <Root>
      <MobilePublicTemplate>
        <MobileMain />
        <MobileFooter />
      </MobilePublicTemplate>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
